import React, { useRef, useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, Spinner, Badge, FormGroup, Label, Row, Col, Input as InputData, Form } from 'reactstrap';
import { Button, Input, Space, Table } from 'antd';
import { Button as Click, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import { collection, setDoc, addDoc, doc, query, getDocs, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../firebase';
import Widget from '../../components/Widget/Widget';
import { nanoid } from 'nanoid';
import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon } from '../../components/Buttons/CustomButtons';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { faEye } from '@fortawesome/free-regular-svg-icons';

const LoanSubCategories = ({ loanTypeId, loanTypeName }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalCategory, setModalCategory] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const searchInput = useRef(null);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const toggle = () => setModal(!modal);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const fetchLoanSubCategories = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loanTypeCategoryRef = collection(db, `LoansType/${loanTypeId}/LoanTypeCategory`);
      const snapshot = await getDocs(loanTypeCategoryRef);

      const subCategories = [];
      let count = 1;

      for (const docRef of snapshot.docs) {
        const loanTypeCategoryData = docRef.data();

        if (loanTypeCategoryData.LoanTypeName === loanTypeName) {
          subCategories.push({
            key: count,
            id: count,
            sector: loanTypeCategoryData.sectorName ? Object.keys(loanTypeCategoryData.sectorName).join(', ') : "",
            subSector: loanTypeCategoryData.sectorName || {},
            interest: loanTypeCategoryData.interestRate || "",
            term: loanTypeCategoryData.Term || "",
          });
          count++;
        }
      }

      setData(subCategories);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching loan sub-categories:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanTypeId && loanTypeName) {
      fetchLoanSubCategories();
    }
  }, [loanTypeId, loanTypeName]);


    const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      sorter: (a, b) => a.sector.localeCompare(b.sector),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Sub-Sector',
      dataIndex: 'subSector',
      key: 'subSector',
      render: (subSector) => {
        if (!subSector || typeof subSector !== 'object' || Object.keys(subSector).length === 0) return '';
        
        return (
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px', margin: 0 }}>
            {Object.entries(subSector).map(([sector, subSectors]) => (
              <li key={sector}>
                {sector}
                <ul style={{ listStyleType: 'circle', paddingLeft: '20px', margin: '5px 0' }}>
                  {Array.isArray(subSectors) ? (
                    subSectors.map((subSector, index) => (
                      <li key={index}>{subSector}</li>
                    ))
                  ) : (
                    <li>{subSectors}</li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      sorter: (a, b) => a.interest - b.interest,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Term',
      dataIndex: 'term',
      key: 'term',
      sorter: (a, b) => a.term.localeCompare(b.term),
      sortDirections: ['ascend', 'descend'],
    },
  ];


  return (
    <div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={data} bordered loading={loading} size="small" />
        </div>
    </div>
  );
};

export default LoanSubCategories;
