import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
// import { countUsers,getTotalApprovedLoanAmount,getTotalSavingsAmount,fetchActiveUsersCount } from '../widgets/data';
// import { fetchUserCountsByGender,fetchLoansDataAndPercentageByGender } from '../dashboard/data';

const LoansDisbursed = () => {

const [userCount, setUserCount] = useState("loading...");
const [maleCount, setMaleCount] = useState("loading...");
const [femaleCount, setFemaleCount] = useState("loading...");
const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState("loading...");
const [totalSavings, setTotalSavings] = useState("loading...");
const [activeUsersCount, setActiveUsersCount] = useState("loading...");
const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState("loading...");
const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState("loading...");
const [loanData, setLoanData] = useState([]);
const [totalMaleLoanCount, setTotalMaleLoanCount] = useState("loading...");
const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState("loading...");

// useEffect(() => {
//   // Fetch the total savings amount when the component mounts
//   getTotalSavingsAmount()
//     .then((formattedTotalSavingsAmount) => {
//       setTotalSavings(formattedTotalSavingsAmount);
//     })
//     .catch((error) => {
//       console.error('Error fetching total savings:', error);
//     });
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const counts = await fetchUserCountsByGender(window.location);
//       setMaleCount(counts.maleCount);
//       setFemaleCount(counts.femaleCount);
//     } catch (error) {
//       console.error('Error fetching user counts:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const result = await fetchLoansDataAndPercentageByGender(window.location);
//       setLoanData(result.totalLoanData);
//       // setLoanPercentage(result.loanPercentage);
//       // setTotalLoanAmount(result.totalLoanAmount);
//       setTotalMaleLoanAmount(result.totalMaleLoanAmount);
//       setTotalFemaleLoanAmount(result.totalFemaleLoanAmount);
//       setTotalMaleLoanCount(result.totalMaleLoanCount);
//       setTotalFemaleLoanCount(result.totalFemaleLoanCount);
//     } catch (error) {
//       console.error('Error fetching loan data:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []); 

   const  handleLoansDisbursedReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: 'NCAA WORKERS SAVINGS AND CREDIT COOPERATIVE SOCIETY LIMITED',
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','LOANS DISBURSED FOR MONTH ENDED:']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 01: To be submitted Monthly for Category B and Quartely for Category A',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

const headerRow2e = worksheet.addRow(['Sno','Sector','Sub Sector','Loans Disbursed To Females','','Loans Disbursed To Males','','Total Loans Disbursed','']);
headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } }; // Light Blue background color
headerRow2e.font = { bold: true };
headerRow2e.height = 25;

// Add a sub-row for the labels just above the subcolumns
const subLabelRow = worksheet.addRow(['', '','','Number','Amount','Number','Amount', 'Number(C+E)', 'Amount(D+F)']);
subLabelRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } };
subLabelRow.font = { bold: true };

worksheet.addRow(['1', 'Manufacturing', 'Food Processing', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['2', '','Animal Feed Processing', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['3', '','Carpentry', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['4', '','Textile and Leather Products', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['5', '','Others', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['6', 'Sub Total','', '', '', '', '', '', '']).font = { bold: true };
worksheet.addRow(['7', 'Services', 'Education,water,health', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['8', '','Financial & Bussiness Services', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['9', '','Transport & Communication', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['10', '','Housing Finance', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['11', '','Micro Insurance', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['12', '','Micro Leasing', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['13', '','Others', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['14', 'Sub Total','', '', '', '', '', '', '']).font = { bold: true };
worksheet.addRow(['15', 'Trade and Commerce', 'Wholetail and Retail stores', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['16', '','Medical Store', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['17', '','Hotel and Restaurant', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['18', '','Electricity and Gas', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['19', '','Others', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['20', 'Sub Total','', '', '', '', '', '', '']).font = { bold: true };
worksheet.addRow(['21', 'Agriculture', 'Crop Production', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['22', '','Animal production', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['23', '','Forestry', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['24', '','Fishing', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['25', '','Hunting', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['26', '','Others', '1', '78,998', '0', '0', '0', '0']);
worksheet.addRow(['27', 'Personal Loans','Consumer', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['28', '','Others', '0', '0', '1', '1,234,345', '0', '0']);
worksheet.addRow(['29', 'Sub Total','', '', '', '', '', '', '',]).font = { bold: true };
worksheet.addRow(['30', 'Gross Loans Disbursed (a)','', '0', '0', '0', '0', '0', '0']).font = { bold: true };

    
   
     // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });


    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'loans_disbursed.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleLoansDisbursedReport,
    // ... (other data or functions you want to export)
  };
};

export default LoansDisbursed;