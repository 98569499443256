import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { collection, doc, setDoc, serverTimestamp, getDocs, updateDoc, query, where } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import 'firebase/compat/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CreateLoan = () => {
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [maxLoanPeriod, setMaxLoanPeriod] = useState('');
  const [minLoanPeriod, setMinLoanPeriod] = useState('');
  const [loanLimitRate, setLoanLimitRate] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanName, setLoanName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { db } = fetchFirebaseConfig();

  const history = useHistory();

  const handleSave = async () => {
    try {
      setIsSubmitting(true);

      const newLoanData = {
        name: loanName,
        maxAmount: Number(maxAmount),
        minAmount: Number(minAmount),
        periodInMonths1: Number(maxLoanPeriod),
        periodInMonths2: Number(minLoanPeriod),
        loanLimitRate: Number(loanLimitRate),
        interestRate: Number(interestRate),
        companyID: 'DSP',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      // Add the new loan to the companyLoans collection
      await setDoc(doc(collection(db, 'companyLoans')), newLoanData);

      // Query the AccountSettings document where companyID == 'DSP'
      const accountSettingsQuery = query(
        collection(db, 'AccountingSettings'),
        where('companyID', '==', 'DSP')
      );

      const accountSettingsSnapshot = await getDocs(accountSettingsQuery);
      if (!accountSettingsSnapshot.empty) {
        const accountSettingsDoc = accountSettingsSnapshot.docs[0];
        const accountSettingsRef = doc(db, 'AccountingSettings', accountSettingsDoc.id);

        // Remove spaces from loanName and create the field name
        const sanitizedLoanName = loanName.replace(/\s+/g, '');
        const fieldName = `${sanitizedLoanName}Account`;

        // Update the document with the new field
        await updateDoc(accountSettingsRef, {
          [fieldName]: ''
        });
      }

      setIsSubmitting(false);

      toast.success(
        <div>
          <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
          &nbsp;&nbsp; Loan Created Successfully
        </div>
      );
    } catch (error) {
      console.error('Error creating loan:', error);
      toast.error('Error creating loan');
      setIsSubmitting(false);
    }
  };

  return (
    <div>
        <Row>
          <Col xs={12}>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="loanName">Loan Name:</Label>
                    <Input
                      type="text"
                      style={{ borderRadius: '7px' }}
                      id="loanName"
                      name="loanName"
                      placeholder="Loan Name"
                      value={loanName}
                      onChange={(e) => setLoanName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maxAmount">Max Loan Amount:</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="maxAmount"
                      name="maxAmount"
                      placeholder="Max Loan Amount"
                      value={maxAmount}
                      onChange={(e) => setMaxAmount(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="minAmount">Min Loan Amount:</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="minAmount"
                      name="minAmount"
                      placeholder="Min Loan Amount"
                      value={minAmount}
                      onChange={(e) => setMinAmount(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maxLoanPeriod">Max Loan Period (Months):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="maxLoanPeriod"
                      name="maxLoanPeriod"
                      placeholder="Max Loan Period"
                      value={maxLoanPeriod}
                      onChange={(e) => setMaxLoanPeriod(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="minLoanPeriod">Min Loan Period (Months):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="minLoanPeriod"
                      name="minLoanPeriod"
                      placeholder="Min Loan Period"
                      value={minLoanPeriod}
                      onChange={(e) => setMinLoanPeriod(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="loanLimitRate">Loan Limit Rate (%):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="loanLimitRate"
                      name="loanLimitRate"
                      placeholder="Loan Limit Rate"
                      value={loanLimitRate}
                      onChange={(e) => setLoanLimitRate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="interestRate">Interest Rate (%):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="interestRate"
                      name="interestRate"
                      placeholder="Interest Rate"
                      value={interestRate}
                      onChange={(e) => setInterestRate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <TextButton label="Save" onClick={handleSave} /> */}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <TextButton label='Save' onClick={handleSave} isLoading={isSubmitting} loadingLabel='Saving' isBorderRadius={true} />
              </div>
            </Form>
          </Col>
        </Row>
    </div>
  );
};

export default CreateLoan;
