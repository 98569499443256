// // src/utils/theme.js

// import { collection, query, where, getDoc, doc, onSnapshot } from 'firebase/firestore';
// import {fetchFirebaseConfig} from '../firebase';
// import firebase from 'firebase/compat/app'; // Adjust the import path based on your setup
// import 'firebase/compat/auth';

// const { db } = fetchFirebaseConfig();

// const getThemeColor = async () => {
//   const currentUser = firebase.auth().currentUser; // Use firebase.auth().currentUser
//   const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
//   const currentUserData = currentUserDoc.data();
//   const currentUserCompanyID = currentUserData.companyID;

//   const q = query(collection(db, 'ThemeSettings'), where('companyID', '==', currentUserCompanyID));
//   return new Promise((resolve) => {
//     onSnapshot(q, (querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         const themeColor = doc.data().themeColor || '#28d456';
//         resolve(themeColor);
//       });
//     });
//   });
// };

// export { getThemeColor };



// src/ThemeSetUp.js

import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../firebase';

const { db } = fetchFirebaseConfig();

const getThemeColor = (callback) => {
  const q = query(collection(db, 'ThemeSettings'), where('companyID', '==', 'DSP'));

  return onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const themeColor = doc.data().themeColor || '';
      callback(themeColor);
    });
  });
};

export { getThemeColor };



