import React, { useState } from 'react';
import { Button, Upload, Spin, message } from 'antd';
import * as XLSX from 'xlsx';
import { createUserAndUploadProfile } from '../../RegisterMember/RegisterData';
import { fetchFirebaseConfig } from '../../../firebase';
import { fetchFirebaseConfig2 } from '../../../firebaseClient';
import { collection, query, where, getDocs } from 'firebase/firestore';
import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';

const UploadMembers = () => {
  const { auth, db, storage } = fetchFirebaseConfig();
  const { auth2, app2 } = fetchFirebaseConfig2();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const generateExcelTemplate = () => {
    const worksheetData = [
      [
        'First Name',
        'Middle Name',
        'Surname',
        'Email',
        'Password',
        'Gender',
        'Religion',
        'Reference No',
        'Account Name',
        'Account Number',
        'Date of Birth',
      ],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'MemberDetails');
    XLSX.writeFile(workbook, 'MemberDetailsTemplate.xlsx');
  };

  const validateExcelData = (jsonData) => {
    const requiredHeaders = [
      'first name',
      'middle name',
      'surname',
      'email',
      'password',
      'gender',
      'religion',
      'reference no',
      'account name',
      'account number',
      'date of birth',
    ];
    const headers = jsonData[0].map((header) => header.toLowerCase());
    const hasAllHeaders = requiredHeaders.every((header) =>
      headers.includes(header)
    );
    if (!hasAllHeaders)
      return 'Invalid Excel template: Missing required columns.';
    for (let i = 1; i < jsonData.length; i++) {
      const row = jsonData[i];
      if (row[10] && !isValidDate(row[10]))
        return `Invalid date format in row ${i +
          1}: Date of Birth must be valid.`;
    }
    return '';
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const handleUpload = async (file) => {
    setUploadError('');
    setFileList([file]);
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const validationError = validateExcelData(jsonData);
      if (validationError) {
        setUploadError(validationError);
        return;
      }
      const parsedData = jsonData.slice(1).map((row) => ({
        display_name: row[0],
        mid_name: row[1],
        sir_name: row[2],
        email: row[3].toLowerCase(), // Convert email to lowercase here
        password: row[4],
        gender: row[5],
        religion: row[6],
        refNo: row[7],
        accountName: row[8],
        accountNo: row[9],
        dob: row[10],
        refFirstName: '',
        refMiddleName: '',
        refSirName: '',
        refemail: '',
        refcontact: '',
        refgender: '',
        refrelationshipStatus: '',
        heirFirstName: '',
        heirMiddleName: '',
        heirSirName: '',
        heiremail: '',
        heircontact: '',
        heirgender: '',
        heirrelationshipStatus: '',
        photo_url: '',
      }));

      const fetchParentData = async (accountName) => {
        const q = query(
          collection(db, 'Assets'),
          where('account_name', '==', accountName)
        );
        const snapshot = await getDocs(q);
        const data = snapshot.docs[0]?.data();
        return data || {};
      };

      setIsSubmitting(true);
      const results = await Promise.all(
        parsedData.map((values) => processUserCreation(values))
      );
      const successCount = results.filter((result) => result.success).length;
      const failureCount = results.length - successCount;
      if (successCount > 0) {
        message.success(
          `${successCount} member(s) created successfully, ${failureCount} failed.`
        );
      } else {
        message.error('Failed to create any members.');
      }
      results.forEach((result) => {
        if (!result.success) {
          message.error(`Error for email ${result.email}: ${result.error}`);
        }
      });
    } catch (error) {
      setUploadError('Error reading Excel file');
      console.error('Error reading Excel file:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const processUserCreation = async (userData) => {
    try {
      const userExists = await checkForDuplicateUser(userData.email);
      if (userExists) {
        return {
          success: false,
          email: userData.email,
          error: 'User already exists',
        };
      }
      await createUserAndUploadProfile(userData, auth2, app2, storage);
      return { success: true, email: userData.email };
    } catch (error) {
      console.error('Error creating user:', error);
      return { success: false, email: userData.email, error: error.message };
    }
  };

  const checkForDuplicateUser = async (email) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', email.toLowerCase())); // Convert email to lowercase here
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Error checking for duplicate user:', error);
      throw error;
    }
  };

  return (
    <div>
       <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Upload</Breadcrumb.Item>
        <Breadcrumb.Item>Upload New Members</Breadcrumb.Item>
      </Breadcrumb>
    <WidgetShadow>
      <div>
        <h5 className='mb-lg'>Create Members from Excel</h5>
        <Button
          onClick={generateExcelTemplate}
          style={{ marginBottom: '10px' }}
        >
          Download Excel Template
        </Button>
        {(loading || isSubmitting) && <Spin />}
        {uploadError && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            {uploadError}
          </div>
        )}
        <Upload
          beforeUpload={(file) => {
            handleUpload(file);
            return false;
          }}
          fileList={fileList}
          onChange={({ fileList }) => setFileList(fileList.slice(-1))}
          maxCount={1}
        >
          <Button>Upload Filled Template</Button>
        </Upload>
      </div>
    </WidgetShadow>
    </div>
  );
};

export default UploadMembers;
