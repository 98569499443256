import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';  
import {
  Row,
  Col,
} from 'reactstrap';
// import Widget from '../../components/Widget';
import s from './Dashboard.module.scss';
import DashboardWidgets from './dashboardWidgets/dashboardWidgets';
import AreaTrendChart from './AreaChartTrend/AreaTrendChart';
import LoanBarStatus from './LoansBarChart/LoanBarChart';
import TransactionLineChart from './TransactionLineChart/TransactionLineChart';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { getThemeColor } from '../../components/ThemeSetUp';



const Dashboard = () => {

  const [themeColor, setThemeColor] = useState('');

  useEffect(() =>{
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  return (
    <div className={s.root}>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Dashboard</span></Breadcrumb.Item>
      </Breadcrumb>

      <h4 className="mb-lg">Dashboard</h4>
      <Row>
      <Col xs={12}>
         <DashboardWidgets/>
        </Col>

        {/* Area Chart */}
        <Col xs={12}>
          <WidgetShadow 
            title={<h5><span className="fw-normal">Loans Monthly Trend</span></h5>}
          >
            <AreaTrendChart />
          </WidgetShadow>
        </Col>
        <Col xs={12}>
          <WidgetShadow
            title={<h5><span className="fw-normal">Loans Approval Status Trend</span></h5>}
          >
            <LoanBarStatus />
          </WidgetShadow>
        </Col>
        <Col xs={12}>
          <WidgetShadow
            title={<h5><span className="fw-normal">Transactions analysis</span></h5>}
          >
            < TransactionLineChart/>
          </WidgetShadow>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;


