import React, { useState, useEffect,useRef } from 'react';
import { Table as AntTable, Breadcrumb, Spin } from 'antd';
import { collection, getDocs } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import { FormGroup, Input, Form, Label, Col, Row } from 'reactstrap';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { faFileExport, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import s from './Styles.module.scss';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import Widget from '../../components/Widget/Widget';
import ReactToPrint from 'react-to-print'; 
import ExcelJS from 'exceljs';
import { getThemeColor } from '../../components/ThemeSetUp';


const TrialBalance = () => {
  const { db } = fetchFirebaseConfig();
  const [data, setData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [printing, setPrinting] = useState(false);
  const [displayDate, setDisplayDate] = useState('');
  const [themeColor, setThemeColor] = useState('');

  const componentRef = useRef(null);

  const getBalanceForDateRange = (item, startDate, endDate) => {
    if (!startDate || !endDate) return { debit: 0, credit: 0 };

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    let totalDebit = 0;
    let totalCredit = 0;
    for (let d = startDateObj; d <= endDateObj; d.setDate(d.getDate() + 1)) {
      const currentDateISOString = d.toISOString().split('T')[0];
      const balanceMap = item.balances.find((balance) => {
        if (!balance.date) return false;
        const balanceDateISOString = balance.date
          .toDate()
          .toISOString()
          .split('T')[0];
        return balanceDateISOString === currentDateISOString;
      });

      totalDebit += balanceMap ? balanceMap.debitBalance || 0 : 0;
      totalCredit += balanceMap ? balanceMap.creditBalance || 0 : 0;
    }

    return { debit: totalDebit, credit: totalCredit };
  };

  const getBalanceForCurrentDate = (item) => {
    const currentDateISOString = new Date().toISOString().split('T')[0];
    const balanceMap = item.balances.find((balance) => {
      if (!balance.date) return false;
      const balanceDateISOString = balance.date
        .toDate()
        .toISOString()
        .split('T')[0];
      return balanceDateISOString === currentDateISOString;
    });

    return {
      debit: balanceMap ? balanceMap.debitBalance || 0 : 0,
      credit: balanceMap ? balanceMap.creditBalance || 0 : 0,
    };
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const collections = ['Assets', 'Liabilities', 'Income', 'Expenses'];
      const allData = [];
      const allKeys = [];
      let totalDebitSum = 0;
      let totalCreditSum = 0;

      for (const collectionName of collections) {
        const collectionRef = collection(db, collectionName);
        const snapshot = await getDocs(collectionRef);
        const items = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const processItem = (item, level, allItems) => {
          allKeys.push(item.id);
          const { debit, credit } =
            startDate && endDate
              ? getBalanceForDateRange(item, startDate, endDate)
              : getBalanceForCurrentDate(item);

          totalDebitSum += debit;
          totalCreditSum += credit;

          if (level === 'parent') {
            return {
              ...item,
              key: item.id,
              debit,
              credit,
              children: allItems
                .filter(
                  (child) =>
                    child.parentAccount === item.account_name &&
                    child.account_level === 'child'
                )
                .map((child) => processItem(child, 'child', allItems)),
            };
          } else if (level === 'child') {
            const children = allItems
              .filter(
                (grandchild) =>
                  grandchild.parentAccount === item.account_name &&
                  grandchild.account_level === 'grandchild' &&
                  (grandchild.hasOwnProperty('IsMainAccount') && grandchild.IsMainAccount !== 'No')
              )
              .map((grandchild) =>
                processItem(grandchild, 'grandchild', allItems)
              );
            return {
              ...item,
              key: item.id,
              debit,
              credit,
              children,
            };
          } else {
            return {
              ...item,
              key: item.id,
              debit,
              credit,
            };
          }
        };

        const processedItems = items
          .filter((item) => item.account_level === 'parent')
          .map((item) => processItem(item, 'parent', items));

        allData.push({
          key: `${collectionName.toUpperCase()}-label`,
          account_name: collectionName,
          isLabel: true,
          category: true,
        });
        allData.push(...processedItems);
      }

      setData(allData);
      setExpandedKeys(allKeys);
      setTotalDebit(totalDebitSum);
      setTotalCredit(totalCreditSum);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    setLoading(false);
  };

  

  useEffect(() => {
    fetchData();
    const today = new Date();
    const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
    setCurrentDate(formattedDate);
    setDisplayDate(formattedDate);
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  const handleSearch = () => {
    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      setDisplayDate(`${formattedStartDate} to ${formattedEndDate}`);
    } else {
      setDisplayDate(currentDate);
    }
    fetchData();
  };
  
  // Add this helper function to format the date
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year}`;
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setDisplayDate(currentDate);
    fetchData();
  };

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account_name',
      key: 'account_name',
      render: (text, record) => ({
        children: (
          <span style={{ 
            paddingLeft: `${record.account_level === 'grandchild' ? 40 : record.account_level === 'child' ? 20 : 0}px`,
            fontWeight: record.account_level === 'parent' ?  620 : 'normal',
            textTransform: record.isLabel ? 'uppercase' : 'none'
          }}>
            {record.account_code ? `${record.account_code} ` : ''}
            {record.isLabel ? (
              text
            ) : (
              <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink}>
                {text}
              </Link>
            )}
          </span>
        ),
      }),
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      render: (text, record) => ({
        children: (
          <span style={{ fontWeight: record.account_level === 'parent' ? 620 : 'normal' }}>
            {text !== undefined ? text.toLocaleString() : ''}
          </span>
        ),
      }),
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      render: (text, record) => ({
        children: (
          <span style={{ fontWeight: record.account_level === 'parent' ?  620 : 'normal' }}>
            {text !== undefined ? text.toLocaleString() : ''}
          </span>
        ),
      }),
    },
  ];

  
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Trial Balance');
  
    // Add the heading "Balance Sheet"
    const headingRow = worksheet.addRow(['Trial Balance']);
    headingRow.font = { bold: true, size: 14 };
    worksheet.mergeCells('A1:B1:C1');
    headingRow.alignment = { horizontal: 'center' };
  
    // Add the current date and time
    const currentDateTime = new Date().toLocaleString();
    const dateTimeRow = worksheet.addRow([`Generated Date: ${currentDateTime}`]);
    dateTimeRow.font = { size: 10 }; // Smaller font size
    worksheet.mergeCells('A2:B2:C2');
    dateTimeRow.alignment = { horizontal: 'center' };

    //Filtered Dates
    const filteredDateTimeRow = worksheet.addRow([`Date: ${displayDate}`]);
     filteredDateTimeRow.font = { size: 10 }; // Smaller font size
     worksheet.mergeCells('A3:B3:C3');
     filteredDateTimeRow.alignment = { horizontal: 'center' };

  
    // Add an empty row for spacing
    worksheet.addRow([]);
  
    // Add column headers
    const headerRow = worksheet.addRow(['Account', 'Debit', 'Credit']);
    headerRow.font = { bold: true };
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F7FA' }
      };
    });
  
    // Define columns
    worksheet.columns = [
      { key: 'Account', width: 60 },
      { key: 'Debit', width: 20 },
      { key: 'Credit', width: 20 }
    ];
  
    const addRows = (items, level = 0) => {
      items.forEach(item => {
        if (item.isLabel) {
          const labelRow = worksheet.addRow([item.account_name]);
          labelRow.font = { bold: true, size: 14 };
        } else {
          const indentation = '  '.repeat(level);
          const row = worksheet.addRow({
            Account: indentation + (item.account_code ? `${item.account_code} ` : '') + item.account_name,
            Debit: item.debit,
            Credit: item.credit
          });
  
          // Apply styles
          if (item.account_level === 'parent') {
            row.font = { bold: true };
          }
  
          if (item.children) {
            addRows(item.children, level + 1);
          }
        }
      });
    };
  
    // Add data
    addRows(data);
  
    // Add total row
    worksheet.addRow([]); // Empty row for spacing
    const totalRow = worksheet.addRow({
      Account: 'Total',
      Debit: totalDebit,
      Credit: totalCredit
    });
    totalRow.font = { bold: true };
    totalRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFF5F7FA' }
    };
  
    // Apply number format to Debit and Credit columns
    worksheet.getColumn('Debit').numFmt = '#,##0.00';
    worksheet.getColumn('Credit').numFmt = '#,##0.00';
  
    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Trial_Balance_${currentDate.replace(/\//g, '-')}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Accounting</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Trial-Balance</span></Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Trial Balance</h5>
      <WidgetShadow>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h7><span className="fw-semi-bold">Date Range</span></h7>
          <div>
            <ButtonIcon icon={faFileExport} onClick={exportToExcel} tooltipTitle='Export'/>
            <ReactToPrint
                      trigger={() => (
                        <ButtonIcon style={{marginLeft:'3px'}} onClick={printing} tooltipTitle='Print' icon={faPrint}/>
                      )}
                      content={() => componentRef.current} // Specify the component to be printed
                      onBeforeGetContent={() => setPrinting(true)}
                      onAfterPrint={() => setPrinting(false)}
              />
          </div>
        </div>
        <Form style={{ marginTop: '15px' }}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="startDate">Start Date</Label>
                <Input
                  id="startDate"
                  style={{ borderRadius: '7px' }}
                  name="startDate"
                  placeholder="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="endDate">End Date</Label>
                <Input
                  id="endDate"
                  style={{ borderRadius: '7px' }}
                  name="endDate"
                  placeholder="End date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '15px' }}>
            <TextButton label='Search' size='sm' onClick={handleSearch} isBorderRadius={true} />
            <div style={{ marginLeft: '10px' }}></div>
            <TextButton label='Reset' size='sm' onClick={handleReset} isBorderRadius={true}/>
          </div>
        </Form>
        <Widget ref={componentRef}>
          <h4 className="mb-md">
          Trial Balance As Of {displayDate}
          </h4>
        <AntTable
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
          bordered={false}
          size="small"
          expandable={{
            expandedRowKeys: expandedKeys,
            onExpandedRowsChange: (keys) => setExpandedKeys(keys),
          }}
          summary={() => (
            <AntTable.Summary.Row style={{ backgroundColor: '#f5f7fa', fontWeight:620 }}>
              <AntTable.Summary.Cell index={0}>Total</AntTable.Summary.Cell>
              <AntTable.Summary.Cell index={1}>{totalDebit.toLocaleString()}</AntTable.Summary.Cell>
              <AntTable.Summary.Cell index={2}>{totalCredit.toLocaleString()}</AntTable.Summary.Cell>
            </AntTable.Summary.Row>
          )}
        />
         </Widget>
      </WidgetShadow>
    </div>
  );
};

export default TrialBalance;
