import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { collection, addDoc, updateDoc, serverTimestamp, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchFirebaseConfig } from '../../../firebase';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';


const LoanManagement = () => {
  const [loanCommiteeNo, setLoanCommiteeNo] = useState('');
  const [loanGuarantorsNo, setLoanGuarantorsNo] = useState('');
  const [loanLimitRate, setLoanLimitRate] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentFileUrl, setCurrentFileUrl] = useState('');
  const [currentFileType, setCurrentFileType] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { db, storage } = fetchFirebaseConfig();

  const history = useHistory();

  useEffect(() => {
    // Fetch existing 'LoanConfig' document and populate the inputs if it exists
    const fetchLoanConfigDocument = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, 'LoanSettings'), where('companyID', '==', 'DSP'))
        );

        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data();
          setLoanCommiteeNo(docData.loanCommiteeNo || '');
          setLoanGuarantorsNo(docData.loanGuarantorsNo || '');
          setLoanLimitRate(docData.loanLimitRate || '');
          
          if (docData.loanContractImage) {
            setCurrentFileUrl(docData.loanContractImage);
            setCurrentFileType('image');
          } else if (docData.loanContractFile) {
            setCurrentFileUrl(docData.loanContractFile);
            setCurrentFileType('file');
          }
        } else {
          console.error('No document found for company_id equal to "DSP"');
        }
      } catch (error) {
        console.error('Error fetching account settings:', error);
      }
    };

    fetchLoanConfigDocument();
  }, [db]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSave = async () => {
    setIsSubmitting(true)
    try {
      const querySnapshot = await getDocs(
        query(collection(db, 'LoanSettings'), where('companyID', '==', 'DSP'))
      );

      let docRef;
      if (!querySnapshot.empty) {
        docRef = querySnapshot.docs[0].ref;
      } else {
        docRef = await addDoc(collection(db, 'LoanSettings'), { companyID: 'DSP', createdAt: serverTimestamp() });
      }

      if (selectedFile) {
        const fileRef = ref(storage, 
          selectedFile.type.startsWith('image/') ? 
          `LoanContractsImages/${selectedFile.name}` : 
          `LoanContractsFiles/${selectedFile.name}`
        );

        await uploadBytes(fileRef, selectedFile);
        const fileURL = await getDownloadURL(fileRef);

        await updateDoc(docRef, {
          [selectedFile.type.startsWith('image/') ? 'loanContractImage' : 'loanContractFile']: fileURL,
          loanCommiteeNo,
          loanGuarantorsNo,
          loanLimitRate,
        });
      } else {
        await updateDoc(docRef, {
          loanCommiteeNo,
          loanGuarantorsNo,
          loanLimitRate,
        });
      }

      setIsSubmitting(false)

      toast.success(
        <div>
          <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
          &nbsp;&nbsp; settings updated
        </div>
      );
      console.log('Settings updated successfully!');
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  return (
    <div>
      <Row>
        <Col xs={8}>
          <Form>
            <FormGroup>
              <Label for="loanGuarantors">Guarantors per Loan</Label>
              <Input
                id="loanGuarantors"
                style={{ borderRadius: '7px' }}
                name="Guarantors per Loan"
                placeholder="Loan guarantors number"
                value={loanGuarantorsNo}
                onChange={(e) => setLoanGuarantorsNo(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="loanCommitee">Loan Committee</Label>
              <Input
                id="loanCommitee"
                style={{ borderRadius: '7px' }}
                name="loanCommitee"
                placeholder="Loan commitee number"
                value={loanCommiteeNo}
                onChange={(e) => setLoanCommiteeNo(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="loanLimit">Loan Limit</Label>
              <Input
                id="loanLimit"
                style={{ borderRadius: '7px' }}
                name="loanLimit"
                placeholder="Loan Limit rate"
                value={loanLimitRate}
                onChange={(e) => setLoanLimitRate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleUploadFile">Upload Loan Contract</Label>
              <Input
                id="exampleUploadFile"
                bsSize="sm"
                type="file"
                onChange={handleFileChange}
              />
            </FormGroup>
            {currentFileUrl && (
              <FormGroup>
                <Label>Current Contract Preview:</Label>
                {currentFileType === 'image' ? (
                  <WidgetShadow style={{ marginBottom: '15px' }} className="text-center d-flex align-items-center justify-content-start">
                  <img src={currentFileUrl} alt="Current Loan Contract" style={{ maxWidth: '100%' }} />
                  </WidgetShadow>
                ) : (
                  // <a href={currentFileUrl} target="_blank" rel="noopener noreferrer">View Current Loan Contract</a>
                  <WidgetShadow style={{ marginBottom: '15px' }} className="text-center d-flex align-items-center justify-content-start">
                  <Label>No Contact Available</Label>
                  </WidgetShadow>
                )}
              </FormGroup>
            )}
            {currentFileUrl && (
              <FormGroup>
                <Label>Current Contract: </Label>
                  <a href={currentFileUrl} target="_blank" rel="noopener noreferrer">  View Current Loan Contract</a>
              </FormGroup>
            )}
            {/* <TextButton label='Save' onClick={handleSave} /> */}
             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <TextButton label='Save' onClick={handleSave} loadingLabel='Saving' isLoading={isSubmitting} isBorderRadius={true}/>
              </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default LoanManagement;





