import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import { Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where} from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { faAdd, faPrint, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import CreateLoan from '../settings/loan/createLoan';


const AvailableLoans = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [loadingData, setLoadingData] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [printing, setPrinting] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [modal, setModal] = useState(false);
    const history = useHistory();

    const toggle = () => setModal(!modal);
  
    const componentRef = useRef(null);
  
    const { db } = fetchFirebaseConfig();
  
    const fetchLoansAvailable = async () => {
      try {
        setLoadingData(true);
        const querySnapshot = await getDocs(query(collection(db, 'companyLoans'), where('companyID', '==', 'DSP')));
        const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        setData(fetchedData);
        setLoadingData(false);
      } catch (error) {
        setError(error);
        setLoadingData(false);
      }
    };
  
    useEffect(() => {
        fetchLoansAvailable();
    }, []);
  
  
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };
  
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button type="link" size="small" onClick={close}>
              Close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
  
    const renderDateRequested = (timestamp) => {
      if (timestamp && timestamp.seconds) {
        const date = new Date(timestamp.seconds * 1000);
        const formattedDate = date.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
        return formattedDate;
      }
      return null;
    };
  
    const formatNumber = number => {
      return number?.toLocaleString();
    };
  
  
    const handleFilter = async () => {
      setLoadingData(true);
      try {
        const querySnapshot = await getDocs(query(collection(db, 'companyLoans'),
          where('createdAt', '>=', new Date(startDate)),
          where('createdAt', '<=', new Date(endDate))
        ));
        const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        setData(fetchedData);
        setLoadingData(false);
      } catch (error) {
        setError(error);
        setLoadingData(false);
      }
    };
  
  
    const handleRedo = () => {
    fetchLoansAvailable();
      setEndDate('');
      setStartDate('');
    };
  
  
    const columns = [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, __, index) => index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
      },
      {
        title: 'Date Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: renderDateRequested,
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
            <Space>
            <ButtonIcon icon={faEdit} />
            <Click color='danger' outline style={{ borderRadius: '10px' }}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </Click>
            </Space>
        ),
      },
    ];
  
    return (
      <div>
          <WidgetShadow
           style={{ height: '300px'}} 
            title={<h5><span className="fw-normal">Created Loans</span></h5>}
           >
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonIcon icon={faAdd} tooltipTitle='Create Loan' onClick={toggle}/>
        </div>
          <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
            <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
          </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader style={{border:'none'}} toggle={toggle}>Create Loan</ModalHeader>
                    <ModalBody>
                    <Widget>
                    <CreateLoan/>
                   </Widget>
            </ModalBody>
        </Modal>
        </WidgetShadow>
      </div>
    );
  };
  
  export default AvailableLoans;