import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
// import { countUsers,getTotalApprovedLoanAmount,getTotalSavingsAmount,fetchActiveUsersCount } from '../widgets/data';
// import { fetchUserCountsByGender,fetchLoansDataAndPercentageByGender } from '../dashboard/data';

const LiquidAssetsReports = () => {

const [userCount, setUserCount] = useState("loading...");
const [maleCount, setMaleCount] = useState("loading...");
const [femaleCount, setFemaleCount] = useState("loading...");
const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState("loading...");
const [totalSavings, setTotalSavings] = useState("loading...");
const [activeUsersCount, setActiveUsersCount] = useState("loading...");
const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState("loading...");
const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState("loading...");
const [loanData, setLoanData] = useState([]);
const [totalMaleLoanCount, setTotalMaleLoanCount] = useState("loading...");
const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState("loading...");

// useEffect(() => {
//   // Fetch the total savings amount when the component mounts
//   getTotalSavingsAmount()
//     .then((formattedTotalSavingsAmount) => {
//       setTotalSavings(formattedTotalSavingsAmount);
//     })
//     .catch((error) => {
//       console.error('Error fetching total savings:', error);
//     });
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const counts = await fetchUserCountsByGender(window.location);
//       setMaleCount(counts.maleCount);
//       setFemaleCount(counts.femaleCount);
//     } catch (error) {
//       console.error('Error fetching user counts:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const result = await fetchLoansDataAndPercentageByGender(window.location);
//       setLoanData(result.totalLoanData);
//       // setLoanPercentage(result.loanPercentage);
//       // setTotalLoanAmount(result.totalLoanAmount);
//       setTotalMaleLoanAmount(result.totalMaleLoanAmount);
//       setTotalFemaleLoanAmount(result.totalFemaleLoanAmount);
//       setTotalMaleLoanCount(result.totalMaleLoanCount);
//       setTotalFemaleLoanCount(result.totalFemaleLoanCount);
//     } catch (error) {
//       console.error('Error fetching loan data:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []); 

   const handleLiquidAssetsReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: 'NCAA WORKERS SAVINGS AND CREDIT COOPERATIVE SOCIETY LIMITED',
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','COMPUTATION OF LIQUID ASSETS FOR THE MONTH ENDED:']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 08: To be submitted Monthly for Category B and Quartely for Category A (This return is autofilled from other returns)',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    // Add additional details
    const headerRow2e = worksheet.addRow(['Sno',' REQUIRED MINIMUM AMOUNT OF LIQUID ASSETS','AMOUNT']);
    headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FEDBB7' } }; // Light Blue background color
    headerRow2e.font = { bold: true,};
    worksheet.addRow(['1','A: AVAILABLE LIQUID ASSETS','']).font = { bold: true};
    worksheet.addRow(['2','(a) Cash in hand','55,000']);
    worksheet.addRow(['3','(b) Current Accounts with banks and other financial institutions', '6,700,000'])
    worksheet.addRow(['4','(c) Accounts Receivable due in <=30 days',''])
    worksheet.addRow(['5','(d) Government Securities <= 30 days to maturity','']);
    worksheet.addRow(['6','(e) Investments in banks and other Financial Institutions <= 30 days to maturity (FDR)','']);
    worksheet.addRow(['7','(f) Balances with Mobile Money Networks e.g MPESA, TIGOPESA','40,000.00']);
    worksheet.addRow(['8','(g) Agent Banking Account Balances (Wakala)','']);
    worksheet.addRow(['9','(h) Other Liquid assets maturing  <= 30 days to maturity','']);
    worksheet.addRow(['10','Total Available Liquid Assets ','']).font = { bold: true};
    worksheet.addRow(['11','B:SHORT TERM  LIABILITES ','']).font = { bold: true};
    worksheet.addRow(['12','Total Savings and Deposits','18,365,476']);
    worksheet.addRow(['13','Accounts Payable due in < 30 days',''])
    worksheet.addRow(['14','Short-Term Borrowings from other SACCOs and 2nd Tier Organizations(< =30 days to  maturity)','']).font = { bold: true};
    worksheet.addRow(['15','Short-Term Borrowings from Banks and Financial Insititutions(< =30 days to  maturity)','']);
    worksheet.addRow(['16','Total Short Term Liabilities','18,365,476']).font = { bold: true};
    worksheet.addRow(['17','C:Required Minimum Liquid Assets(15%*C15)','']).font = { bold: true};
    worksheet.addRow(['18','D:Excess (Deficiency) Liquid Assets (A-C)','']).font = { bold: true};
    worksheet.addRow(['19','E:Minimum Liquidity Ratio as per regulation (15%)','']).font = { bold: true};
    worksheet.addRow(['','E:Minimum Liquidity Ratio as per CAMELS* (15%)','']).font = { bold: true};

  
       // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });


    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'liquid_Asset.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleLiquidAssetsReport,
    // ... (other data or functions you want to export)
  };
};

export default LiquidAssetsReports;