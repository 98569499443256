import React, { useState, useEffect } from 'react';
import SettingsMenu from './menu/settingsMenu';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import AccountSettings from './accounting/accountSettings';
import Settings from './appSettings/appSettings';
import LoanSettings from './loan/loanSettings';
import ThemeSettings from './ThemeSettings/themeSettings';
import { Col, Row } from 'reactstrap';
import { getThemeColor } from '../../components/ThemeSetUp';
import LoanCategories from '../loans/loanCategories';
import AvailableLoans from '../loans/availableLoans';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SettingsContainer = () => {
  const query = useQuery();
  const [selectedKey, setSelectedKey] = useState('profile');
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    const page = query.get('page');
    if (page) {
      setSelectedKey(page);
    }

    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, [query]);

  useEffect(() => {
    const page = query.get('page');
    if (page) {
      setSelectedKey(page);
    }
  }, [query]);

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case 'profile':
        return <Settings />;
      case 'accounting':
        return <AccountSettings />;
      case 'loan':
        return <LoanSettings />;
        case 'create-loan':
          return <AvailableLoans/>;
        case 'create-loan-category':
          return <LoanCategories/>;
      case 'theme':
        return <ThemeSettings />;
      default:
        return <div>Select an option from the menu</div>;
    }
  };

  return (
    <div style={{ width: '100%', boxSizing: 'border-box' }}>
      <div>
        <Breadcrumb separator=">">
          <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
          <Breadcrumb.Item><span style={{color: themeColor}}>Settings</span></Breadcrumb.Item>
        </Breadcrumb>
        <h5 className="mb-lg">General Settings</h5>
        <div>
          <Row>
          <Col md={4}>
              <SettingsMenu onMenuClick={handleMenuClick} />
            </Col>
            <Col md={8}>
              {renderContent()}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SettingsContainer;
