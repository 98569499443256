import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { Button , Spinner} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { getThemeColor } from '../ThemeSetUp';

const colorMap = {
  '#5ae833': 'lightGreen',
  '#28d456': 'darkGreen',
  '#3754a5': 'darkBlue',
  '#5d80f9': 'lightBlue',
  '#e3206b': 'pink',
  '#7c168a': 'purple'
};

const hexToColorName = (hex) => {
  return colorMap[hex.toLowerCase()] || hex;
};

const boxShadowStyle = {
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)'
};

// ButtonIcon component
const ButtonIcon = ({ icon, onClick, borderRadius = true, disabled = false, style = {}, tooltipTitle }) => {
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    const unsubscribe = getThemeColor((color) => {
      setThemeColor(hexToColorName(color));
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const iconButtonStyles = {
    borderRadius: borderRadius ? '10px' : '0px',
    // ...boxShadowStyle,
    ...style,
  };

  const button = (
    <Button outline color={themeColor} style={iconButtonStyles} onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  );

  return tooltipTitle ? (
    <Tooltip placement="top" title={tooltipTitle}>
      {button}
    </Tooltip>
  ) : button;
};

ButtonIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  borderRadius: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  tooltipTitle: PropTypes.string,
};

// TextButton component
const TextButton = ({
  label,
  onClick,
  disabled = false,
  style = {},
  isSubmit = false,
  isLoading = false,
  loadingLabel = '',
  isBorderRadius = false,
  ...props
}) => {
  const [themeColor, setThemeColor] = useState('');
  const [buttonWidth, setButtonWidth] = useState('auto');

  useEffect(() => {
    if (isLoading) {
      updateButtonWidth(loadingLabel);
    } else {
      setButtonWidth('auto');
    }
  }, [isLoading, loadingLabel]);

  useEffect(() => {
    const unsubscribe = getThemeColor((color) => {
      setThemeColor(hexToColorName(color));
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);


  const updateButtonWidth = (label) => {
    const tempElement = document.createElement('span');
    tempElement.style.visibility = 'hidden';
    tempElement.style.position = 'absolute';
    tempElement.style.whiteSpace = 'nowrap';
    tempElement.innerHTML = label;
    document.body.appendChild(tempElement);
    const width = tempElement.offsetWidth;
    document.body.removeChild(tempElement);
    setButtonWidth(`${width + 50}px`); // Added extra space for the spinner
  };

  const buttonText = isLoading ? loadingLabel : label;

  return (
    <Button
      color={themeColor}
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
      style={{
        ...style,
        width: isLoading ? buttonWidth : 'auto',
        transition: 'width 0.3s ease',
        ...(isBorderRadius && { borderRadius: '5px' }),
      }}
      disabled={disabled || isLoading}
      {...props}
    >
      <span style={{
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}>
        {isLoading && (
          <Spinner size="sm" color="white" style={{ marginRight: '10px' }} />
        )}
        {buttonText}
      </span>
    </Button>
  );
};

TextButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  isSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingLabel: PropTypes.string,
  isBorderRadius: PropTypes.bool,
};

export { ButtonIcon, TextButton };

