import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import firebase from 'firebase/compat/app';
import { getFunctions } from 'firebase/functions';

// const firebaseConfig = {
//   apiKey: "AIzaSyDy-TEcoV9z9Ly2JNxBH2j01jj_Xi4cCiQ",
//   authDomain: "dspsinglemode.firebaseapp.com",
//   projectId: "dspsinglemode",
//   storageBucket: "dspsinglemode.appspot.com",
//   messagingSenderId: "341721531146",
//   appId: "1:341721531146:web:8c285a4ff024cc809b70f3",
//   measurementId: "G-FW3V19SZST"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDy-TEcoV9z9Ly2JNxBH2j01jj_Xi4cCiQ",
  authDomain: "dspsinglemode.firebaseapp.com",
  projectId: "dspsinglemode",
  storageBucket: "dspsinglemode.appspot.com",
  messagingSenderId: "341721531146",
  appId: "1:341721531146:web:8c285a4ff024cc809b70f3",
  measurementId: "G-FW3V19SZST"
};

export const fetchFirebaseConfig2 = () => {
  // Initialize Firebase
  const app2 = firebase.initializeApp(firebaseConfig, 'signupuser');
  //const app = !firebase.getApps().length ? initializeApp(firebaseConfig,"Secondary") : firebase.getApp();
  //const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app2);
  const auth2 = getAuth(app2);
  const db = getFirestore(app2);
  const storage = getStorage(app2);

  return { app2, auth2, db, storage, functions };
};

export default fetchFirebaseConfig2;
