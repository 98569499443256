import React, { useRef, useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { Button as Click, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,Form,Col,Row } from 'reactstrap';
import { Button, Space, Table, Spin } from 'antd'; // Imported Spin for loading indicator
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom'; 
import { getDocs, collection,query,where, deleteDoc, doc, onSnapshot } from 'firebase/firestore'; // Added deleteDoc and doc
import { fetchFirebaseConfig } from '../../firebase';
import Swal from 'sweetalert2';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import {ButtonIcon, TextButton} from '../../components/Buttons/CustomButtons';
import { getThemeColor } from '../../components/ThemeSetUp';

const Journals = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isVisible7b1, setIsVisible7b1] = useState(false);
  const [isVisible7b2, setIsVisible7b2] = useState(false);
  const [isVisible7b3, setIsVisible7b3] = useState(false);
  const [themeColor, setThemeColor] = useState('');

  const searchInput = useRef(null);
  const history = useHistory();
  const { db } = fetchFirebaseConfig();

  const fetchData = async () => {
    try {
      setLoading(true);
      const collectionSnapshot = await getDocs(collection(db, 'Journal'));
      const fetchedData = collectionSnapshot.docs.map((doc) => ({ key: doc.id, ...doc.data() }));
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, [db]);


  const fetchVisibility = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);
      
      // Use onSnapshot to listen for changes to user permissions
      const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPosition = userData.position;
          const permissionsCollection = collection(db, 'permissions');
          const q = query(permissionsCollection, where('Role', '==', userPosition));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const permissionDocSnap = querySnapshot.docs[0];
            const permissionData = permissionDocSnap.data();
            const accessArray = permissionData.acess || [];
            setIsVisible7b1(accessArray.includes(7.21));
            setIsVisible7b2(accessArray.includes(7.22));
            setIsVisible7b3(accessArray.includes(7.23));
          } else {
            setIsVisible7b1(false);
            setIsVisible7b2(false);
            setIsVisible7b3(false);
          }
        }
      });
      
      return () => {
        // Unsubscribe from onSnapshot when component unmounts
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  fetchVisibility();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const EditJournal = (id) => {
    history.push(`/app/accounting/account-journals/edit-entry/${id}`);
  };

  const handleDeleteEntry = async (docId) => {
    try {
      if (!docId) {
        console.error('Document ID is empty or undefined.');
        return;
      }
      // Display confirmation dialog
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Confirm',
        text: 'Are you sure you want to delete this entry?',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });
  
      // If user confirms deletion
      if (result.isConfirmed) {
        // Perform deletion
        await deleteDoc(doc(collection(db, 'Journal'), docId));
        // Fetch updated data after deletion
        const collectionSnapshot = await getDocs(collection(db, 'Journal'));
        const updatedData = collectionSnapshot.docs.map((doc) => ({ key: doc.id, ...doc.data() }));
        // Update state with updated data
        setData(updatedData); 
        // Display completion dialog
        Swal.fire({
          icon: 'success',
          title: 'Entry Deleted',
          text: 'The entry has been successfully deleted!',
          confirmButtonColor: '#28a745',
        });
  
        console.log('Entry deleted successfully.');
      }
    } catch (error) {
      console.error('Error deleting document: ', error);
      // Display error dialog if deletion fails
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error deleting the entry.',
      });
    }
  };
  

  const handleFilter = async () => {
    try {
      setLoading(true);
      const collectionSnapshot = await getDocs(query(collection(db, 'Journal'), 
        where('date', '>=', startDate),
        where('date', '<=', endDate)
      ));
      const fetchedData = collectionSnapshot.docs.map((doc) => ({ key: doc.id, ...doc.data() }));
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false);
    }
  };

  const handleRedo = () => {
    fetchData();
    setEndDate('');
    setStartDate('');
  };

  const handleAddEntry= ()=>{
     history.push("/app/accounting/account-journals/add-entry")
  }

  const visibleActions = [isVisible7b2, isVisible7b3].some(Boolean);

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Code',
      dataIndex: 'journalCode',
      key: 'journalCode',
      align: 'center',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      ...getColumnSearchProps('date'),
      render: renderDateRequested,   
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
        <Space>
         {isVisible7b2 && ( 
          <ButtonIcon icon={faEdit} onClick={()=> EditJournal(record.key)}/>
           )}
          {/* {isVisible7b3 && ( 
          <Click color='danger' outline style={{ borderRadius: '10px' }} onClick={() => handleDeleteEntry(record.key)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Click>
          )} */}
        </Space>
        );
      },
      ...(visibleActions ? {} : { hidden: true }),
    },
  ];

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Accounting</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Journals</span></Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">Account Journals</h4>
      <WidgetShadow
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <Input
                        id="exampleFirstName"
                        style={{ borderRadius: '7px' }}
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <Input
                        id="endDate"
                        style={{ borderRadius: '7px' }}
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <TextButton label='Search' onClick={handleFilter} size='sm' isBorderRadius={true}/> 
                  <div style={{ marginLeft: '10px' }}></div>
                  <TextButton label='Reset' onClick={handleRedo} size='sm' isBorderRadius={true}/> 
                </div>
           </WidgetShadow>
       <WidgetShadow> 
       {isVisible7b1 && ( 
       <div className="mb-3" style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <TextButton label='Add Journal' onClick={handleAddEntry} size='sm' isBorderRadius={true}/> {/* Button to trigger Add Account Modal */}
      </div> 
       )}
      <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
      <Table columns={columns} dataSource={data} loading={loading} size='small'/>
      </div>
     </WidgetShadow> 
    </div>
  );
};

export default Journals;
