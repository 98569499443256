import React, { useEffect, useState } from 'react';
import { Select, Button, Upload, Spin, message, Tag } from 'antd';
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  addDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import * as XLSX from 'xlsx';
import Widget from '../../../components/Widget/Widget';
import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';

const { Option } = Select;

const UploadLoans = () => {
  const { db } = fetchFirebaseConfig();
  const [loanType, setLoanType] = useState('');
  const [loanCategory, setLoanCategory] = useState(''); // New state for loan category
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    const fetchLoans = async () => {
      if (!loanType) return;

      setLoading(true);
      try {
        const loansQuery = query(
          collection(db, 'Loans'),
          where('loanSchedual', '!=', loanType)
        );
        const loansSnapshot = await getDocs(loansQuery);
        const loansData = loansSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        fetchUsers(loansData);
      } catch (error) {
        console.error('Error fetching loans:', error);
        setLoading(false);
      }
    };

    const fetchUsers = async (loansData) => {
      try {
        const uniqueUserRefs = [
          ...new Set(loansData.map((loan) => loan.user.path)),
        ];

        const userPromises = uniqueUserRefs.map((userPath) =>
          getDoc(doc(db, userPath))
        );
        const userSnapshots = await Promise.all(userPromises);
        const usersData = userSnapshots.map((snapshot) => ({
          id: snapshot.id,
          ...snapshot.data(),
        }));
        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchLoans();
  }, [loanType]);

  const generateExcelTemplate = () => {
    const filteredData = users
      .filter((user) => selectedUsers.includes(user.id))
      .map((user) => ({
        userId: user.id,
        username: user.display_name,
      }));

    const worksheetData = [
      [
        'User ID',
        'Username',
        'Loan Category',
        'Loan Type', // Added Loan Type column
        'Overdue Penalty',
        'Amount Paid',
        'Remaining Balance',
        'Amount To Be Returned',
        'Date Taken',
        'Expected Monthly Installments',
        'Interest Rate',
        'Loan Amount',
        'Loan Duration',
      ],
      ...filteredData.map((record) => [
        record.userId,
        record.username,
        loanType,
        loanCategory === 'true' ? 'Existing Loan' : 'New Loan', // Added Loan Type value
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Loans');

    XLSX.writeFile(workbook, 'LoansTemplate.xlsx');
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const parseDate = (dateValue) => {
    if (typeof dateValue !== 'number') {
      return null; // Return null for non-number values
    }

    // Convert the numeric value to a date object, assuming the reference date is January 1, 1900
    const baseDate = new Date(1900, 0, 1);
    const date = new Date(
      baseDate.getTime() + (dateValue - 2) * 24 * 60 * 60 * 1000
    );

    // Check if the parsed date is valid
    if (isNaN(date.getTime())) {
      return null; // Return null for invalid dates
    }

    return date;
  };

  const handleUpload = async (file) => {
    setUploadError('');
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const validationError = validateExcelData(jsonData);
      if (validationError) {
        setUploadError(validationError);
        return;
      }

      const parsedData = jsonData.slice(1).map((row) => {
        const dateValue = row[8];
        const parsedDate = parseDate(dateValue);
        const isoDate = parsedDate ? parsedDate.toISOString() : null;
        const isExistingLoan = row[3] === 'Existing Loan'; // Get isExistingLoan value from the Excel data

        return {
          user: row[0],
          userName: row[1],
          loanSchedual: row[2],
          OverduePenalt: row[4],
          amountPaid: row[5],
          amountRemaining: row[6],
          amountTobeReturned: row[7],
          dateTaken: isoDate,
          expectedMonthlyInstallment: row[9],
          interestRate: row[10],
          loanAmount1: row[11],
          loanDuration: row[12],
          isExistingLoan, // Add isExistingLoan to the parsed data

          // duplicates
          loanCategory: row[2], // duplicate of loanSchedual
          expectedTotalPayment: row[7], // duplicate of amountTobeReturned
          interestRateTime: row[12], // duplicate of loanDuration
          repaymentMonths: row[12], // duplicate of loanDuration

          // empties
          guaranteer1: '',
          guaranteer2: '',
          guaranteersStatus: '',
          guaranteersStatus2: '',
          loanComiteeStatus: '',
          loanComiteeStatus2: '',
          loanEnd: '',
          loanStart: '',
          loanState: '',
          loanStatus: 'Approved',
          loanType: 'Personal Loan',
          paymentMethod: '',
          simpleInterest: '',
          userPhoto: '',
        };
      });

      setParsedData(parsedData);
      setSelectedUsers(parsedData.map((data) => data.user));
      setIsSaveDisabled(false);
    } catch (error) {
      setUploadError('Error reading Excel file');
      console.error('Error reading Excel file:', error);
    }
  };

  const validateExcelData = (jsonData) => {
    const requiredHeaders = [
      'User ID',
      'Username',
      'Loan Category',
      'Loan Type', // Added 'Loan Type' to required headers
      'Overdue Penalty',
      'Amount Paid',
      'Remaining Balance',
      'Amount To Be Returned',
      'Date Taken',
      'Expected Monthly Installments',
      'Interest Rate',
      'Loan Amount',
      'Loan Duration',
    ];
    const headers = jsonData[0].map((header) => header.toLowerCase());
    const hasAllHeaders = requiredHeaders.every((header) =>
      headers.includes(header.toLowerCase())
    );

    if (!hasAllHeaders) {
      return 'Invalid Excel template: Missing required columns.';
    }

    for (let i = 1; i < jsonData.length; i++) {
      const row = jsonData[i];
      if (
        typeof row[0] !== 'string' ||
        typeof row[1] !== 'string' ||
        typeof row[2] !== 'string' ||
        typeof row[3] !== 'string' // Added 'Loan Type' type check
      ) {
        return `Invalid data type in row ${i +
          1}: User ID, Username, Loan Category, and Loan Type must be strings.`;
      }
      if (
        typeof row[4] !== 'number' ||
        typeof row[5] !== 'number' ||
        typeof row[6] !== 'number' ||
        typeof row[7] !== 'number' ||
        typeof row[9] !== 'number' ||
        typeof row[10] !== 'number' ||
        typeof row[11] !== 'number' ||
        typeof row[12] !== 'number'
      ) {
        return `Invalid data type in row ${i +
          1}: Overdue Penalty, Amount Paid, Amount Remaining, Amount To Be Returned, Expected Monthly Installments, Interest Rate, Loan Amount, and Loan Duration must be numbers.`;
      }
      if (row[8] && !isValidDate(row[8])) {
        return `Invalid date format in row ${i +
          1}, column 9: Date Taken must be in a valid date format.`;
      }
    }
    return '';
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const loansCollection = collection(db, 'Loans');
      const currentTimestamp = serverTimestamp();
      const addLoansPromises = parsedData.map(async (data) => {
        const userRef = doc(db, 'users', data.user);

        // Check for duplicate loans
        const loansQuery = query(
          loansCollection,
          where('user', '==', userRef),
          where('loanSchedual', '==', data.loanSchedual)
        );
        const loansSnapshot = await getDocs(loansQuery);

        if (!loansSnapshot.empty) {
          // Handle duplicate loans if necessary
          toast.error(
            <div>
              <i
                className='fa fa-exclamation'
                aria-hidden='true'
                style={{ fontSize: '1.5rem' }}
              ></i>
              &nbsp;&nbsp; `Duplicate loan found for user`
            </div>
          );
          return null;
        }

        // Get the Assets document for the user and loanType
        const assetsQuery = query(
          collection(db, 'Assets'),
          where('user', '==', userRef),
          where('loanType', '==', data.loanSchedual)
        );
        const assetsSnapshot = await getDocs(assetsQuery);
        if (!assetsSnapshot.empty) {
          // Update the existing Assets document
          const assetsDoc = assetsSnapshot.docs[0];
          const existingData = assetsDoc.data();
          const newBalanceRecord = {
            balance: existingData.balance + data.amountRemaining,
            debitBalance: existingData.balance + data.amountRemaining,
            creditBalance: 0,
            date: new Date(), // Use current date/time instead of serverTimestamp
          };
          const updatedBalances = [...existingData.balances, newBalanceRecord];
          await updateDoc(assetsDoc.ref, { balances: updatedBalances });
        } else {
          // Create a new Assets document
          toast.error(
            <div>
              <i
                className='fa fa-check'
                aria-hidden='true'
                style={{ fontSize: '1.5rem' }}
              ></i>
              &nbsp;&nbsp; No corresponding Asset found
            </div>
          );
        }

        // Update the Equity collection document if isExistingLoan is true
        if (data.isExistingLoan) {
          const equityQuery = query(
            collection(db, 'Equity'),
            where('account_name', '==', 'Opening Equity Balance')
          );
          const equitySnapshot = await getDocs(equityQuery);
          if (!equitySnapshot.empty) {
            const equityDoc = equitySnapshot.docs[0];
            const existingBalances = equityDoc.data().balances;
            const lastBalanceRecord =
              existingBalances[existingBalances.length - 1];
            const newBalance = lastBalanceRecord.balance + data.amountRemaining;
            const newCreditBalance =
              lastBalanceRecord.creditBalance + data.amountRemaining;
            const newBalanceRecord = {
              ...lastBalanceRecord,
              balance: newBalance,
              creditBalance: newCreditBalance,
              debitBalance: lastBalanceRecord.debitBalance,
              date: new Date(), // Use current date/time instead of serverTimestamp
            };
            const updatedBalances = [...existingBalances, newBalanceRecord];
            await updateDoc(equityDoc.ref, { balances: updatedBalances });
          }
        }

        return addDoc(loansCollection, {
          user: userRef,
          loanSchedual: data.loanSchedual,
          OverduePenalt: data.OverduePenalt,
          amountPaid: data.amountPaid,
          amountRemaining: data.amountRemaining,
          amountTobeReturned: data.amountTobeReturned,
          dateTaken: data.dateTaken ? new Date(data.dateTaken) : new Date(), // Use current date/time instead of serverTimestamp
          expectedMonthlyInstallment: data.expectedMonthlyInstallment,
          interestRate: data.interestRate,
          loanAmount1: data.loanAmount1,
          loanDuration: data.loanDuration,
          isExistingLoan: data.isExistingLoan, // Add isExistingLoan to the loan document

          // duplicates
          loanCategory: data.loanCategory,
          expectedTotalPayment: data.expectedTotalPayment,
          interestRateTime: data.interestRateTime,
          repaymentMonths: data.repaymentMonths,

          // empties
          guaranteer1: '',
          guaranteer2: '',
          guaranteersStatus: '',
          guaranteersStatus2: '',
          loanComiteeStatus: '',
          loanComiteeStatus2: '',
          loanEnd: '',
          loanStart: '',
          loanState: '',
          loanStatus: '',
          loanType: data.loanType,
          paymentMethod: '',
          simpleInterest: '',
          userPhoto: '',
        });
      });

      const results = await Promise.all(addLoansPromises);
      const successfulSaves = results.filter((result) => result !== null);

      if (successfulSaves.length > 0) {
        toast.success(
          <div>
            <i
              className='fa fa-check'
              aria-hidden='true'
              style={{ fontSize: '1.5rem' }}
            ></i>
            &nbsp;&nbsp; Loans Saved Successfully
          </div>
        );
      } else {
        toast.warning(
          <div>
            <i
              className='fa fa-exclamation'
              aria-hidden='true'
              style={{ fontSize: '1.5rem' }}
            ></i>
            &nbsp;&nbsp; No new loans were saved.
          </div>
        );
      }
    } catch (error) {
      console.error('Error saving loans:', error);
      message.error('Error saving loans.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.id));
    }
  };

  const handleUserSelection = (value) => {
    if (value.includes('selectAll')) {
      if (selectedUsers.length === users.length) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(users.map((user) => user.id));
      }
    } else {
      setSelectedUsers(value);
    }
  };

  const tagRender = ({ label, closable, onClose }) => {
    return (
      <Tag
        color='blue'
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div>
    <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Upload</Breadcrumb.Item>
        <Breadcrumb.Item>Upload Loans</Breadcrumb.Item>
      </Breadcrumb>
    <WidgetShadow>
      <div>
        <h5 className='mb-lg'>Upload Loans</h5>

        <div style={{ marginBottom: '20px' }}>
          <Select
            placeholder='Select Loan Type'
            style={{ width: 200, marginRight: '10px' }}
            onChange={(value) => setLoanType(value)}
          >
            <Option value='Chap Chap'>Chap Chap Loan</Option>
            <Option value='Development Fund'>Development Fund</Option>
            <Option value='Long Term Loan'>Long Term Loan</Option>
          </Select>
          <Select
            placeholder='Select Loan Category'
            style={{ width: 200, marginRight: '10px' }}
            onChange={(value) => setLoanCategory(value)}
          >
            <Option value='true'>Existing Loan</Option>
            <Option value='false'>New Loan</Option>
          </Select>
          <Select
            mode='multiple'
            placeholder='Select Users'
            style={{ width: 300 }}
            value={selectedUsers}
            onChange={handleUserSelection}
            tagRender={tagRender}
            optionLabelProp='label'
          >
            <Option key='selectAll' value='selectAll' label='Select All'>
              {selectedUsers.length === users.length
                ? 'Deselect All'
                : 'Select All'}
            </Option>
            {users.map((user) => (
              <Option key={user.id} value={user.id} label={user.display_name}>
                {user.display_name}
              </Option>
            ))}
          </Select>
        </div>
        <Button
          onClick={generateExcelTemplate}
          style={{ marginBottom: '10px', marginRight: '5px' }}
          disabled={selectedUsers.length === 0 || loanCategory === ''}
        >
          Download Excel Template
        </Button>
        {loading && <Spin />}
        {uploadError && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            {uploadError}
          </div>
        )}
        <Upload
          beforeUpload={(file) => {
            handleUpload(file);
            return false;
          }}
          fileList={fileList}
          onChange={({ fileList }) => setFileList(fileList.slice(-1))}
          maxCount={1}
        >
          <Button>Upload Filled Template</Button>
        </Upload>
        <Button type='primary' onClick={handleSave} disabled={isSaveDisabled}>
          Save
        </Button>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ fontSize: '13.5px' }}
      />
    </WidgetShadow>
    </div>
  );
};

export default UploadLoans;
