import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import LinksGroup from './LinksGroup/LinksGroup';
import s from './Sidebar.module.scss';
import { UserAuth } from '../../context/AuthContext';
import { useState, useEffect } from 'react';
import fetchFirebaseConfig from '../../firebase';
import {
  getDocs,
  doc,
  getDoc,
  collection,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faScaleBalanced, faBank, faHandHoldingDollar, faSackDollar, faUsers, faHome, faCreditCard, faArrowRightArrowLeft, faBriefcase, faShieldHalved, faGears } from '@fortawesome/free-solid-svg-icons';
import { faCreditCardAlt, faFile, faFolder, faMessage, faNewspaper } from '@fortawesome/free-regular-svg-icons';

const Sidebar = () => {
  const { user } = UserAuth();

  const [selectedImage, setSelectedImage] = useState('');
  const [imageFetched, setImageFetched] = useState(false);
  const [institutionName, setInstitutionName] = useState('');

  const defaultImage = '';

  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible1a, setIsVisible1a] = useState(false);
  const [isVisible1b, setIsVisible1b] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible2a, setIsVisible2a] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible3a, setIsVisible3a] = useState(false);
  const [isVisible3b, setIsVisible3b] = useState(false);
  const [isVisible3c, setIsVisible3c] = useState(false);
  const [isVisible3d, setIsVisible3d] = useState(false);
  const [isVisible3e, setIsVisible3e] = useState(false);
  const [isVisible3f, setIsVisible3f] = useState(false);
  const [isVisible3g, setIsVisible3g] = useState(false);
  const [isVisible3h, setIsVisible3h] = useState(false);
  const [isVisible3i, setIsVisible3i] = useState(false);
  const [isVisible3l, setIsVisible3l] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible4a, setIsVisible4a] = useState(false);
  const [isVisible4b, setIsVisible4b] = useState(false);
  const [isVisible4c, setIsVisible4c] = useState(false);
  const [isVisible4d, setIsVisible4d] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);
  const [isVisible5a, setIsVisible5a] = useState(false);
  const [isVisible5b, setIsVisible5b] = useState(false);
  const [isVisible6, setIsVisible6] = useState(false);
  const [isVisible6a, setIsVisible6a] = useState(false);
  const [isVisible6b, setIsVisible6b] = useState(false);
  const [isVisible6c, setIsVisible6c] = useState(false);
  const [isVisible6d, setIsVisible6d] = useState(false);
  const [isVisible7, setIsVisible7] = useState(false);
  const [isVisible7a, setIsVisible7a] = useState(false);
  const [isVisible7b, setIsVisible7b] = useState(false);
  const [isVisible7c, setIsVisible7c] = useState(false);
  const [isVisible7d, setIsVisible7d] = useState(false);
  const [isVisible7e, setIsVisible7e] = useState(false);
  const [isVisible7f, setIsVisible7f] = useState(false);
  const [isVisible7g, setIsVisible7g] = useState(false);
  const [isVisible7h, setIsVisible7h] = useState(false);
  const [isVisible8, setIsVisible8] = useState(false);
  const [isVisible8a, setIsVisible8a] = useState(false);
  const [isVisible9, setIsVisible9] = useState(false);
  const [isVisible9a, setIsVisible9a] = useState(false);
  const [isVisible9b, setIsVisible9b] = useState(false);
  const [isVisible9c, setIsVisible9c] = useState(false);
  const [isVisible9d, setIsVisible9d] = useState(false);
  const [isVisible9e, setIsVisible9e] = useState(false);
  const [isVisible9f, setIsVisible9f] = useState(false);
  const [isVisible10, setIsVisible10] = useState(false);
  const [isVisible10a, setIsVisible10a] = useState(false);
  const [isVisible11, setIsVisible11] = useState(false);
  const [isVisible11a, setIsVisible11a] = useState(false);
  const [isVisible12, setIsVisible12] = useState(false);
  const [isVisible12a, setIsVisible12a] = useState(false);
  const [isVisible12b, setIsVisible12b] = useState(false);
  const [isVisible13, setIsVisible13] = useState(false);
  const [isVisible13a, setIsVisible13a] = useState(false);
  const [isVisible14, setIsVisible14] = useState(false);
  const [isVisible15a, setIsVisible15a] = useState(false);
  const [isVisible15, setIsVisible15] = useState(false);
  const [isVisible16, setIsVisible16] = useState(false);
  const [isVisible16a, setIsVisible16a] = useState(false);
  const [isVisible16b, setIsVisible16b] = useState(false);
  const [isVisible16c, setIsVisible16c] = useState(false);

  const fetchVisibility = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);

      // Use onSnapshot to listen for changes to user permissions
      const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPosition = userData.position;
          const permissionsCollection = collection(db, 'permissions');
          const q = query(
            permissionsCollection,
            where('Role', '==', userPosition)
          );
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const permissionDocSnap = querySnapshot.docs[0];
            const permissionData = permissionDocSnap.data();
            const accessArray = permissionData.acess || [];
            setIsVisible1(accessArray.includes(1));
            setIsVisible1a(accessArray.includes(1.1));
            setIsVisible1b(accessArray.includes(1.2));
            setIsVisible2(accessArray.includes(2));
            setIsVisible2a(accessArray.includes(2.1));
            setIsVisible3(accessArray.includes(3));
            setIsVisible3a(accessArray.includes(3.1));
            setIsVisible3b(accessArray.includes(3.2));
            setIsVisible3c(accessArray.includes(3.3));
            setIsVisible3d(accessArray.includes(3.4));
            setIsVisible3e(accessArray.includes(3.5));
            setIsVisible3f(accessArray.includes(3.6));
            setIsVisible3g(accessArray.includes(3.7));
            setIsVisible3h(accessArray.includes(3.8));
            setIsVisible3i(accessArray.includes(3.14));
            setIsVisible3l(accessArray.includes(3.15));
            setIsVisible4(accessArray.includes(4));
            setIsVisible4a(accessArray.includes(4.1));
            setIsVisible4b(accessArray.includes(4.2));
            setIsVisible4c(accessArray.includes(4.3));
            setIsVisible4d(accessArray.includes(4.4));
            setIsVisible5(accessArray.includes(5));
            setIsVisible5a(accessArray.includes(5.1));
            setIsVisible5b(accessArray.includes(5.2));
            setIsVisible6(accessArray.includes(6));
            setIsVisible6a(accessArray.includes(6.1));
            setIsVisible6b(accessArray.includes(6.2));
            setIsVisible6c(accessArray.includes(6.3));
            setIsVisible6d(accessArray.includes(6.4));
            setIsVisible7(accessArray.includes(7));
            setIsVisible7a(accessArray.includes(7.1));
            setIsVisible7b(accessArray.includes(7.2));
            setIsVisible7c(accessArray.includes(7.3));
            setIsVisible7d(accessArray.includes(7.4));
            setIsVisible7e(accessArray.includes(7.5));
            setIsVisible7f(accessArray.includes(7.6));
            setIsVisible7g(accessArray.includes(7.7));
            setIsVisible7h(accessArray.includes(7.8));
            setIsVisible8(accessArray.includes(8));
            setIsVisible8a(accessArray.includes(8.1));
            setIsVisible9(accessArray.includes(9));
            setIsVisible9a(accessArray.includes(9.1));
            setIsVisible9b(accessArray.includes(9.2));
            setIsVisible9c(accessArray.includes(9.3));
            setIsVisible9d(accessArray.includes(9.4));
            setIsVisible9e(accessArray.includes(9.5));
            setIsVisible9f(accessArray.includes(9.6));
            setIsVisible10(accessArray.includes(10));
            setIsVisible10a(accessArray.includes(10.1));
            setIsVisible11(accessArray.includes(11));
            setIsVisible11a(accessArray.includes(11.1));
            setIsVisible12(accessArray.includes(12));
            setIsVisible12a(accessArray.includes(12.1));
            setIsVisible12b(accessArray.includes(12.2));
            setIsVisible13(accessArray.includes(13));
            setIsVisible13a(accessArray.includes(13.1));
            setIsVisible14(accessArray.includes(14));
            setIsVisible15a(accessArray.includes(15.1));
            setIsVisible15(accessArray.includes(15));
            setIsVisible16(accessArray.includes(16));
            setIsVisible16a(accessArray.includes(16.1));
            setIsVisible16b(accessArray.includes(16.2));
            setIsVisible16c(accessArray.includes(16.3));
          } else {
            setIsVisible1(false);
            setIsVisible1a(false);
            setIsVisible1b(false);
            setIsVisible2(false);
            setIsVisible2a(false);
            setIsVisible3(false);
            setIsVisible3a(false);
            setIsVisible3b(false);
            setIsVisible3c(false);
            setIsVisible3d(false);
            setIsVisible3e(false);
            setIsVisible3f(false);
            setIsVisible3g(false);
            setIsVisible3h(false);
            setIsVisible3l(false);
            setIsVisible4(false);
            setIsVisible4a(false);
            setIsVisible4b(false);
            setIsVisible4c(false);
            setIsVisible4d(false);
            setIsVisible5(false);
            setIsVisible5a(false);
            setIsVisible5b(false);
            setIsVisible6(false);
            setIsVisible6a(false);
            setIsVisible6b(false);
            setIsVisible6c(false);
            setIsVisible7(false);
            setIsVisible7a(false);
            setIsVisible7b(false);
            setIsVisible7c(false);
            setIsVisible7d(false);
            setIsVisible7e(false);
            setIsVisible7f(false);
            setIsVisible7g(false);
            setIsVisible7h(false);
            setIsVisible8(false);
            setIsVisible8a(false);
            setIsVisible9(false);
            setIsVisible9a(false);
            setIsVisible9b(false);
            setIsVisible9c(false);
            setIsVisible9d(false);
            setIsVisible9e(false);
            setIsVisible9f(false);
            setIsVisible10(false);
            setIsVisible10a(false);
            setIsVisible11(false);
            setIsVisible11a(false);
            setIsVisible12(false);
            setIsVisible12a(false);
            setIsVisible12b(false);
            setIsVisible13(false);
            setIsVisible13a(false);
            setIsVisible14(false);
            setIsVisible15(false);
            setIsVisible15a(false);
            setIsVisible16(false);
            setIsVisible16a(false);
            setIsVisible16b(false);
            setIsVisible16c(false);
          }
        }
      });

      return () => {
        // Unsubscribe from onSnapshot when component unmounts
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  fetchVisibility();

  useEffect(() => {
    const { db } = fetchFirebaseConfig();
    const companyLogoCollectionRef = collection(db, 'company_logo');
    const q = query(
      companyLogoCollectionRef,
      where('reference', '==', 'NCAAWS')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          setSelectedImage(data.path);
          setInstitutionName(data.institutionName);
          setImageFetched(true);
        });
      } else {
        setSelectedImage(defaultImage);
        setImageFetched(true);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <nav className={s.root}>
      <header className={s.logo}>
        <Link to='/app/main'>
        <img
          src={selectedImage || ""}
          alt='Logo'
          onError={(e) => e.target.src = ""}
          style={{ width: '100px', height: '100px', border: 'none' }}
        />
      </Link>
      </header>

      {/* <header className={s.companyName}>{institutionName}</header> */}

      {/* <div className={`${s.divider} ${s.navWrapper}`}> */}
      <div className={`${s.navWrapper}`}>
        {/* Add wrapper div */}
        <ul className={s.nav}>
          <LinksGroup
            header='Dashboard'
            headerLink='/app/main'
            icon={<FontAwesomeIcon icon={faHome} />}// Pass the icon component directly
          />
           {isVisible1 && (
            <LinksGroup
              header='Members'
              headerLink='/app/members'
              visible={isVisible1a || isVisible1b}
              childrenLinks={[
                isVisible1a && {
                  name: 'Members',
                  link: '/app/members/members-list',
                },
                isVisible1b && {
                  name: 'Register Member',
                  link: '/app/members/register-member',
                },
              ].filter(Boolean)} 
              icon={<FontAwesomeIcon icon={faUsers} />}
            />
          )}
            {isVisible3 && (
            <LinksGroup
               header='Loans'
              headerLink='/app/loans'
              // visible={isVisible3a || isVisible3b || isVisible3c || isVisible3d || isVisible3e ||  isVisible3f ||  isVisible3g || isVisible3h }
              visible={isVisible3a || isVisible3c || isVisible3d || isVisible3e || isVisible3g}
              childrenLinks={[
                isVisible3a && {
                  name: 'Loan Accounts',
                  link: '/app/loans/loan-accounts',
                },
                // isVisible3a && {
                //   name: 'Long-Term Requests',
                //   link: '/app/loans/long-term-loans',
                // },
                // isVisible3b && {
                //   name: 'Chap-Chap Requests',
                //   link: '/app/loans/chap-chap-loans',
                // },
                isVisible3c && {
                  name: 'Loan Requests',
                  link: '/app/loans/loan-requests',
                },
                // isVisible3c && {
                //   name: 'Loan Categories',
                //   link: '/app/loans/loan-categories',
                // },
                isVisible3d && {
                  name: 'Disbursements',
                  link: '/app/loans/loan-payouts',
                },
                isVisible3e && {
                  name: 'Payments',
                  link: '/app/loans/loan-transactions',
                },
                isVisible3g && {
                  name: 'Loans Charts',
                  link: '/app/loans/loan-Charts',
                },
              ].filter(Boolean)} 
              icon={<FontAwesomeIcon icon={faHandHoldingDollar} />}
            />
          )}
           {isVisible4  && (
            <LinksGroup
              header='Savings'
              headerLink='/app/savings'
              visible={isVisible4a || isVisible4b || isVisible4c || isVisible4d}
              childrenLinks={[
                isVisible4a && {
                  name: 'Savings Accounts',
                  link: '/app/savings/savings-accounts',
                },
                isVisible4b && {
                  name: 'Payments',
                  link: '/app/savings/savings-transactions',
                },
                isVisible4c && {
                  name: 'Disbursements',
                  link: '/app/savings/savings-payouts',
                },
                isVisible4d && {
                  name: 'Savings Charts',
                  link: '/app/savings/savings-charts',
                },
              ].filter(Boolean)}
              icon={<FontAwesomeIcon icon={faScaleBalanced} />}
            />
          )}
          {isVisible6 && (
            <LinksGroup
              header='Shares'
              headerLink='/app/shares'
              visible={isVisible6a || isVisible6b || isVisible6c || isVisible6d}
              childrenLinks={[
                isVisible6a && {
                  name: 'Shares Accounts',
                  link: '/app/shares/shares-accounts',
                },
                isVisible6b && {
                  name: 'Payments',
                  link: '/app/shares/shares-transactions',
                },
                isVisible6c && {
                  name: 'Disbursements',
                  link: '/app/shares/shares-disbursements',
                },
                isVisible6d && {
                  name: 'Shares Charts',
                  link: '/app/shares/shares-charts',
                },
              ].filter(Boolean)}
              icon={<FontAwesomeIcon icon={faSackDollar} />}
            />
          )}
           {isVisible6 && (
            <LinksGroup
              header='Deposits'
              headerLink='/app/deposits'
              visible={isVisible6a || isVisible6b || isVisible6c || isVisible6d}
              childrenLinks={[
                isVisible6a && {
                  name: 'Deposits Accounts',
                  link: '/app/deposits/deposits-accounts',
                },
                isVisible6b && {
                  name: 'Payments',
                  link: '/app/deposits/deposits-transactions',
                },
                // isVisible6c && {
                //   name: 'Disbursements',
                //   link: '/app/deposits/deposits-disbursements',
                // },
                // isVisible6d && {
                //   name: 'Deposits Charts',
                //   link: '/app/deposits/deposits-charts',
                // },
              ].filter(Boolean)}
              icon={<FontAwesomeIcon icon={faCreditCardAlt} />}// Pass the icon component directly
            />
          )}
          {/* {isVisible5 && (
            <LinksGroup
              header='Transactions'
              headerLink='/app/transactions'
              visible={isVisible5a || isVisible5b}
              childrenLinks={[
                isVisible5a && {
                  name: 'View Transactions',
                  link: '/app/transactions/all-transactions',
                },
                isVisible5b && {
                  name: 'Categories',
                  link: '/app/transactions/transaction-categories',
                },
              ].filter(Boolean)}
              icon={<FontAwesomeIcon icon={faArrowRightArrowLeft} />}
            />
          )} */}
          {isVisible15 && (
            <LinksGroup
              header='Upload'
              headerLink='/app/upload'
              visible={isVisible15a}
              childrenLinks={[
                isVisible15a && {
                  name: 'Upload Members',
                  link: '/app/upload/upload-members',
                },
                isVisible15a && {
                  name: 'Upload Loans',
                  link: '/app/upload/upload-loans',
                },
                isVisible15a && {
                  name: 'Upload Reductions',
                  link: '/app/upload/upload-reductions',
                },
              ].filter(Boolean)}
              icon={<FontAwesomeIcon icon={faFolder} />}
            />
          )}
          {isVisible7 && (
            <LinksGroup
              header='Accounting'
              headerLink='/app/accounting'
              visible={ isVisible7a || isVisible7b || isVisible7c || isVisible7d || isVisible7e || isVisible7f || isVisible7g}
              childrenLinks={[
                isVisible7a && {
                  name: 'Account Charts',
                  link: '/app/accounting/account-charts',
                },
                isVisible7b && {
                  name: 'Journals',
                  link: '/app/accounting/account-journals',
                },
                isVisible7c && {
                  name: 'Balance Sheet',
                  link: '/app/accounting/balance-sheet',
                },
                isVisible7d && {
                  name: 'General Legder',
                  link: '/app/accounting/general-ledger',
                },
                isVisible7e && {
                  name: 'Profit & Loss',
                  link: '/app/accounting/profit-loss',
                },
                isVisible7f && {
                  name: 'Trial Balance',
                  link: '/app/accounting/trial-balance',
                },
                // isVisible7g && {
                //   name: 'Cash Flow',
                //   link: '/app/accounting/cash-flow',
                // },
              ].filter(Boolean)}
              icon={<FontAwesomeIcon icon={faBook} />}// Pass the icon component directly
            />
          )}
          {isVisible8 && (
            <LinksGroup
              header='Financial Reports'
              headerLink='/app/financial-reports'
              icon={<FontAwesomeIcon icon={faFile} />}
            />
          )}
          {isVisible16 && (
            <LinksGroup
              header='Budgeting'
              headerLink='/app/budgeting'
              visible={isVisible16a || isVisible16b || isVisible16c}
              childrenLinks={[
                isVisible16a && {
                  name: 'View Budget',
                  link: '/app/budgeting/view-budget',
                },
                isVisible16b && {
                  name: 'Create Budget',
                  link: '/app/budgeting/create-budget',
                },
                isVisible16c && {
                  name: 'Budget Report',
                  link: '/app/budgeting/budget-report',
                },
              ].filter(Boolean)}
              icon={<FontAwesomeIcon icon={faBriefcase} />} // Pass the icon component directly
            />
          )}
          {/* {isVisible16 && (
            <LinksGroup
              header='Budgeting'
              headerLink='/app/budgeting'
              icon={
                <i
                  class='fa fa-money'
                  aria-hidden='true'
                  style={{ fontSize: '1.2em', color: '#c0c0c0' }}
                ></i>
              } // Pass the icon component directly
            />
          )} */}
          {/* {isVisible9 && (
            <LinksGroup
              header='Records'
              headerLink='/app/records'
              visible={isVisible9a || isVisible9b || isVisible9c || isVisible9d}
              childrenLinks={[
                isVisible9a && {
                  name: 'Loan Records',
                  link: '/app/records/loans-records',
                },
                isVisible9b && {
                  name: 'Savings Records',
                  link: '/app/records/savings-records',
                },
                isVisible9c && {
                  name: 'Shares Records',
                  link: '/app/records/shares-records',
                },
                isVisible9d && {
                  name: 'Transactions Records',
                  link: '/app/records/transactions-records',
                },
              ].filter(Boolean)}
              icon={
                <i
                  class='fa fa-folder-open'
                  aria-hidden='true'
                  style={{ fontSize: '1.2em', color: '#c0c0c0' }}
                ></i>
              } // Pass the icon component directly
            />
          )} */}
          {/* {isVisible10 && (
            <LinksGroup
              header='Inquiries'
              headerLink='/app/Inquiries'
              icon={<FontAwesomeIcon icon={faMessage} />} // Pass the icon component directly
            />
          )} */}
          {isVisible14 && (
            <LinksGroup
              header='Posts'
              headerLink='/app/posts'
              icon={<FontAwesomeIcon icon={faNewspaper} />}// Pass the icon component directly
            />
          )}
          {isVisible2 && (
            <LinksGroup
              header='Permissions'
              headerLink='/app/roles-permissions'
              icon={<FontAwesomeIcon icon={faShieldHalved} />}// Pass the icon component directly
            />
          )}
          {isVisible13 && (
            <LinksGroup
              header='Settings'
              headerLink='/app/general-settings'
              icon={<FontAwesomeIcon icon={faGears} />} // Pass the icon component directly
            />
          )}
        </ul>
      </div>
    </nav>
  );
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
