// import React, { useState, useEffect } from 'react';
// import {
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   Input,
// } from 'reactstrap';
// import {fetchFirebaseConfig} from '../../../firebase';
// import { getDocs, collection, query, where, updateDoc, doc, getDoc } from 'firebase/firestore';
// import { ref, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';
// import Widget from '../../../components/Widget/Widget';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { TextButton } from '../../../components/Buttons/CustomButtons';
// import 'firebase/compat/auth'
// import firebase from 'firebase/compat/app';
// import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';

// const Settings = () => {
//   const { db, storage } = fetchFirebaseConfig();

//   const [logoImage, setLogoImage] = useState('');
//   const [institutionName, setInstitutionName] = useState('');
//   const [referenceCode, setReferenceCode] = useState('');
//   const [isSubmitting, setIsSubmitting] = useState(false);


//   useEffect(() => {
//     const fetchCompanyLogo = async () => {
//       try {
//         const companyLogoQuery = query(collection(db, 'company_logo'));
//         const companyLogoSnapshot = await getDocs(companyLogoQuery);
//         if (!companyLogoSnapshot.empty) {
//           const docData = companyLogoSnapshot.docs[0].data();
//           setReferenceCode(docData.referenceCode || '');
//           setInstitutionName(docData.institutionName || '');
//           setLogoImage(docData.path || '');
//         }
//       } catch (error) {
//         console.error('Error fetching company logo:', error);
//       }
//     };

//     fetchCompanyLogo();
//   }, [db]);

//   const handleLogoImageChange = async (event) => {
//     const selectedImage = event.target.files[0];
//     try {
//       // Upload image file to Firebase Storage
//       const storageRef = ref(storage, `logo/${selectedImage.name}`);
//       await uploadBytes(storageRef, selectedImage);

//       // Get download URL of the uploaded image
//       const logoImageUrl = await getDownloadURL(storageRef);

//       // Set the downloaded URL as the logo image source
//       setLogoImage(logoImageUrl);
//     } catch (error) {
//       console.error('Error uploading logo image:', error);
//       toast.error('Error uploading logo image!');
//     }
//   };

//   const handleInstitutionNameChange = (event) => {
//     setInstitutionName(event.target.value);
//   };

//   const handleReferenceCodeChange = (event) => {
//     setReferenceCode(event.target.value);
//   };

//    const handleLogoUpdate = async () => {
//     if (logoImage && institutionName) {
//       setIsSubmitting(true)
//       try {
//         // Update logo and institution name
//         const q = query(collection(db, 'company_logo'), where('reference', '==', 'NCAAWS'));
//         const querySnapshot = await getDocs(q);
//         querySnapshot.forEach(async (doc) => {
//           await updateDoc(doc.ref, {
//             path: logoImage,
//             institutionName: institutionName,
//             referenceCode:referenceCode,
//             updateDate: new Date().toISOString(),
//           });
//         });
//         setIsSubmitting(false)
//         toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Info updated successfully</div>); 
//       } catch (error) {
//         console.error('Error updating logo and institution name:', error);
//         toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error updating Info</div>);;
//       }
//     } else {
//       toast.warning(<div><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp;Please select a logo image and enter an institution name</div>);
//     }
//   };


//   return (
//     <div>
//       <Row>
//         <Col xs={12}>
//         <WidgetShadow
//            style={{ height: '300px'}} 
//             title={<h5><span className="fw-normal">Edit Organization Profile</span></h5>}
//           >
//             <Form className='mt-3'>
//                   <WidgetShadow style={{ marginBottom: '15px' }} className="text-center d-flex align-items-center justify-content-start">
//                     <img
//                       className="profileImg"
//                       src={logoImage}
//                       style={{ padding: '25px', borderRadius: '50%' }}
//                       width="150px"
//                       height="150px"
//                       alt=" "
//                     />
//                   </WidgetShadow>
//                   <Row>
//                     <Col md={8}>
//                     <FormGroup className="mb-3">
//                       <Label>Update Logo</Label>
//                       <Input
//                         type="file"
//                         id="logoInput"
//                         accept="image/*"
//                         style={{ fontSize: '13px' }}
//                         onChange={handleLogoImageChange}
//                       />
//                     </FormGroup>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={8}>
//                     <FormGroup>
//                       <Label for="institutionName">
//                         Institution Name
//                       </Label>
//                       <Input
//                         id="institutionName"
//                         style={{ borderRadius: '7px' }}
//                         name="institutionName"
//                         placeholder="Institution name"
//                         type="text"
//                         value={institutionName}
//                         onChange={handleInstitutionNameChange}
//                       />
//                     </FormGroup>
//                     </Col>
//                   </Row>
//                   <Row>
//                   <Col md={8}>
//                   <FormGroup>
//                     <Label for="InstitutionCode">
//                       Institution Code
//                     </Label>
//                     <Input
//                       id="InstitutionCode"
//                       style={{ borderRadius: '7px' }}
//                       name="InstitutionCode"
//                       placeholder="Institution Code"
//                       type='text'
//                       value={referenceCode}
//                       onChange={handleReferenceCodeChange}
//                     />
//                   </FormGroup>
//                   </Col>
//               </Row>
//               <TextButton label='Save' onClick={handleLogoUpdate} isLoading={isSubmitting} isBorderRadius={true} loadingLabel='Saving'/>
//             </Form>
//           </WidgetShadow>
//         </Col>
//       </Row>
//       {/* <ToastContainer position="top-right" autoClose={4000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{ fontSize:'13.5px'}}/> */}
//     </div>
//   );
// };

// export default Settings;




import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import {fetchFirebaseConfig} from '../../../firebase';
import { getDocs, collection, query, where, updateDoc, doc, getDoc } from 'firebase/firestore';
import { ref, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';
import Widget from '../../../components/Widget/Widget';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';

const Settings = () => {
  const { db, storage } = fetchFirebaseConfig();

  const [logoImage, setLogoImage] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const [processingStatus, setProcessingStatus] = useState('');

  useEffect(() => {
    const fetchCompanyLogo = async () => {
      try {
        const companyLogoQuery = query(collection(db, 'company_logo'));
        const companyLogoSnapshot = await getDocs(companyLogoQuery);
        if (!companyLogoSnapshot.empty) {
          const docData = companyLogoSnapshot.docs[0].data();
          setReferenceCode(docData.referenceCode || '');
          setInstitutionName(docData.institutionName || '');
          setLogoImage(docData.path || '');
        }
      } catch (error) {
        console.error('Error fetching company logo:', error);
      }
    };

    fetchCompanyLogo();
  }, [db]);

  const removeBackground = async (imageFile) => {
    const apiKey = "Q4DyvCVGAgzbhcoAzct5JCvW";
    const apiUrl = "https://api.remove.bg/v1.0/removebg";

    const formData = new FormData();
    formData.append("image_file", imageFile, imageFile.name);
    formData.append("size", 'auto');

    try {
      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'X-Api-Key': apiKey
        },
        body: formData
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.blob();
      return new File([data], "background_removed.png", { type: "image/png" });
    } catch (error) {
      console.error('Error removing background:', error);
      throw error;
    }
  };

  const handleLogoImageChange = async (event) => {
    const selectedImage = event.target.files[0];
    setIsProcessingImage(true);
    setProcessingStatus('Processing image...');
    
    try {
      let imageToUpload;
      try {
        // Attempt to remove background from the selected image
        imageToUpload = await removeBackground(selectedImage);
        // Success message removed as per your request
      } catch (removeError) {
        console.error('Error removing background:', removeError);
        setProcessingStatus('Error removing background. Uploading original image.');
        imageToUpload = selectedImage;
      }
  
      // Upload image (either background-removed or original) to Firebase Storage
      const storageRef = ref(storage, `logo/${imageToUpload.name}`);
      await uploadBytes(storageRef, imageToUpload);
  
      // Get download URL of the uploaded image
      const logoImageUrl = await getDownloadURL(storageRef);
  
      // Set the downloaded URL as the logo image source
      setLogoImage(logoImageUrl);
    } catch (error) {
      console.error('Error uploading logo image:', error);
      toast.error('Error uploading logo image!');
      setProcessingStatus('Error uploading image.');
    } finally {
      setIsProcessingImage(false);
      setProcessingStatus(''); // Clear the processing status
    }
  };

  const handleInstitutionNameChange = (event) => {
    setInstitutionName(event.target.value);
  };

  const handleReferenceCodeChange = (event) => {
    setReferenceCode(event.target.value);
  };

   const handleLogoUpdate = async () => {
    if (logoImage && institutionName) {
      setIsSubmitting(true)
      try {
        // Update logo and institution name
        const q = query(collection(db, 'company_logo'), where('reference', '==', 'NCAAWS'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, {
            path: logoImage,
            institutionName: institutionName,
            referenceCode: referenceCode,
            updateDate: new Date().toISOString(),
          });
        });
        setIsSubmitting(false)
        toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Info updated successfully</div>); 
      } catch (error) {
        console.error('Error updating logo and institution name:', error);
        toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error updating Info</div>);
      }
    } else {
      toast.warning(<div><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp;Please select a logo image and enter an institution name</div>);
    }
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
        <WidgetShadow
            title={<h5><span className="fw-normal">Edit Organization Profile</span></h5>}
          >
            <Form className='mt-3'>
            <Col xs={8}>
                  <WidgetShadow style={{ marginBottom: '15px' }} className="text-center d-flex align-items-center justify-content-start">
                    <img
                      className="profileImg"
                      src={logoImage}
                      style={{ padding: '25px', borderRadius: '50%' }}
                      width="150px"
                      height="150px"
                      alt=" "
                    />
                  </WidgetShadow>
                  </Col>
                  <Row>
                    <Col md={8}>
                    <FormGroup className="mb-3">
                      <Label>Update Logo</Label>
                      <Input
                        type="file"
                        id="logoInput"
                        accept="image/*"
                        style={{ fontSize: '13px' }}
                        onChange={handleLogoImageChange}
                        disabled={isProcessingImage}
                      />
                      {isProcessingImage && <span>Processing image...</span>}
                      {processingStatus && processingStatus !== 'Processing image...' && <span>{processingStatus}</span>}
                    </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                    <FormGroup>
                      <Label for="institutionName">
                        Institution Name
                      </Label>
                      <Input
                        id="institutionName"
                        style={{ borderRadius: '7px' }}
                        name="institutionName"
                        placeholder="Institution name"
                        type="text"
                        value={institutionName}
                        onChange={handleInstitutionNameChange}
                      />
                    </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col md={8}>
                  <FormGroup>
                    <Label for="InstitutionCode">
                      Institution Code
                    </Label>
                    <Input
                      id="InstitutionCode"
                      style={{ borderRadius: '7px' }}
                      name="InstitutionCode"
                      placeholder="Institution Code"
                      type='text'
                      value={referenceCode}
                      onChange={handleReferenceCodeChange}
                    />
                  </FormGroup>
                  </Col>
              </Row>
              <TextButton label='Save' onClick={handleLogoUpdate} isLoading={isSubmitting} isBorderRadius={true} loadingLabel='Saving' disabled={isProcessingImage || isSubmitting}/>
            </Form>
          </WidgetShadow>
        </Col>
      </Row>
      {/* <ToastContainer position="top-right" autoClose={4000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{ fontSize:'13.5px'}}/> */}
    </div>
  );
};

export default Settings;