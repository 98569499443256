import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import s from './StatsCard.module.scss';

class StatsCard extends React.Component {
  static propTypes = {
    title: PropTypes.node,
    value: PropTypes.node,
    icon: PropTypes.node, 
    className: PropTypes.string,
    color: PropTypes.string,
    showTSH: PropTypes.bool,  // Add showTSH prop
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  static defaultProps = {
    title: null,
    value: null,
    icon: null,
    className: '',
    color: '#28d456',
    showTSH: false,  // Default value
    children: [],
  };

  render() {
    const { icon, title, value, color, showTSH } = this.props;
    let iconClass = '';

    if (icon && icon.props && icon.props.className) {
      const iconClasses = icon.props.className.split(' ');
      iconClasses.forEach((className) => {
        if (className.startsWith('fa-')) {
          iconClass = `icon-${className}`;
        }
      });
    }

    const iconWrapperStyle = {
      backgroundColor: color,
    };

    return (
      <section className={cx(s.widget, this.props.className)}>
        <div className={s.iconContainer}>
          {icon && (
            <div className={cx(s.iconWrapper)} style={iconWrapperStyle}>
              <div className={cx(s.icon, iconClass)}>{icon}</div>
            </div>
          )}
          <div className={s.titleContainer}>
            {title}
          </div>
          <div className={s.valueContainer}>
            {showTSH && <span className={s.tshLabel}>Tsh</span>}  {/* Display TSH if showTSH is true */}
            {value}
          </div>
        </div>
        <div className={s.content}>
          {this.props.children}
        </div>
      </section>
    );
  }
}

export default StatsCard;







