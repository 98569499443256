import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Badge,
} from 'reactstrap';
import Widget from '../../components/Widget';
import s from './SavingsCharts.module.scss';
import SavingsWidgets from './savingsWidgets/savingsWidgets';
import AreaPayDis from './savingsCharts/AreaPayDis';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { getThemeColor } from '../../components/ThemeSetUp';

const SavingsCharts = () => {

  const [themeColor, setThemeColor] = useState('');

  useEffect(() =>{
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  },[])

  return (
    <div className={s.root}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Savings</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Charts</span></Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">Savings visualizations</h4>
      <Row>
        
         <SavingsWidgets/>

        {/* Area Chart */}
        <Col xs={12}>
          <WidgetShadow
           style={{ height: '300px'}} 
           title={<h5><span className="fw-semi-bold">Disbursements Versus Payments Trend</span></h5>}
          >
            <AreaPayDis />
          </WidgetShadow>
        </Col>
      </Row>
    </div>
  );
};

export default SavingsCharts;


