import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import Widget from '../Widget/Widget';
import {fetchFirebaseConfig} from '../../firebase';
import { query, collection, where, onSnapshot } from 'firebase/firestore'; // Ensure these imports are correct

const TabNavigation = ({ activeTab, handleTabChange, tabContent }) => {
  const tabLinkStyle = {
    transition: 'border-radius 0.3s ease',
    cursor: 'pointer'
  };

  const [color, setThemeColor] = useState('');

  useEffect(() => {
    const { db } = fetchFirebaseConfig();

    const q = query(collection(db, 'ThemeSettings'), where('companyID', '==', 'DSP'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const themeColor = doc.data().themeColor || '';
        setThemeColor(themeColor);
      });
    });

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, []); // Empty dependency array means this effect runs once on mount

  const activeTabLinkStyle = {
    backgroundColor: color,
    color: 'white',
    borderColor: color, // Updated to use the current theme color
    borderRadius: '6px'
  };

  return (
    <div>
      <style>
        {`
          .nav-link:hover {
            border-radius: 4px;
          }
        `}
      </style>
      <Nav tabs style={{ overflowX: 'auto', overflowY: 'auto' }}>
        {Object.keys(tabContent).map((key) => (
          <NavItem key={key}>
            <NavLink
              className={activeTab === key ? 'active green-tab' : ''}
              onClick={() => handleTabChange(key)}
              style={activeTab === key ? { ...tabLinkStyle, ...activeTabLinkStyle } : tabLinkStyle}
            >
              <span style={{ fontSize: '12.5px', fontWeight: 'bold' }}>
                &nbsp;&nbsp;{tabContent[key].label}
              </span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {Object.keys(tabContent).map((key) => (
          <TabPane tabId={key} key={key}>
            <Widget>
              <div className="align-items-center">{tabContent[key].content}</div>
            </Widget>
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

TabNavigation.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  tabContent: PropTypes.object.isRequired,
};

export default TabNavigation;

