import React, { useRef, useState, useEffect } from 'react';
import {
  Button as Click,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from 'reactstrap';
import { Button, Space, Table, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getDocs, collection } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { getThemeColor } from '../../components/ThemeSetUp';

const Ledger = () => {
  const [themeColor, setThemeColor] = useState('');
  const [ledgerData, setLedgerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountCategoryMap, setAccountCategoryMap] = useState({});
  const searchInput = useRef(null);
  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    fetchLedgerData();
    fetchAccountCategories();
    fetchAccountNames();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, [db]);

  const fetchLedgerData = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'Journal'));
      const data = querySnapshot.docs.map((doc) => doc.data());
      setLedgerData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ledger data: ', error);
      setLoading(false);
    }
  };

  const fetchAccountCategories = async () => {
    try {
      const collections = [
        'Assets',
        'Liabilities',
        'Expenses',
        'Income',
        'Equity',
      ];
      const categoryMap = {};

      for (const collectionName of collections) {
        const querySnapshot = await getDocs(collection(db, collectionName));
        querySnapshot.docs.forEach((doc) => {
          categoryMap[doc.data().account_name] = collectionName;
        });
      }

      setAccountCategoryMap(categoryMap);
    } catch (error) {
      console.error('Error fetching account categories: ', error);
    }
  };

  const fetchAccountNames = async () => {
    try {
      const assetsSnapshot = await getDocs(collection(db, 'Assets'));
      const liabilitiesSnapshot = await getDocs(collection(db, 'Liabilities'));
      const expensesSnapshot = await getDocs(collection(db, 'Expenses'));
      const incomeSnapshot = await getDocs(collection(db, 'Income'));
      const equitySnapshot = await getDocs(collection(db, 'Equity'));

      const assetsNames = assetsSnapshot.docs.map(
        (doc) => doc.data().account_name
      );
      const liabilitiesNames = liabilitiesSnapshot.docs.map(
        (doc) => doc.data().account_name
      );
      const expensesNames = expensesSnapshot.docs.map(
        (doc) => doc.data().account_name
      );
      const incomeNames = incomeSnapshot.docs.map(
        (doc) => doc.data().account_name
      );
      const equityNames = equitySnapshot.docs.map(
        (doc) => doc.data().account_name
      );

      const allAccountNames = [
        ...assetsNames,
        ...liabilitiesNames,
        ...expensesNames,
        ...incomeNames,
        ...equityNames,
      ];
      setAccountOptions([...new Set(allAccountNames)]);
    } catch (error) {
      console.error('Error fetching account names: ', error);
    }
  };

  const handleFilter = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'Journal'));
      const data = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((entry) => {
          const entryDate = new Date(entry.date);
          const start = startDate ? new Date(startDate) : null;
          const end = endDate ? new Date(endDate) : null;

          if (start && entryDate < start) {
            return false;
          }

          if (end && entryDate > end) {
            return false;
          }

          if (
            selectedAccount &&
            entry.entries.every(
              (entryItem) =>
                entryItem.debit_account !== selectedAccount &&
                entryItem.credit_account !== selectedAccount
            )
          ) {
            return false;
          }

          return true;
        });

      setLedgerData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ledger data: ', error);
      setLoading(false);
    }
  };

  const handleRedo = () => {
    fetchLedgerData();
    setEndDate('');
    setStartDate('');
  };


  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, record, index) => {
        if (record.key !== 'total') {
          return index + 1; // Render the row number for all rows except the "Total" row
        }
        return null; // Return null to prevent rendering for the "Total" row
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text}</b>; // Render the text in bold for the "Total" row
        }
        return renderDateRequested(text, record);
      },
    },    
    {
      title: 'Code',
      dataIndex: 'journalCode',
      key: 'journalCode',
      align: 'center',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      align: 'center',
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      align: 'center',
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text.toLocaleString()}</b>; // Render the text in bold for the "Total" row
        }
        return text.toLocaleString();
      },
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      align: 'center',
      render: (text, record) => {
        if (record.key === 'total') {
          return <b>{text.toLocaleString()}</b>; // Render the text in bold for the "Total" row
        }
        return text.toLocaleString();
      },
    },
    // {
    //   title: 'Balance',
    //   dataIndex: 'balance',
    //   key: 'balance',
    //   align: 'center',
    //   ...getColumnSearchProps('balance'),
    // },
  ];

  const formatValue = (value) => {
    return value !== undefined && value !== 0
      ? value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '--';
  };

  const formatDebitCredit = (amount, accountType, isDebit) => {
    if (accountType === 'Assets') {
      return isDebit ? amount : -amount;
    } else if (
      accountType === 'Liabilities' ||
      accountType === 'Equity' ||
      accountType === 'Income'
    ) {
      return isDebit ? -amount : amount;
    } else if (accountType === 'Expenses') {
      return isDebit ? amount : -amount;
    } else {
      return amount;
    }
  };

  const calculateBalance = (data) => {
    let balance = 0;
    return data.map((entry) => {
      const debit =
        entry.debit !== '--' ? parseFloat(entry.debit.replace(/,/g, '')) : 0;
      const credit =
        entry.credit !== '--' ? parseFloat(entry.credit.replace(/,/g, '')) : 0;
      balance += debit + credit;
      return {
        ...entry,
        balance: formatValue(balance),
      };
    });
  };

  const formattedLedgerData = ledgerData.reduce((acc, entry) => {
    entry.entries.forEach((entryItem) => {
      const category =
        accountCategoryMap[entryItem.debit_account] ||
        accountCategoryMap[entryItem.credit_account] ||
        '';
      const accountName = entryItem.debit_account || entryItem.credit_account;
      const rowData = {
        id: acc.length + 1,
        date: entry.date,
        description: entry.description,
        transactionType: entry.transactionType,
        // debit: formatValue(formatDebitCredit(entryItem.debit_amount, accountCategoryMap[entryItem.debit_account], true)),
        // credit: formatValue(formatDebitCredit(entryItem.credit_amount, accountCategoryMap[entryItem.credit_account], false)),
        debit: formatValue(
          entryItem.debit_amount,
          accountCategoryMap[entryItem.debit_account],
          true
        ),
        credit: formatValue(
          entryItem.credit_amount,
          accountCategoryMap[entryItem.credit_account],
          false
        ),
        category,
        accountName,
        balance: '',
        journalCode: entry.journalCode,
      };
      acc.push(rowData);
    });
    return acc;
  }, []);

  // const ledgerDataWithBalance = calculateBalance(formattedLedgerData);
  const ledgerDataWithBalance = formattedLedgerData;

  // Calculate total debit and credit
  const totalDebit = ledgerDataWithBalance.reduce((total, item) => {
    const debit =
      item.debit !== '--' ? parseFloat(item.debit.replace(/,/g, '')) : 0;
    return total + debit;
  }, 0);

  const totalCredit = ledgerDataWithBalance.reduce((total, item) => {
    const credit =
      item.credit !== '--' ? parseFloat(item.credit.replace(/,/g, '')) : 0;
    return total + credit;
  }, 0);

  const formattedTotalDebit = totalDebit.toLocaleString();
  const formattedTotalCredit = totalCredit.toLocaleString();

  const totalRow = {
    key: 'total',
    date: 'TOTAL',
    description: '',
    transactionType: '',
    category: '',
    account_name: '',
    debit: formattedTotalDebit,
    credit: formattedTotalCredit,
    isTotalRow: true, // Add a flag to identify the total row
  };

  const tableData = [...ledgerDataWithBalance, totalRow];

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Accounting</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>General Ledger</span></Breadcrumb.Item>
      </Breadcrumb>

      <h4 className='mb-lg'>General Ledger</h4>
      <WidgetShadow
        title={
          <h7>
            <span className='fw-semi-bold'>Data Range</span>
          </h7>
        }
      >
        <Row style={{ marginTop: '15px' }}>
          <Col md={6}>
            <FormGroup>
              <Label for='exampleFirstName'>Start Date</Label>
              <Input
               style={{ borderRadius: '7px' }}
                id='exampleFirstName'
                name='startDate'
                placeholder='Start Date'
                type='date'
                bsSize='sm'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for='exampleMiddleName'>End Date</Label>
              <Input
               style={{ borderRadius: '7px' }}
                id='endDate'
                name='middlename'
                placeholder='End date'
                type='date'
                bsSize='sm'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for='exampleAccName'>Account name</Label>
              <Input
               style={{ borderRadius: '7px' }}
                id='accName'
                name='accountname'
                placeholder='Account name'
                type='select'
                bsSize='sm'
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.target.value)}
              >
                <option value=''>Select Account</option>
                {accountOptions.map((accountName) => (
                  <option key={accountName} value={accountName}>
                    {accountName}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <TextButton label='Search' onClick={handleFilter} size='sm' isBorderRadius={true}/>
          <div style={{ marginLeft: '10px' }}></div>
          <TextButton label='Reset' onClick={handleRedo} size='sm' isBorderRadius={true}/>
        </div>
      </WidgetShadow>
      <WidgetShadow>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={tableData} loading={loading} pagination={false} size='small'/>
        </div>
      </WidgetShadow>
    </div>
  );
};

export default Ledger;
