import React, { useEffect, useState } from 'react';
import { Select, Button, Upload, Spin, message, Tag } from 'antd';
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  addDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import * as XLSX from 'xlsx';
import Widget from '../../../components/Widget/Widget';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';

const { Option } = Select;

const UploadReductions = () => {
  const { db } = fetchFirebaseConfig();
  const [loanType, setLoanType] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState([]);
  const [users, setUsers] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    const fetchLoans = async () => {
      if (!loanType) return;

      setLoading(true);
      try {
        const loansQuery = query(
          collection(db, 'Loans'),
          where('loanSchedual', '==', loanType)
        );
        const loansSnapshot = await getDocs(loansQuery);
        const loansData = loansSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLoans(loansData);
        fetchUsers(loansData);
      } catch (error) {
        console.error('Error fetching loans:', error);
        setLoading(false);
      }
    };

    const fetchUsers = async (loansData) => {
      try {
        const userPromises = loansData.map((loan) => getDoc(loan.user));
        const userSnapshots = await Promise.all(userPromises);
        const usersData = userSnapshots.map((snapshot) => ({
          id: snapshot.id,
          ...snapshot.data(),
        }));
        setUsers(usersData);
        combineData(loansData, usersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchLoans();
  }, [loanType]);

  const combineData = (loansData, usersData) => {
    const combined = loansData.map((loan) => {
      const user = usersData.find((user) => user.id === loan.user.id);
      return {
        userId: user?.id,
        username: user?.display_name,
        loanId: loan.id,
        reductionAmount: null,
        date: null,
      };
    });
    setCombinedData(combined);
  };

  const generateExcelTemplate = () => {
    const filteredData = combinedData.filter((record) =>
      selectedUsers.includes(record.userId)
    );

    const worksheetData = [
      ['User ID', 'Username', 'Loan ID', 'Reduction Amount', 'Date'],
      ...filteredData.map((record) => [
        record.userId,
        record.username,
        record.loanId,
        record.reductionAmount,
        record.date,
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reductions');

    XLSX.writeFile(workbook, 'ReductionsTemplate.xlsx');
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const parseDate = (dateValue) => {
    if (typeof dateValue !== 'number') {
      return null; // Return null for non-number values
    }

    // Convert the numeric value to a date object, assuming the reference date is January 1, 1900
    const baseDate = new Date(1900, 0, 1);
    const date = new Date(
      baseDate.getTime() + (dateValue - 2) * 24 * 60 * 60 * 1000
    );

    // Check if the parsed date is valid
    if (isNaN(date.getTime())) {
      return null; // Return null for invalid dates
    }

    return date;
  };

  const validateExcelData = (jsonData) => {
    const requiredHeaders = [
      'user id',
      'username',
      'loan id',
      'reduction amount',
      'date',
    ];
    const headers = jsonData[0].map((header) => header.toLowerCase());
    const hasAllHeaders = requiredHeaders.every((header) =>
      headers.includes(header)
    );

    if (!hasAllHeaders) {
      return 'Invalid Excel template: Missing required columns.';
    }

    for (let i = 1; i < jsonData.length; i++) {
      const row = jsonData[i];
      if (
        typeof row[0] !== 'string' ||
        typeof row[1] !== 'string' ||
        typeof row[2] !== 'string'
      ) {
        return `Invalid data type in row ${i +
          1}: User ID, Username, and Loan ID must be strings.`;
      }
      if (typeof row[3] !== 'number') {
        return `Invalid data type in row ${i +
          1}, column 4: Reduction Amount must be a number.`;
      }
      if (row[4] && !isValidDate(row[4])) {
        return `Invalid date format in row ${i +
          1}, column 5: Date must be in a valid date format.`;
      }
    }
    return '';
  };

  const handleUpload = async (file) => {
    setUploadError('');
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const validationError = validateExcelData(jsonData);
      if (validationError) {
        setUploadError(validationError);
        return;
      }

      const parsedData = jsonData.slice(1).map((row) => {
        const dateValue = row[4];
        const parsedDate = parseDate(dateValue);
        const isoDate = parsedDate ? parsedDate.toISOString() : null;

        return {
          userId: row[0],
          loanId: row[2],
          reductionAmount: row[3],
          date: isoDate,
        };
      });

      setCombinedData(parsedData);
      setFileList([file]);
      setIsSaveDisabled(false);
    } catch (error) {
      setUploadError('Error reading Excel file');
      console.error('Error reading Excel file:', error);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const transactionsCollection = collection(db, 'allTransaction2');
      const addTransactionsPromises = combinedData.map((record) =>
        addDoc(transactionsCollection, {
          transactionUser: doc(db, 'users', record.userId),
          transactionType: 'Loan Payment',
          transactionId: record.loanId,
          transactionDate: record.date
            ? new Date(record.date)
            : serverTimestamp(),
          transactionAmount: record.reductionAmount,
          paySlip: '',
          approvalStatus: 'Approved',
        })
      );

      await Promise.all(addTransactionsPromises);
      toast.success(
        <div>
          <i
            className='fa fa-check'
            aria-hidden='true'
            style={{ fontSize: '1.5rem' }}
          ></i>
          &nbsp;&nbsp; Deductions Saved Successfully
        </div>
      );
    } catch (error) {
      console.error('Error saving transactions:', error);
      message.error('Error saving transactions.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.id));
    }
  };

  const handleUserSelection = (value) => {
    if (value.includes('selectAll')) {
      if (selectedUsers.length === users.length) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(users.map((user) => user.id));
      }
    } else {
      setSelectedUsers(value);
    }
  };

  const tagRender = ({ label, closable, onClose }) => {
    return (
      <Tag
        color='blue'
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (  
    <div>
   <Breadcrumb separator=">">
    <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
    <Breadcrumb.Item>Upload</Breadcrumb.Item>
    <Breadcrumb.Item>Upload Loans</Breadcrumb.Item>
  </Breadcrumb>
    <WidgetShadow>
      <div>
        <h5 className='mb-lg'>Upload Reductions</h5>

        <div style={{ marginBottom: '20px' }}>
          <Select
            placeholder='Select Loan Type'
            style={{ width: 200, marginRight: '10px' }}
            onChange={(value) => setLoanType(value)}
          >
            <Option value='Chap Chap'>Chap Chap Loan</Option>
            <Option value='Development Fund'>Development Fund</Option>
            <Option value='Long Term Loan'>Long Term Loan</Option>
          </Select>
          <Select
            mode='multiple'
            placeholder='Select Users'
            style={{ width: 300 }}
            value={selectedUsers}
            onChange={handleUserSelection}
            tagRender={tagRender}
            optionLabelProp='label'
          >
            <Option
              key='selectAll'
              value='selectAll'
              label='Select All'
              onClick={handleSelectAllUsers}
            >
              {selectedUsers.length === users.length
                ? 'Deselect All'
                : 'Select All'}
            </Option>
            {users.map((user) => (
              <Option key={user.id} value={user.id} label={user.display_name}>
                {user.display_name}
              </Option>
            ))}
          </Select>
        </div>
        <Button
          onClick={generateExcelTemplate}
          style={{ marginBottom: '10px', marginRight: '5px' }}
          disabled={selectedUsers.length === 0}
        >
          Download Excel Template
        </Button>
        {loading && <Spin />}
        {uploadError && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            {uploadError}
          </div>
        )}
        <Upload
          beforeUpload={(file) => {
            handleUpload(file);
            return false;
          }}
          fileList={fileList}
          onChange={({ fileList }) => setFileList(fileList.slice(-1))}
          maxCount={1}
        >
          <Button>Upload Filled Template</Button>
        </Upload>
        <Button type='primary' onClick={handleSave} disabled={isSaveDisabled}>
          Save
        </Button>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ fontSize: '13.5px' }}
      />
    </WidgetShadow>
    </div>
  );
};

export default UploadReductions;

// import React, { useEffect, useState } from 'react';
// import { Select, Button, Upload, Spin, message, Tag } from 'antd';
// import {
//   collection,
//   getDocs,
//   getDoc,
//   query,
//   where,
//   addDoc,
//   doc,
//   serverTimestamp,
// } from 'firebase/firestore';
// import { fetchFirebaseConfig } from '../../../firebase';
// import * as XLSX from 'xlsx';
// import Widget from '../../../components/Widget/Widget';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const { Option } = Select;

// const UploadReductions = () => {
//   const { db } = fetchFirebaseConfig();
//   const [loanType, setLoanType] = useState('');
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [loans, setLoans] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [combinedData, setCombinedData] = useState([]);
//   const [fileList, setFileList] = useState([]);
//   const [uploadError, setUploadError] = useState('');
//   const [isSaveDisabled, setIsSaveDisabled] = useState(true);

//   useEffect(() => {
//     const fetchLoans = async () => {
//       if (!loanType) return;

//       setLoading(true);
//       try {
//         const loansQuery = query(
//           collection(db, 'Loans'),
//           where('loanSchedual', '==', loanType)
//         );
//         const loansSnapshot = await getDocs(loansQuery);
//         const loansData = loansSnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setLoans(loansData);
//         fetchUsers(loansData);
//       } catch (error) {
//         console.error('Error fetching loans:', error);
//         setLoading(false);
//       }
//     };

//     const fetchUsers = async (loansData) => {
//       try {
//         const userPromises = loansData.map((loan) => getDoc(loan.user));
//         const userSnapshots = await Promise.all(userPromises);
//         const usersData = userSnapshots.map((snapshot) => ({
//           id: snapshot.id,
//           ...snapshot.data(),
//         }));
//         setUsers(usersData);
//         combineData(loansData, usersData);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching users:', error);
//         setLoading(false);
//       }
//     };

//     fetchLoans();
//   }, [loanType]);

//   const combineData = (loansData, usersData) => {
//     const combined = loansData.map((loan) => {
//       const user = usersData.find((user) => user.id === loan.user.id);
//       return {
//         userId: user?.id,
//         username: user?.display_name,
//         loanId: loan.id,
//         reductionAmount: null,
//         date: null,
//       };
//     });
//     setCombinedData(combined);
//   };

//   const generateExcelTemplate = () => {
//     const filteredData = combinedData.filter((record) =>
//       selectedUsers.includes(record.userId)
//     );

//     const worksheetData = [
//       ['User ID', 'Username', 'Loan ID', 'Reduction Amount', 'Date'],
//       ...filteredData.map((record) => [
//         record.userId,
//         record.username,
//         record.loanId,
//         record.reductionAmount,
//         record.date,
//       ]),
//     ];

//     const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Reductions');

//     XLSX.writeFile(workbook, 'ReductionsTemplate.xlsx');
//   };

//   const isValidDate = (dateString) => {
//     const date = new Date(dateString);
//     return !isNaN(date.getTime());
//   };

//   const parseDate = (dateValue) => {
//     if (typeof dateValue !== 'number') {
//       return null; // Return null for non-number values
//     }

//     // Convert the numeric value to a date object, assuming the reference date is January 1, 1900
//     const baseDate = new Date(1900, 0, 1);
//     const date = new Date(
//       baseDate.getTime() + (dateValue - 2) * 24 * 60 * 60 * 1000
//     );

//     // Check if the parsed date is valid
//     if (isNaN(date.getTime())) {
//       return null; // Return null for invalid dates
//     }

//     return date;
//   };

//   const validateExcelData = (jsonData) => {
//     const requiredHeaders = [
//       'user id',
//       'username',
//       'loan id',
//       'reduction amount',
//       'date',
//     ];
//     const headers = jsonData[0].map((header) => header.toLowerCase());
//     const hasAllHeaders = requiredHeaders.every((header) =>
//       headers.includes(header)
//     );

//     if (!hasAllHeaders) {
//       return 'Invalid Excel template: Missing required columns.';
//     }

//     for (let i = 1; i < jsonData.length; i++) {
//       const row = jsonData[i];
//       if (
//         typeof row[0] !== 'string' ||
//         typeof row[1] !== 'string' ||
//         typeof row[2] !== 'string'
//       ) {
//         return `Invalid data type in row ${i +
//           1}: User ID, Username, and Loan ID must be strings.`;
//       }
//       if (typeof row[3] !== 'number') {
//         return `Invalid data type in row ${i +
//           1}, column 4: Reduction Amount must be a number.`;
//       }
//       if (row[4] && !isValidDate(row[4])) {
//         return `Invalid date format in row ${i +
//           1}, column 5: Date must be in a valid date format.`;
//       }
//     }
//     return '';
//   };

//   const handleUpload = async (file) => {
//     setUploadError('');
//     try {
//       const data = await file.arrayBuffer();
//       const workbook = XLSX.read(data);
//       const worksheet = workbook.Sheets[workbook.SheetNames[0]];
//       const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

//       const validationError = validateExcelData(jsonData);
//       if (validationError) {
//         setUploadError(validationError);
//         return;
//       }

//       const parsedData = jsonData.slice(1).map((row) => {
//         const dateValue = row[4];
//         const parsedDate = parseDate(dateValue);
//         const isoDate = parsedDate ? parsedDate.toISOString() : null;

//         return {
//           userId: row[0],
//           loanId: row[2],
//           reductionAmount: row[3],
//           date: isoDate,
//         };
//       });

//       setCombinedData(parsedData);
//       setFileList([file]);
//       setIsSaveDisabled(false);
//     } catch (error) {
//       setUploadError('Error reading Excel file');
//       console.error('Error reading Excel file:', error);
//     }
//   };

//   const handleSave = async () => {
//     setLoading(true);
//     try {
//       const transactionsCollection = collection(db, 'allTransaction3');
//       const addTransactionsPromises = combinedData.map((record) =>
//         addDoc(transactionsCollection, {
//           transactionUser: doc(db, 'users', record.userId),
//           transactionType: 'Loan Payment',
//           transactionId: record.loanId,
//           transactionDate: record.date
//             ? new Date(record.date)
//             : serverTimestamp(),
//           transactionAmount: record.reductionAmount,
//           paySlip: '',
//           approvalStatus: 'Approved',
//         })
//       );

//       await Promise.all(addTransactionsPromises);
//       toast.success(
//         <div>
//           <i
//             className='fa fa-check'
//             aria-hidden='true'
//             style={{ fontSize: '1.5rem' }}
//           ></i>
//           &nbsp;&nbsp; Deductions Saved Successfully
//         </div>
//       );
//     } catch (error) {
//       console.error('Error saving transactions:', error);
//       message.error('Error saving transactions.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSelectAllUsers = () => {
//     if (selectedUsers.length === users.length) {
//       setSelectedUsers([]);
//     } else {
//       setSelectedUsers(users.map((user) => user.id));
//     }
//   };

//   const tagRender = ({ label, closable, onClose }) => {
//     return (
//       <Tag
//         color='blue'
//         closable={closable}
//         onClose={onClose}
//         style={{ marginRight: 3 }}
//       >
//         {label}
//       </Tag>
//     );
//   };

//   return (
//     <Widget>
//       <div>
//         <h5 className='mb-lg'>Upload Reductions</h5>

//         <div style={{ marginBottom: '20px' }}>
//           <Select
//             placeholder='Select Loan Type'
//             style={{ width: 200, marginRight: '10px' }}
//             onChange={(value) => setLoanType(value)}
//           >
//             <Option value='Chap Chap'>Chap Chap Loan</Option>
//             <Option value='Development Fund'>Development Fund</Option>
//             <Option value='Long Term Loan'>Long Term Loan</Option>
//           </Select>
//           <Select
//             mode='multiple'
//             placeholder='Select Users'
//             style={{ width: 300 }}
//             value={selectedUsers}
//             onChange={(value) => setSelectedUsers(value)}
//             tagRender={tagRender}
//             optionLabelProp='label'
//           >
//             <Option
//               key='selectAll'
//               value='selectAll'
//               label='Select All'
//               onClick={handleSelectAllUsers}
//             >
//               {selectedUsers.length === users.length
//                 ? 'Deselect All'
//                 : 'Select All'}
//             </Option>
//             {users.map((user) => (
//               <Option key={user.id} value={user.id} label={user.display_name}>
//                 {user.display_name}
//               </Option>
//             ))}
//           </Select>
//         </div>
//         <Button
//           onClick={generateExcelTemplate}
//           style={{ marginBottom: '10px', marginRight: '5px' }}
//           disabled={selectedUsers.length === 0}
//         >
//           Download Excel Template
//         </Button>
//         {loading && <Spin />}
//         {uploadError && (
//           <div style={{ color: 'red', marginBottom: '10px' }}>
//             {uploadError}
//           </div>
//         )}
//         <Upload
//           beforeUpload={(file) => {
//             handleUpload(file);
//             return false;
//           }}
//           fileList={fileList}
//           onChange={({ fileList }) => setFileList(fileList.slice(-1))}
//           maxCount={1}
//         >
//           <Button>Upload Filled Template</Button>
//         </Upload>
//         <Button type='primary' onClick={handleSave} disabled={isSaveDisabled}>
//           Save
//         </Button>
//       </div>
//       <ToastContainer
//         position='top-right'
//         autoClose={4000}
//         hideProgressBar={true}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         style={{ fontSize: '13.5px' }}
//       />
//     </Widget>
//   );
// };

// export default UploadReductions;
