import React, { useRef, useState, useEffect } from 'react';
import { Table } from 'antd';
import { Collapse } from 'reactstrap';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { Button as Click, ButtonGroup, Input } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { faFilter, faList, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { FormGroup, Label, Row, Col } from 'reactstrap';

const ReusableTable = ({ columns, dataSource, dateField, dateFormat = 'seconds', generateExcel, generatePDF }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const componentRef = useRef(null);
  const [printing, setPrinting] = useState(false);

  const [filterText, setFilterText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState(dataSource);
  const [isTableLoading, setIsTableLoading] = useState(false);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleSearch = () => {
    setIsTableLoading(true);
    
    let filtered = dataSource;

    if (filterText.trim() !== '') {
      const lowercasedFilter = filterText.toLowerCase();
      filtered = filtered.filter(item => {
        return Object.keys(item).some(key => 
          item[key]?.toString().toLowerCase().includes(lowercasedFilter)
        );
      });
    }

    if (startDate && endDate && dateField) {
      const start = new Date(startDate).getTime();
      const end = new Date(endDate).getTime() + 86400000; // Add one day to include the end date

      filtered = filtered.filter(item => {
        let itemDate;
        switch(dateFormat) {
          case 'seconds':
            itemDate = item[dateField]?.seconds * 1000;
            break;
          case 'milliseconds':
            itemDate = item[dateField];
            break;
          case 'iso':
            itemDate = new Date(item[dateField]).getTime();
            break;
          default:
            itemDate = item[dateField];
        }
        return itemDate >= start && itemDate <= end;
      });
    }

    setFilteredData(filtered);
    
    // Simulate an async operation
    setTimeout(() => {
      setIsTableLoading(false);
    }, 500); // Adjust the timeout as needed
  };

  const handleReset = () => {
    setIsTableLoading(true);
    
    setFilterText('');
    setStartDate('');
    setEndDate('');
    setFilteredData(dataSource);
    
    // Simulate an async operation
    setTimeout(() => {
      setIsTableLoading(false);
    }, 500); // Adjust the timeout as needed
  };

  return (
    <div>
      <Collapse isOpen={isOpen}>
        <WidgetShadow title={<h7><span className="fw-semi-bold">Filter</span></h7>}>
          <Row style={{ marginTop: '5px' }}>
            <Col md={4}>
              <FormGroup>
                <Label for="filterText">Filter Text</Label>
                <Input
                  id="filterText"
                  style={{ borderRadius: '7px' }}
                  placeholder="Type to text filter..."
                  value={filterText}
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="startDate">From:</Label>
                <Input
                  id="startDate"
                  style={{ borderRadius: '7px' }}
                  name="startDate"
                  placeholder="Start Date"
                  type="date"
                  bsSize="sm"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="endDate">To:</Label>
                <Input
                  id="endDate"
                  style={{ borderRadius: '7px' }}
                  name="endDate"
                  placeholder="End date"
                  type="date"
                  bsSize="sm"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <TextButton label='Search' size='sm' isBorderRadius={true} onClick={handleSearch} />
            <div style={{ marginLeft: '10px' }}></div>
            <TextButton label='Reset' size='sm' isBorderRadius={true} onClick={handleReset} />
          </div>
        </WidgetShadow>
      </Collapse>
      <WidgetShadow>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ButtonIcon icon={faList} size='sm' borderRadius={true} tooltipTitle='filter panel' onClick={toggle} />
          <ButtonGroup size='sm' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextButton label='CSV' style={{ marginRight: '2px' }} onClick={() => generateExcel(filteredData)} />
          <TextButton label='PDF' style={{ marginRight: '2px' }} onClick={() => generatePDF(filteredData)} />
            <ReactToPrint
              trigger={() => (
                <ButtonIcon icon={faPrint} tooltipTitle='Print' onClick={() => setPrinting(true)} size="sm" borderRadius={false} />
              )}
              content={() => componentRef.current}
              onBeforeGetContent={() => setPrinting(true)}
              onAfterPrint={() => setPrinting(false)}
            />
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table
            columns={columns}
            dataSource={filteredData}
            loading={isTableLoading}
            pagination={true}
            size="small"
          />
        </div>
      </WidgetShadow>
    </div>
  );
};

export default ReusableTable;