import React, { useRef, useState, useEffect } from 'react';
import { Button, Input, Space, Table,Tooltip } from 'antd';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import { faAdd, faFileExport, faPrint, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app';
import { getThemeColor } from '../../components/ThemeSetUp';
import s from './Styles.module.scss';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import ReusableTable from '../../components/CustomTable/table';
import AddLoanAccounts from './addAccounts';

const CreatedLoanAccounts = ({loanType }) => {
  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [themeColor, setThemeColor] = useState('');
  const [modal, setModal] = useState(false);

  const history = useHistory();
  const componentRef = useRef(null);
  const { db } = fetchFirebaseConfig();
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchLoansAccounts();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  const fetchLoansAccounts = async () => {
    try {
      setLoadingData(true);
      const currentUser = firebase.auth().currentUser;
      const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const currentUserCompanyID = currentUserData.companyID;

      const querySnapshot = await getDocs(query(collection(db, 'Assets'), where('loanType', '==', loanType)));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setData(fetchedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };


  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    }
    return '';
  };

  const formatNumber = (number) => number?.toLocaleString();

  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'account_name', width: 30 },
      { header: 'Long Term Balance', key: 'balances', width: 20 },
      { header: 'Date Created', key: 'createDate', width: 15 },
    ];

    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };

    await generateExcelFile({
      sheetName: `${loanType} Accounts`,
      title: `${loanType} Accounts`,
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: `${loanType}Accounts.xlsx`,
      numericalColumns: ['balances'],
      useFullName: true,
      useLatestBalance: true,
      customStyles: {
        headerFill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF5F7FA' },
        },
      },
    });
  };

  const generatePDF = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'account_name', width: 30 },
      { header: `${loanType} Balance`, key: 'balances', width: 20 },
      { header: 'Date Created', key: 'createDate', width: 15 },
    ];

    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };

    await generatePDFFile({
      title: `${loanType} Accounts`,
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: `${loanType}Accounts.pdf`,
      useFullName: true,
      useLatestBalance: true,
    });
  };

  
  const refreshData = async () => {
    await fetchLoansAccounts();
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center',
      render: (text, record) => (
        <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
          {text}
        </Link>
      ),
    },
    {
      title: `${loanType} Balance`,
      dataIndex: 'balances',
      key: 'balances',
      align: 'center',
      render: (balances) => {
        if (Array.isArray(balances)) {
          const latestBalance = balances.reduce((prev, current) =>
            new Date(prev.date) > new Date(current.date) ? prev : current
          );
          return formatNumber(latestBalance.balance);
        }
        return 'N/A';
      },
    },
    {
      title: 'Date Created',
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'center',
      render: renderDateRequested,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <Space>
          <ButtonIcon
            icon={faUserShield}
            tooltipTitle="Panel"
            onClick={() => history.push(`/app/loans/loans-panel/${record.id}`)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      {/* <h5>{`${loanType} Accounts`}</h5> */}
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>       
        <ButtonIcon icon={faAdd} tooltipTitle='Add accounts'  onClick={toggle} size='sm' isBorderRadius={true}/>
       </div>
          <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="createDate"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
        {/* Loan Accounts */}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader style={{border:'none'}} toggle={toggle}>Add Accounts</ModalHeader>
          <ModalBody>
            <Widget>
           <AddLoanAccounts loanType={loanType} onAccountsAdded={refreshData} />
            </Widget>
          </ModalBody>
        </Modal>
    </div>
  );
};

export default CreatedLoanAccounts;
