
// LinksGroup.js

// import React, { Component } from 'react';
// import cx from 'classnames';
// import PropTypes from 'prop-types';
// import { NavLink } from 'react-router-dom';
// import { withRouter } from 'react-router';
// import { Collapse } from 'reactstrap';
// import { Route } from 'react-router';

// import s from './LinksGroup.module.scss';

// class LinksGroup extends Component {
//   static propTypes = {
//     header: PropTypes.node.isRequired,
//     headerLink: PropTypes.string,
//     childrenLinks: PropTypes.array,
//     icon: PropTypes.element,
//     className: PropTypes.string,
//     location: PropTypes.object.isRequired, // Location prop from withRouter
//   };

//   static defaultProps = {
//     headerLink: null,
//     childrenLinks: null,
//     className: '',
//     icon: null,
//   };

//   constructor(props) {
//     super(props);

//     this.state = {
//       isOpen: false,
//       linkClicked: false, // Track whether a link inside the collapse has been clicked
//     };
//   }

//   componentDidUpdate(prevProps) {
//     const { location, headerLink } = this.props;
//     if (location.pathname !== prevProps.location.pathname) {
//       if (!location.pathname.startsWith(headerLink) && this.state.isOpen && !this.state.linkClicked) {
//         // If the current location path changes to a completely separate link
//         // and the collapse is open and no link inside the collapse has been clicked
//         // then close the collapse
//         this.setState({ isOpen: false });
//       }
//       // Reset linkClicked state for next update
//       this.setState({ linkClicked: false });
//     }
//   }

//   handleLinkClick = () => {
//     // Set linkClicked state to true when a link inside the collapse is clicked
//     this.setState({ linkClicked: true });
//   };

//   render() {
//     const { className, childrenLinks, headerLink, header, icon } = this.props;
//     const { isOpen } = this.state;
//     if (!childrenLinks) {
//       return (
//         <li className={cx(s.headerLink, className)}>
//           <NavLink
//             to={headerLink}
//             activeClassName={s.headerLinkActive}
//             exact
//           >
//             <div>
//               {icon && icon}
//               <span style={{ marginLeft: '22px' }}>{header}</span>
//             </div>
//           </NavLink>
//         </li>
//       );
//     }

//     return (
//       <Route
//         path={headerLink}
//         children={({ match }) => {
//           return (
//             <li className={cx(s.headerLink, className)}>
//               <a
//                 className={cx({[s.headerLinkActive]: !!match && match.url.indexOf(headerLink) !== -1 })}
//                 onClick={() => this.setState({ isOpen: !isOpen })}
//               >
//                 <div>
//                   {icon && icon}
//                   <span style={{ marginLeft: '22px' }}>{header}</span>
//                 </div>
//                 <b className={cx('fa fa-caret-down', s.arrow, {[s.arrowActive]: isOpen})} />
//               </a>
//               <Collapse className={s.panel} isOpen={isOpen}>
//                 <ul>
//                   {childrenLinks &&
//                   childrenLinks.map(child => (
//                     <li key={child.name}>
//                       <NavLink
//                         to={child.link}
//                         exact
//                         onClick={this.handleLinkClick} // Add onClick handler to set linkClicked state
//                         activeClassName={s.headerLinkActive}
//                         style={{ marginLeft: '15px' }}
//                       >
//                         {child.name}
//                       </NavLink>
//                     </li>
//                   ))}
//                 </ul>
//               </Collapse>
//             </li>
//           );
//         }}
//       />
//     );
//   }
// }

// // Wrap the component with withRouter to access the location prop
// export default withRouter(LinksGroup);




import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Collapse } from 'reactstrap';
import { Route } from 'react-router';
import { fetchFirebaseConfig } from '../../../firebase';
import { collection, query, where, getDoc, doc, onSnapshot } from 'firebase/firestore';
import firebase from 'firebase/compat/app'; // Adjust the import path based on your setup
import 'firebase/compat/auth';
import s from './LinksGroup.module.scss';

class LinksGroup extends Component {
  static propTypes = {
    header: PropTypes.node.isRequired,
    headerLink: PropTypes.string,
    childrenLinks: PropTypes.array,
    icon: PropTypes.element,
    className: PropTypes.string,
    location: PropTypes.object.isRequired, // Location prop from withRouter
    themeColor: PropTypes.string,
  };

  static defaultProps = {
    headerLink: null,
    childrenLinks: null,
    className: '',
    icon: null,
    // themeColor: '#28d456',
    themeColor: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      linkClicked: false, // Track whether a link inside the collapse has been clicked
      themeColor: props.themeColor,
    };
  }

  async componentDidMount() {
    const { db } = fetchFirebaseConfig();

    const q = query(collection(db, 'ThemeSettings'), where('companyID', '==', 'DSP'));
    this.unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const themeColor = doc.data().themeColor || '#28d456';
        this.setState({ themeColor });
      });
    });
  }

  componentWillUnmount() {
    // Clean up the listener on unmount
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, headerLink } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      if (!location.pathname.startsWith(headerLink) && this.state.isOpen && !this.state.linkClicked) {
        // If the current location path changes to a completely separate link
        // and the collapse is open and no link inside the collapse has been clicked
        // then close the collapse
        this.setState({ isOpen: false });
      }
      // Reset linkClicked state for next update
      this.setState({ linkClicked: false });
    }
  }

  handleLinkClick = () => {
    // Set linkClicked state to true when a link inside the collapse is clicked
    this.setState({ linkClicked: true });
  };

  render() {
    const { className, childrenLinks, headerLink, header, icon } = this.props;
    const { isOpen, themeColor } = this.state;
    if (!childrenLinks) {
      return (
        <li className={cx(s.headerLink, className)} style={{ '--theme-color': themeColor }}>
          <NavLink
            to={headerLink}
            activeClassName={s.headerLinkActive}
            exact
          >
            <div>
              {icon && (
                <div className={s.iconCard}>
                  {React.cloneElement(icon, { className: cx(icon.props.className, s.linkIcon) })}
                </div>
              )}
              <span>{header}</span>
            </div>
          </NavLink>
        </li>
      );
    }

    return (
      <Route
        path={headerLink}
        children={({ match }) => {
          return (
            <li className={cx(s.headerLink, className)} style={{ '--theme-color': themeColor }}>
              <a
                className={cx({ [s.headerLinkActive]: !!match && match.url.indexOf(headerLink) !== -1 })}
                onClick={() => this.setState({ isOpen: !isOpen })}
              >
                <div>
                  {icon && (
                    <div className={s.iconCard}>
                      {React.cloneElement(icon, { className: cx(icon.props.className, s.linkIcon) })}
                    </div>
                  )}
                  <span>{header}</span>
                </div>
                <b className={cx('fa', { 'fa-chevron-right': !isOpen, 'fa-chevron-down': isOpen }, s.arrow, { [s.arrowActive]: isOpen })} />
              </a>
              <Collapse className={s.panel} isOpen={isOpen}>
                <ul>
                  {childrenLinks &&
                    childrenLinks.map(child => (
                      <li key={child.name}>
                        <NavLink
                          to={child.link}
                          className={({ isActive }) =>
                            cx({ [s.headerLinkActive]: isActive })
                          }
                          exact
                          onClick={this.handleLinkClick}
                        >
                          <i className={cx('fa', 'fa-dot-circle-o', s.dotIcon)}></i>
                          <span>{child.name}</span>
                        </NavLink>
                      </li>
                    ))}
                </ul>
              </Collapse>
            </li>
          );
        }}
      />
    );
  }
}

// Wrap the component with withRouter to access the location prop
export default withRouter(LinksGroup);




