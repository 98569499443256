import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc, updateDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print'; 
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import LoanSummaryDetails from './loanDetails';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { faFileExport, faPrint } from '@fortawesome/free-solid-svg-icons';
import { faClipboard, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import ReusableTable from '../../components/CustomTable/table';

const CreatedLoanRequests = ({ loanId, refreshTable, loanType }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modal, setModal] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [isVisible3i, setIsVisible3i] = useState(false);
  const [isVisible3j, setIsVisible3j] = useState(false);
  const [isVisible3k, setIsVisible3k] = useState(false); 
  
  const history = useHistory();

  const componentRef = useRef(null);

  const toggle = () => setModal(!modal);

  const toggleLoanDetailsModal = (id) => {
    setSelectedLoanId(id); // Set the selected loan id
    setModal(!modal); // Toggle the modal
  };

  const handleDetailsClick = (id) => {
    toggleLoanDetailsModal(id);
  };

  const fetchVisibility = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);
      
      // Use onSnapshot to listen for changes to user permissions
      const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPosition = userData.position;
          const permissionsCollection = collection(db, 'permissions');
          const q = query(permissionsCollection, where('Role', '==', userPosition));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const permissionDocSnap = querySnapshot.docs[0];
            const permissionData = permissionDocSnap.data();
            const accessArray = permissionData.acess || [];
            setIsVisible3i(accessArray.includes(3.9));
            setIsVisible3j(accessArray.includes(3.13));
            setIsVisible3k(accessArray.includes(3.11));
          } else {
            setIsVisible3i(false);
            setIsVisible3j(false);
            setIsVisible3k(false);
          }
        }
      });
      
      return () => {
        // Unsubscribe from onSnapshot when component unmounts
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  fetchVisibility();

 

  const fetchLoanDataAndSetState = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
  
      // Create a reference to the Loans collection
      const loansCollectionRef = collection(db, 'Loans');
  
      // Query loans where loanSchedual is "Long Term Loan"
      const querySnapshot = await getDocs(query(loansCollectionRef, where('loanSchedual', '==', loanType)));
  
      const newLoansData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const updatedData = await Promise.all(
        newLoansData.map(async (item) => {
          if (item.user) {
            const userDoc = await getDoc(item.user);
            if (userDoc.exists()) {
              const userData = userDoc.data();
              if (userData && userData.display_name && userData.mid_name && userData.sir_name) {
                return {
                  ...item,
                  id: item.id,
                  firstName: userData.display_name,
                  middleName: userData.mid_name,
                  sirName: userData.sir_name,
                };
              }
            }
          }
          return null; // Return null for data that doesn't meet the criteria
        })
      );
  
      // Filter out null values
      const filteredData = updatedData.filter(item => item !== null);
  
      // Sort the loans data based on the dateTaken field in descending order
      const sortedNewLoansData = filteredData.sort((a, b) => b.dateTaken.seconds - a.dateTaken.seconds);
  
      // Filter out loans with loanAmount equal to zero
      const finalFilteredNewLoansData = sortedNewLoansData.filter((loan) => loan.loanAmount !== 0 && loan.loanSchedual === loanType);
  
      setData(finalFilteredNewLoansData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  
  useEffect(() => {
    fetchLoanDataAndSetState();
  }, []);  



  const handleApproveClick = async (id) => {
    try {
      // Fetch Firebase configuration  
      const { db } = fetchFirebaseConfig(); 
      
      // Display the confirmation modal
      Swal.fire({
        icon: 'warning',
        title: 'Confirm this action',
        text: 'Are you sure you want to approve this loan?',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then(async (result) => {
        // Check if the confirm button is clicked
        if (result.isConfirmed) {
          const loanDocRef = doc(db, 'Loans', id);
          
          // Fetch the existing loan data
          const loanDocSnapshot = await getDoc(loanDocRef);
          if (loanDocSnapshot.exists()) {
            const loanData = loanDocSnapshot.data();
            
            // Calculate due date based on loanDuration and current time
            const loanDurationInWeeks = parseInt(loanData.repaymentMonths, 10);
            const currentTime = new Date();
            const dueDate = new Date(currentTime.getTime() + loanDurationInWeeks * 30 * 24 * 60 * 60 * 1000);
            
            // Update the Firestore document with 'loanState', 'dueDate', 'dateApproved', and 'amountRemaining'
            await updateDoc(loanDocRef, {
              loanState: 'Approved',
              payOutDate: dueDate,
              dateApproved: serverTimestamp(),
              amountRemaining: loanData.expectedTotalPayment,
            });
  
            history.push(`/app/accounting/accounting-transfer/${id}`);
          }
          
          // Display the success alert
          Swal.fire({
            icon: 'success',
            title: 'Loan Approved',
            text: 'The Loan has been successfully approved!',
            confirmButtonColor: '#28a745',
          });
  
          fetchLoanDataAndSetState();
        }
      });
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };
  

  const handleDenyClick = async (id) => {
    try {
      // Find the loan with the specified ID in the local state
      const { db } = fetchFirebaseConfig(); // Fetch Firebase configuration
  
      // Display the confirmation modal
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Confirm this action',
        text: 'Are you sure you want to decline this loan?',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });
  
      // Check if the confirm button is clicked
      if (result.isConfirmed) {
  
        const loanDocRef = doc(db, 'Loans', id);
  
        // Fetch the existing loan data
        const loanDocSnapshot = await getDoc(loanDocRef);
        if (loanDocSnapshot.exists()) {
          await updateDoc(loanDocRef, {
            loanState: 'Denied',
            dateDenied: serverTimestamp(),
          });
        }
        // Display the success alert
        await Swal.fire({
          icon: 'success',
          title: 'Loan Declined',
          text: 'The Loan has been successfully declined!',
          confirmButtonColor: '#28a745',
        });
        // Fetch and update loan data after declining
        fetchLoanDataAndSetState();
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };
   

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = (number) => {
    return number?.toLocaleString();
  };

  const renderLoanState = (loanState) => {
    return loanState === "Approved" ? (
      <Badge color="success" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Approved</span>
      </Badge>
    ) : loanState === "pending" ? (
      <Badge color="info" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Pending</span>
      </Badge>
    ) : (
      <Badge color="danger" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Denied</span>
      </Badge>
    );
  };

   
  const visibleDetails = [isVisible3i].some(Boolean);

  const visibleActions = [isVisible3j, isVisible3k].some(Boolean);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const firstName = record.firstName ? capitalizeFirstLetter(record.firstName) : '';
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sirName ? capitalizeFirstLetter(record.sirName) : '';
        const fullName = [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');  
        return (
          <span>{fullName}</span>
        );
      },
    },
    {
      title: 'Loan Request',
      dataIndex: 'loanAmount1',
      key: 'loanAmount1',
      align: 'center',
      sorter: (a, b) => a.loanAmount1 - b.loanAmount1,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Loan Term',
      dataIndex: 'loanSchedual',
      key: 'loanSchedual',
      align: 'center',
      sorter: (a, b) => a.loanSchedual - b.loanSchedual,
    },
    {
      title: 'Outstanding Balance',
      dataIndex: 'amountTobeReturned',
      key: 'amountTobeReturned',
      align: 'center',
      sorter: (a, b) => a.amountTobeReturned - b.amountTobeReturned,
      render: (text) => formatNumber(text),
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      align: 'center',
      sorter: (a, b) => a.amountPaid - b.amountPaid,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Remaining Balance',
      dataIndex: 'amountRemaining',
      key: 'amountRemaining',
      align: 'center',
      sorter: (a, b) => a.amountRemaining - b.amountRemaining,
      render: (text) => formatNumber(text),
    },
    {
      title: 'Requested On',
      dataIndex: 'dateTaken',
      key: 'dateTaken',
      align: 'center',
      sorter: (a, b) => a.dateTaken - b.dateTaken,
      render: renderDateRequested,
      render: (text) => new Date(text.seconds * 1000).toLocaleDateString(),
    },
    // {
    //   title: 'Payment Due On',
    //   dataIndex: 'payOutDate',
    //   key: 'payOutDate',
    //   align: 'center',
    //   sorter: (a, b) => a.payOutDate - b.payOutDate,
    //   render: renderDateRequested,
    //   ...getColumnSearchProps('payOutDate'),
    // },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      align: 'center',
      render: (_, record) => {
       return (
        <Space>
        {isVisible3i && (
          <ButtonIcon icon={faClipboard} onClick={() => handleDetailsClick(record.id)}/>
        )}
        </Space>
        );
      },
      ...(visibleDetails ? {} : { hidden: true }),
    },
    {
      title: 'Approval Status',
      dataIndex: 'loanState',
      key: 'loanState',
      align: 'center',
      render:renderLoanState,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
        <Space>
          {isVisible3j && (
          <TextButton label='APPROVE' size='sm' onClick={() => handleApproveClick(record.id)} disabled={record.loanState === 'Approved' || record.loanState === 'denied'}/>
           )}
          {isVisible3j && (
          <TextButton label='DECLINE' size='sm' onClick={() => handleDenyClick(record.id)} disabled={record.loanState === 'Approved' || record.loanState === 'denied'}/>
          )}
        </Space>
        );
      },
      ...(visibleActions ? {} : { hidden: true }),
    },
  ];


  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Loan Request', key: 'loanAmount1', width: 20 },
      { header: 'Loan Term', key: 'loanSchedual', width: 15 },
      { header: 'OutStanding Balance', key: 'amountTobeReturned', width: 30 },
      { header: 'Amount Paid', key: 'amountPaid', width: 20 },
      { header: 'Remaining Balance', key: 'amountRemaining', width: 30 },
      { header: 'Requested On', key: 'dateTaken', width: 15 },
      { header: 'Approval Status', key: 'loanState', width: 15 },
    ];
  
    const formatters = {
      id: (_, __, index) => index + 1,
      dateTaken: renderDateRequested,
    };
  
    await generateExcelFile({
      sheetName: `${loanType} Requests`,
      title: `${loanType} Requests`,
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: `${loanType}Requests.xlsx`,
      numericalColumns: ['loanAmount1','amountTobeReturned','amountPaid','amountRemaining'],
      useFullName2: true, // This will use the full name formatter
      useLatestBalance: true, // This will use the latest balance formatter
      customStyles: {
        headerFill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF5F7FA' }
        }
      }
    });
  };


  const generatePDF = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 15 },
      { header: 'Loan Request', key: 'loanAmount1', width: 10 },
      { header: 'Loan Term', key: 'loanSchedual', width: 12 },
      { header: 'OutStanding Balance', key: 'amountTobeReturned', width: 10 },
      { header: 'Amount Paid', key: 'amountPaid', width: 10 },
      { header: 'Remaining Balance', key: 'amountRemaining', width: 10 },
      { header: 'Requested On', key: 'dateTaken', width: 10 },
      { header: 'Approval Status', key: 'loanState', width: 10 },
    ];
      
    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };
  
    await generatePDFFile({
      title: `${loanType} Requests`,
      columns: columns,
      data: data,
      formatters: formatters,
      numericalColumns: ['loanAmount1','amountTobeReturned','amountPaid','amountRemaining'],
      fileName: `${loanType}Requests.pdf`,
      useFullName2: true,
      useLatestBalance: true,
    });
  };


  return (
    <div>
      <h5 className="mb-lg">{`${loanType} Requests`}</h5>
      <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="dateTaken"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
      <Modal isOpen={modal} toggle={toggle}>
        <Widget>
        {selectedLoanId && <LoanSummaryDetails id={selectedLoanId} />}
        </Widget>
        <ModalFooter>
          <Click color="danger" onClick={toggle} size='sm'>
            Cancel
          </Click>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreatedLoanRequests;