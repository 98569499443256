// import React, { useState,useEffect,useRef } from 'react';
// import { connect } from 'react-redux';
// import cx from 'classnames';
// import {
//   Navbar,
//   Nav,
//   NavItem,
//   Button,
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
//   UncontrolledDropdown
// } from 'reactstrap';
// import { NavLink } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
// import { Badge} from 'antd';
// import {fetchFirebaseConfig} from '../../firebase';
// import s from './Header.module.scss';
// import { UserAuth } from '../../context/AuthContext';
// import 'firebase/compat/auth';
// import firebase from 'firebase/compat/app';
// import { collection,doc,getDoc,query,where,onSnapshot,getDocs } from 'firebase/firestore';
// import { Avatar, Divider, List, Skeleton } from 'antd';
// import Notificationspop from '../../pages/notifications/notificationspopup';
// import 'firebase/compat/auth';

// const Header = ({ sidebarToggle }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [userData, setUserData] = useState(null);
//   const [notificationsOpen, setNotificationsOpen] = useState(false);
//   const [notificationCount, setNotificationCount] = useState(0);
//   const [isVisible11a, setIsVisible11a] = useState(false); // <-- Add isVisible11a state

//   const {logOut} = UserAuth();

//   const history=useHistory()

//   const prevNotificationCount = useRef(0);

//   const user = firebase.auth().currentUser;

//   const currentAuth = () =>{
//     if (user) {
//       const { db } = fetchFirebaseConfig();
//       const usersCollection = collection(db, 'users');
//       const userDocRef = doc(usersCollection, user.uid);

//       getDoc(userDocRef)
//         .then((docSnapshot) => {
//           if (docSnapshot.exists()) {
//             const userData = docSnapshot.data();
//             setUserData(userData); // <-- Set userData state
//           } else {
//             console.log('No such document!');
//           }
//         })
//         .catch((error) => {
//           console.log('Error getting document:', error);
//         });
//     }
//   }

//   useEffect(() => {  
//    currentAuth();
//   }, []);

//   currentAuth();

//   const handleSignOut = async () => {
//     try {
//       await logOut();
//       console.log('You are logged out'); // Log a message indicating successful logout
//       history.push('/login');
//     } catch (error) {
//       console.error('Error occurred while signing out:', error.message); // Log any errors that occur during sign-out
//     }
//   };

//   const toggleDropdown = () => {
//     setIsOpen(prevIsOpen => !prevIsOpen);
//   };

//   const toggleNotifications = () => {
//     setNotificationsOpen(prevNotificationsOpen => !prevNotificationsOpen);
//   };

//   const showAllNotf = () => {
//     history.push('/app/notifications')
//     setNotificationsOpen(false)
//   }

//   const handleNotificationCountChange = (count) => {
//     setNotificationCount(count);
//   };

//   useEffect(() => {
//     // Check if the new notification count is greater than the previous count
//     if (notificationCount > 1 && notificationCount > prevNotificationCount.current) {
//       setNotificationsOpen(true);
//     } 
//     // Store the current notification count for the next comparison
//     prevNotificationCount.current = notificationCount;
  
//     // Close notifications dropdown after 10 seconds only if notificationCount is greater than 1
//     if (notificationCount > 1) {
//       const timer = setTimeout(() => {
//         setNotificationsOpen(false);
//       }, 10000); // 10 seconds in milliseconds
  
//       // Clear the timeout if component unmounts or the notification count changes
//       return () => {
//         clearTimeout(timer);
//       };
//     }
//   }, [notificationCount]);
  

//   return (
//     <Navbar className={s.root}>
//       <Nav>
//         <NavItem
//           className={cx('visible-xs mr-4 d-sm-up-none', s.headerIcon, s.sidebarToggler)}
//           href="#"
//           onClick={sidebarToggle}
//         >
//           <i className="fa fa-bars fa-2x text-muted" />
//         </NavItem>
//       </Nav>
//       <Nav className="ml-auto">
//        <NavItem className={cx('', s.headerIcon)}>
//         <Dropdown isOpen={notificationsOpen}  toggle={toggleNotifications}>
//         <DropdownToggle nav >
//            <NavItem className={cx('', s.headerIcon)}>
//            <Badge count={notificationCount}>
//            <i class="fa fa-bell text-muted" aria-hidden="true" style={{fontSize:'1.6rem'}}></i>
//            </Badge>
//            </NavItem>
//           </DropdownToggle>
//           <DropdownMenu> 
//             <div
//                 style={{
//                   width:350,
//                   // overflow: 'auto',
//                   padding: '0 10px',
//                 }}
//               >
//                 {/* <Notificationspop onNotificationCountChange={handleNotificationCountChange} /> */}
//               </div>
//           </DropdownMenu>
//         </Dropdown>
//         </NavItem>
//         <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
//         <DropdownToggle nav>
//             {userData?.photo_url ? (
//               <img className={cx('rounded-circle mr-sm', s.adminPhoto)} src={userData.photo_url} alt="administrator" />
//             ) : (
//               <i className={cx("fa fa-user-circle", s.adminPhoto)} style={{ fontSize: '2em', color: '#c0c0c0' }}></i>
//             )}
//              <span className="text-body">
//                   Hi,{' '}
//                   <span style={{ fontWeight: 600, fontSize:'14px' }}>
//                     {userData?.display_name ? userData.display_name.charAt(0).toUpperCase() + userData.display_name.slice(1) : ''}&nbsp;
//                     {userData?.mid_name ? userData.mid_name.charAt(0).toUpperCase() : ''}&nbsp;
//                     {userData?.sir_name ? userData.sir_name.charAt(0).toUpperCase() + userData.sir_name.slice(1) : ''}&nbsp;!
//                   </span>
//                 </span>
//             <i className={cx('fa fa-angle-down ml-sm', s.arrow, {[s.arrowActive]: isOpen})} />
//           </DropdownToggle>
//           <DropdownMenu style={{width: '110%'}}>
//             <DropdownItem>
//               <NavLink to={`/app/members/members-list/edit-user/${userData?.uid}`}><i class="fa fa-user text-muted" aria-hidden="true"></i>&nbsp;&nbsp;Update Profile</NavLink>
//             </DropdownItem>
//             <DropdownItem>
//               <NavLink to={`/app/members/members-list/reset-password/${userData?.uid}`}><i class="fa fa-refresh text-muted" aria-hidden="true"></i>&nbsp;Change Password</NavLink>
//             </DropdownItem>
//             <DropdownItem onClick={handleSignOut}>
//             <i class="fa fa-sign-out text-muted" aria-hidden="true"></i>&nbsp;&nbsp;Logout
//             </DropdownItem>
//           </DropdownMenu>
//         </Dropdown>
//       </Nav>
//     </Navbar>
//   );
// };

// function mapStateToProps(state) {
//   return {
//     init: state.runtime.initialNow,
//   };
// }

// export default connect(mapStateToProps)(Header);







import React, { useState,useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  Navbar,
  Nav,
  NavItem,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Badge} from 'antd';
import {fetchFirebaseConfig} from '../../firebase';
import s from './Header.module.scss';
import { UserAuth } from '../../context/AuthContext';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { collection,doc,getDoc,query,where,onSnapshot,getDocs } from 'firebase/firestore';
import { Avatar, Divider, List, Skeleton } from 'antd';
import Notificationspop from '../../pages/notifications/notificationspopup';
import 'firebase/compat/auth';
import { faLock, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faEnvelope, faMessage, faUser } from '@fortawesome/free-regular-svg-icons';

const Header = ({ sidebarToggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isVisible11a, setIsVisible11a] = useState(false); // <-- Add isVisible11a state
  const [color, setThemeColor] = useState('');

  const {logOut} = UserAuth();

  const history=useHistory()

  const prevNotificationCount = useRef(0);

  const user = firebase.auth().currentUser;


  useEffect(() => {
    const { db } = fetchFirebaseConfig();

    const q = query(collection(db, 'ThemeSettings'), where('companyID', '==', 'DSP'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const themeColor = doc.data().themeColor || '';
        setThemeColor(themeColor);
      });
    });

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, []); // Empty dependency array means this effect runs once on mount

  const currentAuth = () =>{
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);

      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setUserData(userData); // <-- Set userData state
          } else {
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    }
  }

  useEffect(() => {  
   currentAuth();
  }, []);

  currentAuth();


  const handleSignOut = async () => {
    try {
      await logOut();
      console.log('You are logged out'); // Log a message indicating successful logout
      history.push('/login');
    } catch (error) {
      console.error('Error occurred while signing out:', error.message); // Log any errors that occur during sign-out
    }
  };

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const toggleNotifications = () => {
    setNotificationsOpen(prevNotificationsOpen => !prevNotificationsOpen);
  };

  const showAllNotf = () => {
    history.push('/app/notifications')
    setNotificationsOpen(false)
  }

  const handleNotificationCountChange = (count) => {
    setNotificationCount(count);
  };

  // useEffect(() => {
  //   // Check if the new notification count is greater than the previous count
  //   if (notificationCount > 1 && notificationCount > prevNotificationCount.current) {
  //     setNotificationsOpen(true);
  //   } 
  //   // Store the current notification count for the next comparison
  //   prevNotificationCount.current = notificationCount;
  
  //   // Close notifications dropdown after 10 seconds only if notificationCount is greater than 1
  //   if (notificationCount > 1) {
  //     const timer = setTimeout(() => {
  //       setNotificationsOpen(false);
  //     }, 10000); // 10 seconds in milliseconds
  
  //     // Clear the timeout if component unmounts or the notification count changes
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }
  // }, [notificationCount]);
  

  return (
    <Navbar className={s.root}>
      <Nav>
        <NavItem
          className={cx('visible-xs mr-4 d-sm-up-none', s.headerIcon, s.sidebarToggler)}
          href="#"
          onClick={sidebarToggle}
        >
          <i className="fa fa-bars fa-2x text-muted" />
        </NavItem>
      </Nav>
      <Nav className="ml-auto">
       <NavItem className={cx('', s.headerIcon)}>
        <Dropdown isOpen={notificationsOpen}  toggle={toggleNotifications}>
        <DropdownToggle nav >
           <NavItem className={cx('', s.headerIcon)}>
           <Badge count={notificationCount}>
           <Card className={s.messageCard}>
           <FontAwesomeIcon icon={faCommentDots} style={{fontSize:'1.6em',color: color}}/>
           </Card>
           </Badge>
           </NavItem>
          </DropdownToggle>
          <DropdownMenu> 
            <div
                style={{
                  width:350,
                  padding: '0 10px',
                }}
              >
                {/* <Notificationspop onNotificationCountChange={handleNotificationCountChange} /> */}
              </div>
          </DropdownMenu>
        </Dropdown>
        </NavItem>
        <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
        <DropdownToggle nav>
            <Card className={s.userCard}>  {/* Add a new class for styling */}
              <div className={s.userCardContent}>  {/* Wrapper for flex layout */}
                {userData?.photo_url ? (
                  <img className={cx( s.adminPhoto, s.iconCard)} src={userData.photo_url} alt="administrator" />
                ) : (
                  // <FontAwesomeIcon icon={faUser} className={s.userIcon} />
                  <img className={cx( s.adminPhoto, s.iconCard)} src='user.png' alt="administrator" />
                )}
                <div className={s.userInfo}>
                  <span style={{color: color}}>
                    Hi,{' '}
                    <span style={{ fontWeight: 600, fontSize:'14px' }}>
                      {userData?.display_name ? userData.display_name.charAt(0).toUpperCase() + userData.display_name.slice(1) : ''}&nbsp;
                      {userData?.mid_name ? userData.mid_name.charAt(0).toUpperCase() : ''}&nbsp;
                      {userData?.sir_name ? userData.sir_name.charAt(0).toUpperCase() + userData.sir_name.slice(1) : ''}&nbsp;!
                    </span>
                  </span>
                </div>
                <i className={cx('fa fa-angle-down ml-sm', s.arrow, {[s.arrowActive]: isOpen})} />
              </div>
            </Card>
          </DropdownToggle>
          <DropdownMenu style={{width: '100%',boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.05)', border:'none', borderRadius:'10px'}}>
            <DropdownItem>
              <NavLink to={`/app/members/members-list/edit-user/${userData?.uid}`}><FontAwesomeIcon icon={faUser}/>&nbsp;&nbsp;Profile</NavLink>
            </DropdownItem>
            <DropdownItem>
              <NavLink to={`/app/members/members-list/reset-password/${userData?.uid}`}><FontAwesomeIcon icon={faLock}/>&nbsp;Change Password</NavLink>
            </DropdownItem>
            <DropdownItem onClick={handleSignOut}>
            <FontAwesomeIcon icon={faSignOut}/>&nbsp;&nbsp;Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
};

function mapStateToProps(state) {
  return {
    init: state.runtime.initialNow,
  };
}

export default connect(mapStateToProps)(Header);







