import React from 'react';
import { 
  FormGroup, 
  Input, 
  Row,
  Col,
  Form,
  Button,
  Label
} from 'reactstrap';
import s from './SignUp.module.scss';
import Widget from '../../components/Widget';
import Footer from "../../components/Footer";
import { UserAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);

  const { signIn } = UserAuth();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className={s.root}>
      <Row>
        <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 3 }} lg={{ size: 4, offset: 4 }}>
         <h3 className="text-center" style={{fontWeight:700}}>Register</h3>
          <Widget className={s.widget}>
            <p className="fs-sm text-muted">
              Create your new account profile<br />
              Already have an account? <a href='#/login'>Log in</a>
            </p>
            <Form className="mt">
            <FormGroup className="form-group">
            <Label for="exampleUploadImage">Account name</Label>
                <Input
                  className="no-border"
                  type="text"
                  required
                  name="name"
                  placeholder="Company name"
                />
              </FormGroup>
              <FormGroup className="form-group">
              <Label for="exampleUploadImage">Account email</Label>
                <Input
                  className="no-border"
                  type="text"
                  required
                  name="email"
                  placeholder="company email"
                />
              </FormGroup>
              <FormGroup className="form-group">
              <Label for="exampleUploadImage">Account code</Label>
                <Input
                  className="no-border"
                  type="text"
                  required
                  name="email"
                  placeholder="company code"
                />
              </FormGroup>
              <FormGroup>
              <Label for="exampleUploadImage">Account Password</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    className="no-border"
                    type={showPassword ? 'text' : 'password'}
                    required
                    name="password"
                    placeholder="Account Password"
                  />
                  <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '35px', cursor: 'pointer', color: '#c0c0c0' }} onClick={togglePasswordVisibility}></i>
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="exampleUploadImage">Comfirm password</Label>
                <div style={{ position: 'relative' }}>
                  <Input
                    className="no-border"
                    type={showPassword ? 'text' : 'password'}
                    required
                    name="Comfirmpassword"
                    placeholder="Confirm Password"
                  />
                  <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '35px', cursor: 'pointer', color: '#c0c0c0' }} onClick={togglePasswordVisibility}></i>
                </div>
              </FormGroup>
              <FormGroup>
                      <Label for="exampleUploadImage">Upload Logo</Label>
                      <Input
                        id="exampleUploadImage"
                        bsSize="sm"
                        type="file"
                        accept="image/*"
                      />
             </FormGroup>
              <div className="d-flex justify-content-between align-items-center">
                  <Button color="success" size="sm" type="submit">
                    Register
                  </Button>
              </div>
            </Form>
          </Widget>
        </Col>
      </Row>
      {/* <Footer className="text-center" /> */}
      <ToastContainer position="top-center" autoClose={4000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{ fontSize:'13.5px'}}/>
    </div>
  );
}

export default SignUp

