import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import Widget from '../../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../../firebase';
import { collection, doc, updateDoc, query, where, getDocs } from 'firebase/firestore'; // Import Firestore functions
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from 'antd';

function UploadSettings() {
  const { db } = fetchFirebaseConfig();

  const [loanAmount, setLoanAmount] = useState('');
  const [amountPending, setAmountPending] = useState('');
  const [amountReturned, setAmountReturned] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [loanType, setLoanType] = useState('');
  const [recepient, setRecepient] = useState('');
  const [dateTaken, setDateTaken] = useState('');

  // Fetch and populate data from Firestore on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const querySnapshot = await getDocs(query(collection(db, 'UploadSettings'), where('company_id', '==', 'NCAAWS')));
        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data();
          setLoanAmount(docData.loanAmount);
          setAmountPending(docData.amountRemaining);
          setAmountReturned(docData.amountTobeReturned);
          setAmountPaid(docData.amountPaid);
          setLoanType(docData.loanSchedual);
          setRecepient(docData.userName);
          setDateTaken(docData.dateTaken);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettings();
  }, [db]);

  const handleSave = async () => {
    // Find the document to update based on company_id
    const querySnapshot = await getDocs(query(collection(db, 'UploadSettings'), where('company_id', '==', 'NCAAWS')));
    querySnapshot.forEach(async (doc) => {
      try {
        // Update the database fields for each document found
        await updateDoc(doc.ref, {
          loanAmount1: loanAmount,
          loanAmount: loanAmount,
          amountRemaining: amountPending,
          amountTobeReturned: amountReturned,
          amountPaid: amountPaid,
          loanSchedual: loanType,
          userName: recepient,
          dateTaken: dateTaken,
        });
        toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp;Settings saved successfully</div>);
      } catch (error) {
        console.error('Error updating settings: ', error);
        toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp;Failed to save settings</div>);
      }
    });
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
        <BreadcrumbItem active>Settings</BreadcrumbItem>
      </Breadcrumb>
      <h4 className="mb-lg">Excel Template Upload Settings</h4>
      <Row>
        <Col xs={12}>
          <Widget>
          <div style={{ marginBottom: '10px' }}>
            <Alert
              message="Note"
              description={
                <ul>
                  <li>This form contains inputs where you place the column names of your excel template</li>
                  <li>The excel columns names that you will place in this form will be used to fetch the required data in the database.</li>
                  <li>Make sure you understand the form input requirements before you place the name of your excel columns</li>
                  <li>Example the input "loan Amount" means place the excel column names which holds the data that involves Loans provided to members.</li>
                  <li>To save the settings, click the "save" button below the page.</li>
                </ul>
              }
              type="info"
              showIcon
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Alert
              message={<span><i class="fa fa-key" aria-hidden="true"></i> Key</span>}
              description={
                <ul>
                  <li><b>Loan Amount:&nbsp;</b> Loan Amount given to the user</li>
                  <li><b>Amount Pending:&nbsp;</b> Amount left to complete loan payment </li>
                  <li><b>Amount To Be Returned:&nbsp;</b> Amount Required to pay</li>
                  <li><b>Loan Type:&nbsp;</b>(Long-Term, short-Term, ....etc)</li>
                  <li><b>Loan Receipts:&nbsp;</b>user name</li>
                  <li><b>Date Taken:&nbsp;</b>Day the Loan was requested</li>
                </ul>
              }
              type="success"
            />
          </div>
            <Form>
              <FormGroup>
                <Label for="LoanAmount">Loan Amount</Label>
                <Input id="LoanAmount" name="LoanAmount" placeholder="Loan Amount" type="text" value={loanAmount} onChange={(e) => setLoanAmount(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label for="AmountPending">Amount Pending</Label>
                <Input
                  id="AmountPending"
                  name="AmountPending"
                  placeholder="Amount Pending"
                  type="text"
                  value={amountPending}
                  onChange={(e) => setAmountPending(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="AmountReturned">Amount To Be Returned</Label>
                <Input
                  id="AmountReturned"
                  name="AmountReturned"
                  placeholder="Amount To Be Returned"
                  type="text"
                  value={amountReturned}
                  onChange={(e) => setAmountReturned(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="AmountReturned">Paid Amount</Label>
                <Input
                  id="AmountPaid"
                  name="AmountPaid"
                  placeholder="Amount Paid"
                  type="text"
                  value={amountPaid}
                  onChange={(e) => setAmountPaid(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="loanType">Loan Type</Label>
                <Input id="loanType" name="loanType" placeholder="Loan Type" type="text" value={loanType} onChange={(e) => setLoanType(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label for="recepient">Loan recipient</Label>
                <Input id="recepient" name="recepient" placeholder="Recipient" type="text" value={recepient} onChange={(e) => setRecepient(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label for="DateTaken">Date Taken</Label>
                <Input id="DateTaken" name="DateTaken" placeholder="Date Taken" type="text" value={dateTaken} onChange={(e) => setDateTaken(e.target.value)} />
              </FormGroup>
              <Button size="sm" color="success" onClick={handleSave}>
                Save
              </Button>
            </Form>
            <ToastContainer position="top-right" autoClose={4000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{ fontSize:'13.5px'}}/>
          </Widget>
        </Col>
      </Row>
    </div>
  );
}

export default UploadSettings;
