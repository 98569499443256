import React, { useRef, useState, useEffect } from 'react';
import { Table } from 'antd';
import {
  Spinner,
  Badge,
  FormGroup,
  Label,
  Row,
  Col,
  Input as InputData,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button as Click,
  ButtonGroup,
} from 'reactstrap';
import Widget from '../../../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../../../firebase';
import {
  getDoc,
  doc,
} from 'firebase/firestore';
import ReactToPrint from 'react-to-print';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import WidgetShadow from '../../../../components/WidgetShadow/WidgetShadow';
import { ButtonIcon, TextButton } from '../../../../components/Buttons/CustomButtons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const UserActualLoanArmotization = () => {
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [annualInterestRate, setAnnualInterestRate] = useState(' '); // Default value
  const [loanTermMonths, setLoanTermMonths] = useState(' '); // Default value
  const [loanPrinciple, setLoanPrinciple] = useState(''); // Default value
  const [repaymentMonths, setRepaymentMonths] = useState(' ');

  const { id } = useParams();

  const componentRef = useRef(null);

  const { db } = fetchFirebaseConfig();

  const calculateActualLoanDetails = (
    loanAmount,
    annualInterestRate,
    repaymentMonths,
    payments
  ) => {
    const monthlyInterestRate = annualInterestRate / 12 / 100;
    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -repaymentMonths));

    let remainingBalance = loanAmount;
    const loanData = [];
    const currentDate = new Date();

    for (let month = 1; month <= repaymentMonths; month++) {
      const paymentDate = new Date(currentDate);
      paymentDate.setMonth(currentDate.getMonth() + month - 1);

      const interestPortion = remainingBalance * monthlyInterestRate;
      const payment =
        payments[month - 1] !== undefined
          ? payments[month - 1]
          : monthlyPayment;
      const principalPortion = payment - interestPortion;
      const endingBalance = remainingBalance - principalPortion;

      loanData.push({
        key: month,
        paymentDate: paymentDate.toLocaleDateString(),
        beginningBalance: remainingBalance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        payment: payment.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        principal: principalPortion.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        interest: interestPortion.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        endingBalance: endingBalance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      });

      remainingBalance = endingBalance;
    }

    while (remainingBalance > 0) {
      const interestPortion = remainingBalance * monthlyInterestRate;
      const principalPortion = remainingBalance;
      const payment = interestPortion + principalPortion;
      const endingBalance = remainingBalance - principalPortion;

      const paymentDate = new Date(currentDate);
      paymentDate.setMonth(currentDate.getMonth() + repaymentMonths);

      loanData.push({
        key: repaymentMonths + loanData.length + 1,
        paymentDate: paymentDate.toLocaleDateString(),
        beginningBalance: remainingBalance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        payment: payment.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        principal: principalPortion.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        interest: interestPortion.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        endingBalance: endingBalance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      });

      remainingBalance = endingBalance;
    }

    setData(loanData);
  };

  useEffect(() => {
    const payments = []; // Example payments array
    if (loanPrinciple && annualInterestRate && repaymentMonths) {
      calculateActualLoanDetails(
        loanPrinciple,
        annualInterestRate,
        repaymentMonths,
        payments
      );
    }
  }, [loanPrinciple, annualInterestRate, repaymentMonths]);

  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        if (!id) {
          console.error('UID is undefined');
          return;
        }
        const userDocRef = doc(db, 'Loans', id);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setLoanPrinciple(userData.expectedTotalPayment || userData.loanAmount1);
          setAnnualInterestRate(userData.interestRate || 0);
          setRepaymentMonths(userData.repaymentMonths || 0);
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchLoanData();
  }, [db, id]);

  const columns = [
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
    },
    {
      title: 'Beginning Balance',
      dataIndex: 'beginningBalance',
      key: 'beginningBalance',
      align: 'center',
    },
    {
      title: 'Payment',
      dataIndex: 'payment',
      key: 'payment',
      align: 'center',
    },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
      align: 'center',
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      align: 'center',
    },
    {
      title: 'Ending Balance',
      dataIndex: 'endingBalance',
      key: 'endingBalance',
      align: 'center',
    },
  ];

  return (
    <div>
      <Widget>
        <div
          className='mb-2'
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <ButtonGroup size='sm'>
            <TextButton label='PDF'  size='sm'/>
            <TextButton label='CSV'  size='sm'/>
            <ReactToPrint
              trigger={() => (
                <ButtonIcon icon={faPrint} size="sm" disabled={printing} borderRadius={false}/>
              )}
              content={() => componentRef.current} // Specify the component to be printed
              onBeforeGetContent={() => setPrinting(true)}
              onAfterPrint={() => setPrinting(false)}
            />
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
          <Table columns={columns} dataSource={data} pagination={false} size='small' />
        </div>
      </Widget>
    </div>
  );
};

export default UserActualLoanArmotization;
