import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { collection, query, where, getDocs, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from '../../../components/Buttons/CustomButtons';

const ShortLoanManagement = () => {
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [loanPeriod, setLoanPeriod] = useState('');
  const [loanLimitRate, setLoanLimitRate] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    const fetchLoanConfigDocument = async () => {
      try {
        const loansTypeSnapshot = await getDocs(
          query(collection(db, 'LoansType'), where('companyID', '==', 'DSP'))
        );

        if (!loansTypeSnapshot.empty) {
          const loanTypeDoc = loansTypeSnapshot.docs[0];
          const loanTypeCategorySnapshot = await getDocs(
            collection(db, `LoansType/${loanTypeDoc.id}/LoanTypeCategory`)
          );

          loanTypeCategorySnapshot.forEach(doc => {
            if (doc.data().Term === 'Development Fund') {
              const docData = doc.data();
              setMaxAmount(docData.maxAmount || '');
              setMinAmount(docData.minAmount || '');
              setLoanPeriod(docData.periodInMonths || '');
              setLoanLimitRate(docData.loanLimitRate || '');
              setInterestRate(docData.interestRate || '');
            }
          });
        } else {
          console.error('No document found for companyID equal to "DSP"');
        }
      } catch (error) {
        console.error('Error fetching loan settings:', error);
      }
    };

    fetchLoanConfigDocument();
  }, [db]);

  const handleSave = async () => {
    setIsSubmitting(true)
    try {
      const loansTypeSnapshot = await getDocs(
        query(collection(db, 'LoansType'), where('companyID', '==', 'DSP'))
      );

      if (!loansTypeSnapshot.empty) {
        const loanTypeDoc = loansTypeSnapshot.docs[0];
        const loanTypeCategorySnapshot = await getDocs(
          collection(db, `LoansType/${loanTypeDoc.id}/LoanTypeCategory`)
        );

        loanTypeCategorySnapshot.forEach(async (doc) => {
          if (doc.data().Term === 'Development Fund') {
            const docRef = doc.ref;
            await updateDoc(docRef, {
              maxAmount: Number(maxAmount),
              minAmount: Number(minAmount),
              periodInMonths: Number(loanPeriod),
              loanLimitRate: Number(loanLimitRate),
              interestRate: Number(interestRate),
              updatedAt: serverTimestamp(),
            });

            setIsSubmitting(false)

            toast.success(
                <div>
                  <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
                  &nbsp;&nbsp; Settings Updated
                </div>
              );
          }
        });
      }
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="maxAmount">Max Loan Amount:</Label>
                  <Input
                    type="number"
                    style={{ borderRadius: '7px' }}
                    id="maxAmount"
                    name="maxAmount"
                    placeholder="Max Loan Amount"
                    value={maxAmount}
                    onChange={(e) => setMaxAmount(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="minAmount">Min Loan Amount:</Label>
                  <Input
                    type="number"
                    style={{ borderRadius: '7px' }}
                    id="minAmount"
                    name="minAmount"
                    placeholder="Min Loan Amount"
                    value={minAmount}
                    onChange={(e) => setMinAmount(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="minLoanPeriod">Loan Period (Months):</Label>
                  <Input
                    type="number"
                    style={{ borderRadius: '7px' }}
                    id="loanPeriod"
                    name="loanPeriod"
                    placeholder="Loan Period"
                    value={loanPeriod}
                    onChange={(e) => setLoanPeriod(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="interestRate">Interest Rate (%):</Label>
                  <Input
                    type="number"
                    style={{ borderRadius: '7px' }}
                    id="interestRate"
                    name="interestRate"
                    placeholder="Interest Rate"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <TextButton label="Save" onClick={handleSave} loadingLabel='Saving' isBorderRadius={true} isLoading={isSubmitting} />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ShortLoanManagement;