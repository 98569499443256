import React, { useRef, useState, useEffect } from 'react';
import { Button, Input, Space, Table } from 'antd';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore'; // Removed unnecessary imports
import { Button as Click, ButtonGroup } from 'reactstrap';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon } from '../../components/Buttons/CustomButtons';

const TransactionsCategories = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]); // State to hold transaction data

  useEffect(() => {
    fetchSummaryTransactions();
  }, []); // Fetch data when component mounts

  const fetchSummaryTransactions = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      const querySnapshot = await getDocs(collection(db, 'allTransaction2'));
      const transactions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const groupedTransactions = groupTransactions(transactions);
      setData(groupedTransactions);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  const groupTransactions = transactions => {
    const grouped = {};
    transactions.forEach(transaction => {
      if (!grouped[transaction.transactionType]) {
        grouped[transaction.transactionType] = {
          transactionType: transaction.transactionType,
          totalAmount: transaction.transactionAmount,
        };
      } else {
        grouped[transaction.transactionType].totalAmount += transaction.transactionAmount;
      }
    });
    return Object.values(grouped);
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleRedo = () => {
    fetchSummaryTransactions();
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      sorter: (a, b) => a.transactionType.localeCompare(b.transactionType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'center',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortDirections: ['descend', 'ascend'],
      render: text => formatNumber(text),
    },
  ];

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Transactions Summary</Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Transactions & Deposits Collections Summary</h5>
      <WidgetShadow>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <ButtonIcon icon={faRefresh} tooltipTitle='refresh' onClick={handleRedo} />
          </ButtonGroup>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
        </div>
      </WidgetShadow>
    </div>
  );
};

export default TransactionsCategories;
