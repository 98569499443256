import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
import { getShortTermLoanAmount,getMediumTermLoanAmount,getLongTermLoanAmount, getCashOnHandBalance,getBankBalanceByKeywords,getMobileBalanceByKeywords,getTotalSavingsAmount, getSharesAmount } from './FinancialPositionData';
import numeral from 'numeral';


const FinancialPosition = () => {

  const [totalShortTermLoanAmount, setTotalShortTermLoanAmount] = useState("loading...");
  const [totalMediumTermLoanAmount, setTotalMediumTermLoanAmount] = useState("loading...");
  const [totalLongTermLoanAmount, setTotalLongTermLoanAmount] = useState("loading...");
  const [cashOnHand, setCashOnHand] = useState("loading...");
  const [CashOnOtherBanks, setCashOnOtherBanks] = useState("loading...");
  const [CashOnOtherNobile, setCashOnOtherMobile] = useState("loading...");
  const [totalSavings, setTotalSavings] = useState("loading...");
  const [totalShares, setTotalShares] = useState("loading...");

  useEffect(() => {
    const fetchData = async () => {
      try {

        // Fetch short-term loan amount
        const shortTermLoanAmount = await getShortTermLoanAmount();
        setTotalShortTermLoanAmount(shortTermLoanAmount); // Assuming you have a state for total short-term loan amount

        // Fetch medium-term loan amount
        const mediumTermLoanAmount = await getMediumTermLoanAmount();
        setTotalMediumTermLoanAmount(mediumTermLoanAmount); // Assuming you have a state for total medium-term loan amount

        // Fetch long-term loan amount
        const longTermLoanAmount = await getLongTermLoanAmount();
        setTotalLongTermLoanAmount(longTermLoanAmount); // Assuming you have a state for total long-term loan amount

        // Fetch cash on hand balance
        const cashOnHandBalance = await getCashOnHandBalance();
        setCashOnHand(cashOnHandBalance);

        const bankBalance = await getBankBalanceByKeywords();
        setCashOnOtherBanks(bankBalance);


        const mobileBalance = await getMobileBalanceByKeywords();
        setCashOnOtherMobile(mobileBalance);
        
      } catch (error) {
        console.error('Error fetching loan data:', error);
        // Handle error as needed
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch the total savings amount when the component mounts
    getTotalSavingsAmount()
      .then((formattedTotalSavingsAmount) => {
        setTotalSavings(formattedTotalSavingsAmount);
      })
      .catch((error) => {
        console.error('Error fetching total savings:', error);
      });
  }, []);


  useEffect(() => {
    // Fetch the total savings amount when the component mounts
    getSharesAmount()
      .then((formattedTotalSharesAmount) => {
        setTotalShares(formattedTotalSharesAmount);
      })
      .catch((error) => {
        console.error('Error fetching total shares:', error);
      });
  }, []);



   const  handleFinancialPositionReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: 'NCAA WORKERS SAVINGS AND CREDIT COOPERATIVE SOCIETY LIMITED',
      mspcode: 'xxx',
    };

      // Calculate NetLoan
    const NetLoan = numeral(totalShortTermLoanAmount).value() + numeral(totalMediumTermLoanAmount).value() + numeral(totalLongTermLoanAmount).value();
    const TotalNonEarning = numeral(CashOnOtherNobile).value() + numeral(CashOnOtherNobile).value() + numeral(CashOnOtherBanks).value() + numeral(cashOnHand).value();;

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','STATEMENT OF FINANCIAL POSITION FOR THE MONTH ENDED:']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 01: To be submitted Monthly for Category B and Quartely for Category A',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    // Add additional details
    const headerRow2e = worksheet.addRow(['Sno','Particular','Amount in TZS(month/Quarter to date )','Amount in TZS(previous month/Quarter)']);
    headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6'} }; // Light Blue background color
    headerRow2e.font = { bold: true,};
    headerRow2e.height = 25;
    worksheet.addRow(['1','Earning Assets  Loans','','']).font = { bold: true,}; 
    worksheet.addRow(['2', 'Short-term Loans (<= 1 year)',totalShortTermLoanAmount || '0', '']);
    worksheet.addRow(['3', 'Medium-term Loans (>1<=3 years)',totalMediumTermLoanAmount || '0', '']);
    worksheet.addRow(['4', 'Long-term Loans (>3 < = 5 years)',totalLongTermLoanAmount || '0', '']);
    worksheet.addRow(['5','Loan Participations*','0','',]);
    worksheet.addRow(['6','(Less the Allowance for Loan Losses)','0','',])
    worksheet.addRow(['7','Total Net Loans',NetLoan.toLocaleString(),'',]).font = { bold: true,};
    worksheet.addRow(['8','Earning Assets  Investments (sum 21:28)','','',])
    worksheet.addRow(['9','Government Securities <= 30 days to maturity','0','',])
    worksheet.addRow(['10','Government Securities > 30 days to maturity','0','',])
    worksheet.addRow(['11','Board and Committee Investments in Banks and other Financial Institutions <= 30 days to maturity (FDR)',CashOnOtherBanks.toLocaleString(),'']);
    worksheet.addRow(['12','Investments in Banks and other Financial Institutions > 30 days to maturity (FDR)',CashOnOtherBanks.toLocaleString(),'',]);
    worksheet.addRow(['13','Investment in other SACCOS or 2nd tier organizations','0','',])
    worksheet.addRow(['14','Other Investments','0','',])
    worksheet.addRow(['15','(Less the Allowance for Investment Loss)','0','',]);
    worksheet.addRow(['16','Non-Financial Investments','0','',])
    worksheet.addRow(['17','Total Net Earning Assets Investments ','0','',]).font = { bold: true,}; 
    worksheet.addRow(['18','Non-Earning Assets  (Cash and Cash Equivalent)','','',])
    worksheet.addRow(['19','Cash on Hand',cashOnHand.toLocaleString(),'',])
    worksheet.addRow(['20','Current Accounts with banks and other financial institutions',CashOnOtherBanks.toLocaleString(),'',])
    worksheet.addRow(['21','Other Non-Interest Earning Deposits with banks and other financial institutions*','0','']);
    worksheet.addRow(['22','Balances with Mobile Money Networks e.g MPESA, TIGOPESA',CashOnOtherNobile.toLocaleString(),'',]);
    worksheet.addRow(['23','Agent Banking Account Balances (Wakala)',CashOnOtherNobile.toLocaleString(),'',])
    worksheet.addRow(['24','Other assets maturing  <= 30 days to maturity','0','',])
    worksheet.addRow(['25','Total Non-Earning Assets ( Cash and Cash Equivalent )',TotalNonEarning.toLocaleString(),'',]).font = { bold: true,}; 
    worksheet.addRow(['26','Non-Earning Assets ( Fixed Assets)','','',])
    worksheet.addRow(['27','Land (cost)','0','',])
    worksheet.addRow(['28','Buildings (cost)','0','',])
    worksheet.addRow(['29','Furniture, Fixtures and Equipment (cost)','0','',])
    worksheet.addRow(['30','Other Fixed Assets (cost)','0','',])
    worksheet.addRow(['31','Leasehold Improvements (cost)','0','']);
    worksheet.addRow(['32','Revaluation of Fixed Assets','0','',]);
    worksheet.addRow(['33','(Less Accumulated Depreciation)','0','',])
    worksheet.addRow(['34','Total Net Non-Earning Assets (Fixed Assets)','0','',]).font = { bold: true,}; 
    worksheet.addRow(['35','Non-Earning Assets (Other Assets)','0','',]);
    worksheet.addRow(['36','Accounts Receivable due in <=30 days','0','',])
    worksheet.addRow(['37','Accounts Receivable due in > 30 days','0','',])
    worksheet.addRow(['38','Accrued Income on Loans','0','',])
    worksheet.addRow(['39','Accrued Income on Investments ','0','',])
    worksheet.addRow(['40','Other Accrued Income','0','',])
    worksheet.addRow(['41','Prepaid Expenses','0','']);
    worksheet.addRow(['42','Internal Receivables','0','',]);
    worksheet.addRow(['43','Assets in Liquidation','0','',])
    worksheet.addRow(['44','Suspense Accounts','0','',])
    worksheet.addRow(['45','Other Assets','0','',]);
    worksheet.addRow(['46','(Less Allowance for Other Asset Losses) ','0','',])
    worksheet.addRow(['47','Total Net Non-Earning Assets (Other Assets)','0','',]).font = { bold: true,}; 
    worksheet.addRow(['48','TOTAL ASSETS ','0','',])
    worksheet.addRow(['49','Liabilities and Equity','0','',])
    worksheet.addRow(['50','Short-Term Borrowings from other SACCOs and 2nd Tier Organizations(< =30 days to  maturity)','0','',])
    worksheet.addRow(['51','Short-Term Borrowings from Banks and Financial Insititutions(< =30 days to  maturity)','0','']);
    worksheet.addRow(['52','Long-Term Borrowings from other SACCOs and 2nd Tier Organizations  (> 30 days to maturity)','0','',]);
    worksheet.addRow(['53','Long-Term Borrowings from Banks and Financial Institutions(> 30 days to maturity)','0','',])
    worksheet.addRow(['54','Total Borrowed Funds ','0','',]).font = { bold: true,}; 
    worksheet.addRow(['55','Interest Bearing  Savings and Deposits','0','',]);
    worksheet.addRow(['56','Savings Accounts',totalSavings.toLocaleString(),'',])
    worksheet.addRow(['57','Term Deposits','0','',])
    worksheet.addRow(['58','Special Savings','0','',])
    worksheet.addRow(['59','Other Savings','0','',])
    worksheet.addRow(['60','Total Savings and Deposits','0','',]).font = { bold: true,}; 
    worksheet.addRow(['61','Non-Interest Bearing Liabilities','0','']);
    worksheet.addRow(['62','Accounts Payable due in < 30 days','0','',]);
    worksheet.addRow(['63','Other Payables','0','',])
    worksheet.addRow(['64','Accrued Interest Payable on Savings','0','',])
    worksheet.addRow(['65','Accrued Interest Payable on External Credit','0','',]);
    worksheet.addRow(['66','Taxes Payable','0','',])
    worksheet.addRow(['67','Internal Payable','0','',])
    worksheet.addRow(['68','Suspense Accounts','0','',])
    worksheet.addRow(['69','Other Liabilities','0','',])
    worksheet.addRow(['70','Total Non-Interest Bearing Liabilities','0','',]).font = { bold: true,}; 
    worksheet.addRow(['71','Total Liabilities','0','']);
    worksheet.addRow(['72','Equity','0','',]);
    worksheet.addRow(['73','Member Shares',totalShares.toLocaleString(),'',])
    worksheet.addRow(['74','Voluntary Shares','0','',])
    worksheet.addRow(['75','Other Shares','0','',]);
    worksheet.addRow(['76','Fixed Asset Revaluation Reserve','0','',])
    worksheet.addRow(['77','Transitory Capital','0','',])
    worksheet.addRow(['78','Donations and Grants (cash only)','0','',])
    worksheet.addRow(['79','Reserve Funds','0','',])
    worksheet.addRow(['80','Retained Earnings (Profit/Loss for Past Years)','0','',]).font = { bold: true,}; 
    worksheet.addRow(['81','Profit/Loss for the Current Month / quarter','0','',])
    worksheet.addRow(['82','Total Equity','0','',])
    worksheet.addRow(['83','Total Liabilities and Equity','0','',])
    worksheet.addRow(['84','Off Balance Sheet  Other SACCOS Commitments and Obligations such as unfunded member lines of credit and Guarantees.','0','',])
 
    
   
     // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

            // Add additional content
            worksheet.addRow([]); // Empty row to create space
            worksheet.addRow(['NB','*Loan Participations (Loans provided to other SACCOS or Guarantees to Other SACCOS)']);
            worksheet.addRow(['','*Non-Earning Deposit (Compulsory savings/cash collateral against borrowings in financial insitutions) ']);
            worksheet.addRow(['','2nd Tier Organization: Appex Institution (SCCULT)']);


    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'financial_position.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleFinancialPositionReport,
    // ... (other data or functions you want to export)
  };
};

export default FinancialPosition;