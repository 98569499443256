// import { collection, getDocs } from 'firebase/firestore';

// export const getBalanceForDateRange = (item, startDate, endDate) => {
//   if (!startDate || !endDate) return 0;

//   const startDateObj = new Date(startDate);
//   const endDateObj = new Date(endDate);

//   let totalBalance = 0;
//   for (let d = startDateObj; d <= endDateObj; d.setDate(d.getDate() + 1)) {
//     const currentDateISOString = d.toISOString().split('T')[0];
//     const balanceMap = item.balances.find((balance) => {
//       if (!balance.date) return false;
//       const balanceDateISOString = balance.date
//         .toDate()
//         .toISOString()
//         .split('T')[0];
//       return balanceDateISOString === currentDateISOString;
//     });

//     totalBalance += balanceMap ? balanceMap.balance : 0;
//   }

//   return totalBalance;
// };

// export const getBalanceForCurrentDate = (item) => {
//   const currentDateISOString = new Date().toISOString().split('T')[0];
//   const balanceMap = item.balances.find((balance) => {
//     if (!balance.date) return false;
//     const balanceDateISOString = balance.date
//       .toDate()
//       .toISOString()
//       .split('T')[0];
//     return balanceDateISOString === currentDateISOString;
//   });

//   return balanceMap ? balanceMap.balance : 0;
// };

// export const netProfitCalculator = async (db, startDate, endDate) => {
//   const allKeys = [];

//   const processItem = async (collectionName) => {
//     const collectionRef = collection(db, collectionName);
//     const snapshot = await getDocs(collectionRef);
//     const items = snapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     const processItemRecursively = (item, level, allItems) => {
//       allKeys.push(item.id);
//       if (startDate && endDate) {
//         item.amount = getBalanceForDateRange(item, startDate, endDate);
//       } else {
//         item.amount = getBalanceForCurrentDate(item);
//       }

//       if (level === 'parent') {
//         return {
//           ...item,
//           key: item.id,
//           children: allItems
//             .filter(
//               (child) =>
//                 child.parentAccount === item.account_name &&
//                 child.account_level === 'child'
//             )
//             .map((child) => processItemRecursively(child, 'child', allItems)),
//         };
//       } else if (level === 'child') {
//         const children = allItems
//           .filter(
//             (grandchild) =>
//               grandchild.parentAccount === item.account_name &&
//               grandchild.account_level === 'grandchild'&& (grandchild.IsMainAccount !== 'No')
//           )
//           .map((grandchild) =>
//             processItemRecursively(grandchild, 'grandchild', allItems)
//           );
//         return {
//           ...item,
//           key: item.id,
//           children,
//         };
//       } else {
//         return {
//           ...item,
//           key: item.id,
//         };
//       }
//     };

//     const itemsProcessed = items
//       .filter((item) => item.account_level === 'parent')
//       .map((item) => processItemRecursively(item, 'parent', items));

//     const totalAmount = itemsProcessed.reduce(
//       (total, item) => total + item.amount,
//       0
//     );

//     return { itemsProcessed, totalAmount };
//   };

//   const {
//     itemsProcessed: incomesProcessed,
//     totalAmount: totalIncome,
//   } = await processItem('Income');

//   const {
//     itemsProcessed: expensesProcessed,
//     totalAmount: totalExpenses,
//   } = await processItem('Expenses');

//   const netProfit = totalIncome - totalExpenses;

//   return {
//     totalIncome,
//     totalExpenses,
//     netProfit,
//     incomesProcessed,
//     expensesProcessed,
//     allKeys,
//   };
// };


// src/utils/profitLossUtils.js

// profitLossCalculator.js
import { collection, query, getDocs } from "firebase/firestore";

export const calculateProfitLoss = async (db, startDate, endDate, accountType) => {
  const getCollectionData = async (collectionName) => {
    const q = query(collection(db, collectionName));
    const querySnapshot = await getDocs(q);
    const documents = [];
    querySnapshot.forEach((doc) => {
      const document = doc.data();
      // Only include documents where IsMainAccount is not "No"
      if (document.IsMainAccount !== "No") {
        const balances = document.balances ? document.balances.filter((balance) => {
          const balanceDate = new Date(balance.date.toDate());
          if (startDate && endDate) {
            return balanceDate >= startDate && balanceDate <= new Date(endDate.getTime() + 24 * 60 * 60 * 1000);
          }
          return true;
        }) : [];
        documents.push({ id: doc.id, ...document, balances });
      }
    });
    return documents;
  };

  const formatData = (income, expenses) => {
    const getLatestBalance = (accounts) => {
      return accounts.map((account) => {
        const balances = account.balances;
        const latestBalance = balances.reduce((latest, current) => {
          return current.date > latest.date ? current : latest;
        }, { date: 0 });
        return {
          ...account,
          latestBalance: latestBalance.balance || 0,
        };
      });
    };

    return {
      profitLoss: {
        expenses: getLatestBalance(categorizeAccounts(expenses, "Expenses",accountType)),
        income: getLatestBalance(categorizeAccounts(income, "Income", accountType)),
      },
    };
  };

  const categorizeAccounts = (accounts, category, accountType) => {
    const categorized = accounts.filter(account => 
      account.account_type === category && account.IsMainAccount !== "No"
    );
    const structuredData = buildHierarchy(categorized, accountType);
    return structuredData;
  };

  const buildHierarchy = (accounts, accountType) => {
    const hierarchy = [];
    const parentAccounts = accounts.filter(account => account.account_level === "parent" && account.IsMainAccount !== "No");
    
    parentAccounts.forEach(parent => {
      const children = accounts.filter(account => 
        account.parentAccount === parent.account_name && account.IsMainAccount !== "No"
      );
      
      const structuredChildren = children.map(child => {
        const grandchildren = accounts.filter(account => 
          account.parentAccount === child.account_name && account.IsMainAccount !== "No"
        );
        
        return {
          ...child,
          subCategory: getLatestBalance(grandchildren),
        };
      });
  
      let accountToAdd;
      switch(accountType) {
        case 'Parent Accounts':
          accountToAdd = { ...parent, subCategory: [] };
          break;
        case 'Sub Accounts':
          accountToAdd = { ...parent, subCategory: getLatestBalance(structuredChildren.map(child => ({ ...child, subCategory: [] }))) };
          break;
        case 'Child Accounts':
          accountToAdd = { ...parent, subCategory: getLatestBalance(structuredChildren) };
          break;
        default:
          accountToAdd = { ...parent, subCategory: getLatestBalance(structuredChildren) };
      }
      
      hierarchy.push(accountToAdd);
    });
    
    return hierarchy;
  };


  const getLatestBalance = (accounts) => {
    return accounts.map(account => {
      const latestBalance = account.balances.reduce((latest, current) => {
        return current.date > latest.date ? current : latest;
      }, { date: 0 });
      return {
        ...account,
        latestBalance: latestBalance.balance,
      };
    });
  };

  
  const calculateTotal = (items) => {
    if (!items) return 0;
  
    return items.reduce((acc, item) => {
      if (item.account_level === 'parent') {
        const latestBalance = item.latestBalance || 0;
        const subTotal = latestBalance + (item.subCategory ? calculateTotal(item.subCategory) : 0);
        return acc + subTotal;
      } else {
        return acc;
      }
    }, 0);
  };

  const incomeData = await getCollectionData("Income");
  const expensesData = await getCollectionData("Expenses");
  const formattedData = formatData(incomeData, expensesData);

  const totalIncome = calculateTotal(formattedData.profitLoss.income);
  const totalExpenses = calculateTotal(formattedData.profitLoss.expenses);
  const netIncome = totalIncome - totalExpenses;

  return {
    ...formattedData,
    totalIncome,
    totalExpenses,
    netIncome
  };
};



