import React, { useState,useEffect } from 'react';
import {
  Col,
} from 'reactstrap';
import CountUp from 'react-countup';
import { getTotalLoanDisbursements,getTotalLoanPayments } from './data';
import StatsCard from '../../../components/StatsCard/statsCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';

const LoanWidgets = () => {
  const [totalLoanDisbursed, setTotalLoanDisbursed] = useState(0);
  const [totalLoanPayments, setTotalLoanPayments] = useState(0);

  const history = useHistory();


  useEffect(() => {
    const fetchData = async () => { // Set loading to true when fetching data
      try {
        const loanDisbursements = await getTotalLoanDisbursements();
        setTotalLoanDisbursed(loanDisbursements);
        const loanPayments = await getTotalLoanPayments();
        setTotalLoanPayments(loanPayments);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
        <Col sm={12} md={6}>
        <StatsCard
        title="Loan Payments"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalLoanPayments} duration={2} separator="," />}</div>}
        icon={<FontAwesomeIcon icon={faCreditCard}/>} 
        color="#28d456"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
        <Col sm={12} md={6}>
       <StatsCard
        title="Loan Disbursements"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalLoanDisbursed} duration={2} separator="," />}</div>}
        icon={<FontAwesomeIcon icon={faMoneyCheckDollar}/>} 
        color="#4b3e75"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
     </>
     )
    }
     export default LoanWidgets;