import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
// import { countUsers,getTotalApprovedLoanAmount,getTotalSavingsAmount,fetchActiveUsersCount } from '../widgets/data';
// import { fetchUserCountsByGender,fetchLoansDataAndPercentageByGender } from '../dashboard/data';

const ComprehensiveIncome = () => {

const [userCount, setUserCount] = useState("loading...");
const [maleCount, setMaleCount] = useState("loading...");
const [femaleCount, setFemaleCount] = useState("loading...");
const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState("loading...");
const [totalSavings, setTotalSavings] = useState("loading...");
const [activeUsersCount, setActiveUsersCount] = useState("loading...");
const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState("loading...");
const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState("loading...");
const [loanData, setLoanData] = useState([]);
const [totalMaleLoanCount, setTotalMaleLoanCount] = useState("loading...");
const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState("loading...");

// useEffect(() => {
//   // Fetch the total savings amount when the component mounts
//   getTotalSavingsAmount()
//     .then((formattedTotalSavingsAmount) => {
//       setTotalSavings(formattedTotalSavingsAmount);
//     })
//     .catch((error) => {
//       console.error('Error fetching total savings:', error);
//     });
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const counts = await fetchUserCountsByGender(window.location);
//       setMaleCount(counts.maleCount);
//       setFemaleCount(counts.femaleCount);
//     } catch (error) {
//       console.error('Error fetching user counts:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const result = await fetchLoansDataAndPercentageByGender(window.location);
//       setLoanData(result.totalLoanData);
//       // setLoanPercentage(result.loanPercentage);
//       // setTotalLoanAmount(result.totalLoanAmount);
//       setTotalMaleLoanAmount(result.totalMaleLoanAmount);
//       setTotalFemaleLoanAmount(result.totalFemaleLoanAmount);
//       setTotalMaleLoanCount(result.totalMaleLoanCount);
//       setTotalFemaleLoanCount(result.totalFemaleLoanCount);
//     } catch (error) {
//       console.error('Error fetching loan data:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []); 

   const  handleComprehensiveIncomeReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: 'NCAA WORKERS SAVINGS AND CREDIT COOPERATIVE SOCIETY LIMITED',
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','STATEMENT OF COMPREHENSIVE INCOME FOR THE MONTH ENDED:']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 01: To be submitted Monthly for Category B and Quartely for Category A',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    // Add additional details
    const headerRow2e = worksheet.addRow(['Sno','Particular','Amount in TZS(month/Quarter to date )','Amount in TZS(previous month/Quarter)']);
    headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6'} }; // Light Blue background color
    headerRow2e.font = { bold: true,};
    headerRow2e.height = 25;
    worksheet.addRow(['1','Interest Income','52,340','']).font = { bold: true,}; 
    worksheet.addRow(['2','Interest Income On Loans','52,340','',]);
    worksheet.addRow(['3',' Interest Income- Government Securities','0','',])
    worksheet.addRow(['4','Interest Income - Deposits in Financial Institutions','0','',])
    worksheet.addRow(['5','Interest Income On Other SACCOS and 2nd tier Organizations','0','',]);
    worksheet.addRow(['6','Interest Income Other Investments','0','',])
    worksheet.addRow(['7','Total Interest Income','104,680','',]).font = { bold: true,}; 
    worksheet.addRow(['8','Interest Expense','0','',])
    worksheet.addRow(['9','Interest Expense on Borrowing','0','',])
    worksheet.addRow(['10','Interest Expense on Members Savings and Deposits Accounts','0','',])
    worksheet.addRow(['11','Other Interest Expenses','0','']);
    worksheet.addRow(['12','Total Interest Expense','0','',]).font = { bold: true,};
    worksheet.addRow(['13','Net Interest Income = (Total Interest Income Less Total Interest Expense)','0','',]).font = { bold: true,};
    worksheet.addRow(['14','Non Interest Income ','0','',]).font = { bold: true,};
    worksheet.addRow(['15','Commissions and Fees','0','',]);
    worksheet.addRow(['16','Penalties','0','',])
    worksheet.addRow(['17','Other Non Interest Income ','0','',]);
    worksheet.addRow(['18','Total Non Interest Income','0','',]).font = { bold: true,};
    worksheet.addRow(['19','Gross Operating Income = (Net Interest Income + Non Interest Income)  ','1,500,000','',]).font = { bold: true,};
    worksheet.addRow(['20','Operating Expenses ','0','',]).font = { bold: true,};
    worksheet.addRow(['21','Salaries and Wages ','260,000','']);
    worksheet.addRow(['22','Other Personnel Costs and Benefits','0','',]);
    worksheet.addRow(['23','Office Occupancy (Rent)','0','',])
    worksheet.addRow(['24','Operations Expenses (supplies, travel, training, communications, utilities, marketing, other administrative)','0','',])
    worksheet.addRow(['25','Professional Fees and Services','0','',])
    worksheet.addRow(['25','Depreciation','0','',]).font = { bold: true,}; 
    worksheet.addRow(['27','Maintenance/Repairs','0','',])
    worksheet.addRow(['28','Other Operating Expenses','0','',])
    worksheet.addRow(['29','Total Operating Expenses','0','',]).font = { bold: true,};
    worksheet.addRow(['30','Net Operating Income (Loss) = (Gross Operating Income-Total Operating Expenses ) ','0','',]).font = { bold: true,};
    worksheet.addRow(['31','Loan Loss Provisions (Recoveries) ','0','']).font = { bold: true,};
    worksheet.addRow(['32','Provision for Loan Losses','0','',]);
    worksheet.addRow(['33','Provisions for Investments and Other Losses (Other than Loans)','0','',])
    worksheet.addRow(['34','Less: Recoveries (Put it in income items)','0','',]).font = { bold: true,}; 
    worksheet.addRow(['35','Net Provision for Losses','0','',]).font = { bold: true,};
    worksheet.addRow(['36','Extraordinary Items','0','',]).font = { bold: true,};
    worksheet.addRow(['37','Extraordinary Income','0','',])
    worksheet.addRow(['38','Extraordinary Expenses','0','',])
    worksheet.addRow(['39',' Donations and Grants (cash only) ','0','',])
    worksheet.addRow(['40','Prior Period Adjustments','0','',])
    worksheet.addRow(['41','Total Extraordinary Income/(Expenses)','-1,640,000','']).font = { bold: true,};
    worksheet.addRow(['42','Net Profit Before Tax = ( Net Operating Income +/- Net Provision for Loan Loss +/- Total Extraordinary Income)','3,140,000','',]).font = { bold: true,};
    worksheet.addRow(['43','Tax Provisions','0','',]).font = { bold: true,};
    worksheet.addRow(['44','Net Profit After Tax = ( Net Profit Before Tax less Tax Provisions)','0','',]).font = { bold: true,};
    worksheet.addRow(['45','Dividend Paid on Shares','0','',]).font = { bold: true,};
    
   
     // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

            // Add additional content
            worksheet.addRow([]); // Empty row to create space
            worksheet.addRow(['(*SACCOS management should complete this form using their income statement information as of the reporting date.']);


    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'comprehensive_income.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleComprehensiveIncomeReport,
    // ... (other data or functions you want to export)
  };
};

export default ComprehensiveIncome;