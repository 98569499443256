import React, { useState, useEffect } from 'react';
import Widget from '../../components/Widget/Widget';
import { useParams, Link } from "react-router-dom";
import { Button,Badge,Collapse } from 'reactstrap';
import LoansReceipts from './loanReceipts';
import UserRequests from './userRequests';
import LoansPreviousTransactions from './loanPayoutUser';
import {fetchFirebaseConfig} from '../../firebase';
import {
    doc,
    getDoc,
    onSnapshot
  } from 'firebase/firestore';
  import CountUp from 'react-countup';
  import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
  import s from './LoanCharts.module.scss';
  import TabNavigation from '../../components/TabNavigation/tabnavigation';
  import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
  import { Breadcrumb } from 'antd';
  import { getThemeColor } from '../../components/ThemeSetUp';  

const LoansPaymentTabs = () => {

  const { id } = useParams(); // Get the UID from the URL

  const [activeTab, setActiveTab] = useState('allrequests');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false); 
  const tabContent = {
    allrequests: { label: 'All Requests', content: <UserRequests loanId={id} refreshTable={refreshDepositsTable} /> },
    receipts: { label: 'Receipts & Payslips', content: <LoansReceipts loanId={id} refreshTable={refreshDepositsTable} /> },
    payoutsRecords: { label: 'Previous Payments', content: <LoansPreviousTransactions loanId={id} refreshTable={refreshDepositsTable} /> }
  };
  const [name, setName] = useState("");
  const [loanType, setLoanType] = useState("");
  const [balance, setBalance] = useState(0);
  const [loanLimit, setLoanLimit] = useState(0);
  const [themeColor, setThemeColor] = useState('');

  const {db}=fetchFirebaseConfig();

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'payments' || key === 'payoutsRecords') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };

  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        if (!id) {
          console.error('UID is undefined');
          return;
        }
  
        // Fetch the document from the Assets collection using the provided id
        const userDocRef = doc(db, 'Assets', id);
        const userDocSnapshot = await getDoc(userDocRef);
  
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
  
          const balances = userData.balances || [];
          let latestBalanceEntry = null;
  
          // Find the latest balance entry based on the date
          balances.forEach((balanceEntry) => {
            if (
              !latestBalanceEntry ||
              (balanceEntry.date && balanceEntry.date.toDate && balanceEntry.date.toDate() > latestBalanceEntry.date.toDate())
            ) {
              latestBalanceEntry = balanceEntry;
            }
          });
  
          if (latestBalanceEntry) {
            const currentBalance = latestBalanceEntry.balance || 0;
  
            console.log('Balances:', balances);
  
            // Update balance, name, and loan type state
            setName(userData.account_name || '');
            setLoanType(userData.loanType || '');
            setBalance(currentBalance); // Update balance state with the latest value
  
            // Fetch the user document via the user reference field
            const userRef = userData.user;
            if (userRef) {
              const userSnapshot = await getDoc(userRef);
              if (userSnapshot.exists()) {
                const user = userSnapshot.data();
                console.log('Fetched user document data:', user);
  
                // Update loanLimit state
                setLoanLimit(user.loanLimit || 0);
              } else {
                console.log('User document does not exist');
              }
            } else {
              console.error('User reference field is missing');
            }
          } else {
            console.log('No balance entries found');
          }
        } else {
          console.log('User document does not exist');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchLoanData();

    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, [db, id]);
  

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Loans</Breadcrumb.Item>
        <Breadcrumb.Item>Loan Accounts</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Loans Tab</span></Breadcrumb.Item>
      </Breadcrumb>
      {/* <h5 className="mb-lg">{userDetails?.display_name}'s  Loans Panel</h5> */}
        {/* Profile Picture Widget */}
        <WidgetShadow>
            <div className="d-flex align-items-start justify-content-center flex-column" style={{ height: '100%' }}>
              <div style={{ borderBottom: `1px solid rgba(128, 128, 128, 0.03)`, width: '100%', marginBottom: '5px', paddingBottom: '5px', backgroundColor:'#fffff5' }}>
                <div className="d-flex justify-content-start align-items-center">
                  <div>
                    <h6 className="mt-1" style={{fontWeight:'bold'}}>{name}</h6>
                  </div>
                </div>
              </div>
            <div className="d-flex flex-column align-items-start" style={{ paddingLeft: '15px' }}>
              <div className="mr-4"> <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601' }}>Loan Balance :&nbsp;&nbsp;<span className={s.tshLabel}>Tsh</span>&nbsp;</span><CountUp start={0} end={balance} duration={2} separator="," /> </div> 
              <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601'}}>Loan Limit :&nbsp;&nbsp;<span className={s.tshLabel}>Tsh</span>&nbsp;</span>&nbsp;<CountUp start={0} end={loanLimit} duration={2} separator="," /></div> 
               <div className="mb-1"> <span style={{ fontSize: '12px',fontWeight: '601'}}>Loan Type :&nbsp;</span>&nbsp;{loanType} </div> 
             </div> 
             </div> 
             </div>
            </WidgetShadow>

            {/* Tabs */}           
        <WidgetShadow>
        <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} tabContent={tabContent} />
      </WidgetShadow>
    </div>
  );
};

export default LoansPaymentTabs;

