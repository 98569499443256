// import React, { useRef, useState, useEffect } from 'react';
// import { Table } from 'antd';
// import { Spinner,Input as InputData} from 'reactstrap';
// import Widget from '../../../../components/Widget/Widget';
// import { fetchFirebaseConfig } from '../../../../firebase';
// import { collection, getDocs, query, where, getDoc, doc, updateDoc, serverTimestamp, onSnapshot, addDoc } from 'firebase/firestore';
// import { Button as Click, ButtonGroup } from 'reactstrap';
// import ReactToPrint from 'react-to-print'; 
// import { useHistory,Link,useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import 'firebase/compat/auth';

// const UserCurrentLoanArmotization = () => {
//   const [data, setData] = useState([]);
//   const [printing, setPrinting] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [annualInterestRate, setAnnualInterestRate] = useState(''); // Default value
//   const [loanTermMonths, setLoanTermMonths] = useState(''); // Default value
//   const [loanPrinciple, setLoanPrinciple] = useState(''); // Default value
//   const [userData, setUserData] = useState('');
//   const [userName, setUserName] = useState('');


//   const {id} = useParams();

//   const componentRef = useRef(null);

//   const { db } = fetchFirebaseConfig();

//   useEffect(() => {
//     const fetchLoanData = async () => {
//       try {
//         if (!id) {
//           console.error('UID is undefined');
//           return;
//         }
//         const userDocRef = doc(db, 'Loans', id);
//         const userDocSnapshot = await getDoc(userDocRef);
//         if (userDocSnapshot.exists()) {
//           const userData = userDocSnapshot.data();
//           console.log('Fetched user data:', userData);
//           const loanPrinciple = userData.amountRemaining || 0;
//           const loanTermMonths = userData.repaymentMonths || 0;
//           const annualInterestRate = userData.interestRate || 0;
  
//           setLoanPrinciple(loanPrinciple);
//           setLoanTermMonths(loanTermMonths);
//           setAnnualInterestRate(annualInterestRate);
  
//           if (userData.user) {
//             const userId = userData.user.id;
//             const userDoc = await getDoc(doc(db, 'users', userId));
//             if (userDoc.exists()) {
//               const firstName = userDoc.data().display_name;
//               const midName = userDoc.data().mid_name;
//               const sirName = userDoc.data().sir_name;
//               const userFullName = `${firstName} ${midName ? midName.charAt(0).toUpperCase() + '. ' : ''}${sirName || ''}`.trim();
//               console.log('username', userFullName);
//               setUserData({
//                 firstName,
//                 midName,
//                 sirName,
//               });
//               setUserName(userFullName)
//             } else {
//               console.log('User document not found');
//             }
//           } else {
//             console.log('User reference not found');
//           }
  
//           // Call fetchPayments with the updated values
//           fetchPayments(loanPrinciple, annualInterestRate, loanTermMonths);
//         } else {
//           console.log('Loan document not found');
//         }
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//       }
//     };
  
//     fetchLoanData();
//   }, [db, id]);


//   const fetchPayments = async (loanPrinciple,annualInterestRate,loanTermMonths) => {
//     try {
//         if (!id) {
//             console.error('UID is undefined');
//             return;
//         }

//         const paymentsQuery = query(
//             collection(db, 'allTransaction2'),
//             where('transactionType', '==', 'Loan Payment'),
//             where('approvalStatus', '==', 'Approved'),
//             where('transactionId', '==', id)
//         );

//         const paymentsSnapshot = await getDocs(paymentsQuery);
//         const payments = [];

//         console.log('before', payments)

//         console.log('principle', loanPrinciple);
//         console.log('interest', annualInterestRate);
//         console.log('terms', loanTermMonths);

//         paymentsSnapshot.forEach((doc) => {
//             const paymentData = doc.data();
//             if (!paymentData.hasOwnProperty('armotized') || paymentData.armotized !== 'yes') {
//                 payments.push({
//                     id: doc.id,
//                     amount: paymentData.transactionAmount,
//                     date: paymentData.transactionDate,
//                 });
//             }
//         });

//         console.log('After', payments)

//         if (payments.length > 0 && (loanPrinciple && annualInterestRate && loanTermMonths)) {
//             await calculateLoanDetails(loanPrinciple, annualInterestRate, loanTermMonths, payments);
//         }else{
//           return null
//         }
//     } catch (error) {
//         console.error('Error processing payments:', error);
//     }
// };

// const fetchData = async () => {
//   setLoading(true);
//     try {
//         if (!id) {
//             console.error('UID is undefined');
//             return;
//         }

//        // Query payments where loanAmortization is equal to a specific ID
//           const paymentsQuery = query(
//             collection(db, 'Amortizations'),
//             where('loanAmortization', '==', id)
//           );

//           // Get the snapshot of the documents returned by the query
//           const paymentsSnapshot = await getDocs(paymentsQuery);

//           // Use Promise.all to process each document asynchronously
//           const paymentsAmortizations = await Promise.all(
//           paymentsSnapshot.docs.map(async (doc) => {
//             const paymentData = doc.data();
//             return {
//               id: doc.id,
//               paymentDate: paymentData.paymentDate, // Assuming paymentDate is stored as a string
//               beginningBalance: paymentData.beginningBalance,
//               payment: paymentData.payment,
//               principal: paymentData.principal,
//               interest: paymentData.interest,
//               endingBalance: paymentData.endingBalance,
//             };
//           })
//           );

//           const sortedNewLoansData = paymentsAmortizations.sort((b,a) => b.paymentDate.seconds - a.paymentDate.seconds);

//         setData(sortedNewLoansData);
//         setLoading(false);
//     } catch (error) {
//         console.error('Error processing payments amortizations:', error);
//     }
// };

// useEffect(() => {
// fetchData();
// }, [db, id]);

//   const updateAssetsBalance = async (user, loanSchedual, principalPortion) => {
//     try {
//       const assetsQuery = query(
//         collection(db, 'Assets'),
//         where('user', '==', user),
//         where('loanType', '==', loanSchedual)
//       );
//       const assetsSnapshot = await getDocs(assetsQuery);
  
//       if (!assetsSnapshot.empty) {
//         const assetDoc = assetsSnapshot.docs[0];
//         const assetData = assetDoc.data();
  
//         // Find the latest balance entry by date
//         const balances = assetData.balances || [];
//         let latestBalanceEntry = null;
//         let latestBalanceIndex = -1;
//         balances.forEach((balanceEntry, index) => {
//           if (
//             !latestBalanceEntry ||
//             (balanceEntry.date && balanceEntry.date.serverTimestamp && balanceEntry.date.serverTimestamp.seconds > latestBalanceEntry.date.serverTimestamp.seconds)
//           ) {
//             latestBalanceEntry = balanceEntry;
//             latestBalanceIndex = index;
//           }
//         });
  
//         if (latestBalanceEntry) {
//           const currentBalance = latestBalanceEntry.balance || 0;
//           const currentCreditBalance = latestBalanceEntry.creditBalance || 0;
//           const currentDebitBalance = latestBalanceEntry.debitBalance || 0;

//           const updatedBalance = currentBalance - principalPortion;
//           const newCreditBalance = currentCreditBalance + principalPortion; // No change for debits
//           const newDebitBalance = currentDebitBalance
  
//           // Update the latest balance entry in balances array
//           balances[latestBalanceIndex].balance = updatedBalance;
//           balances[latestBalanceIndex].creditBalance = newCreditBalance;
//           balances[latestBalanceIndex].debitBalance = newDebitBalance;
//           // balances[latestBalanceIndex].date = new Date(); // Update date for tracking
  
//           await updateDoc(doc(db, 'Assets', assetDoc.id), { balances });
//         } else {
//           console.error(`No balance entries found for user '${user}' and loan type '${loanSchedual}'.`);
//         }
//       }
//     } catch (error) {
//       console.error('Error updating asset balance:', error);
//     }
//   };
  
  
//   const calculateLoanDetails = async (loanAmount, annualInterestRate, loanTermMonths, payments) => {
//     try {
//       const accountsSnapshot = await getDocs(query(collection(db, 'PrincipleInterestAccounts'), where('company_id', '==', 'NCAAWS')));
  
//       if (accountsSnapshot.empty) {
//         throw new Error('No account information found for the specified company_id');
//       }
  
//       const accountDoc = accountsSnapshot.docs[0];
//       const accountData = accountDoc.data();
  
//       console.log('principle', accountData.principleAccount);
//       console.log('interest', accountData.interestAccount);
//       console.log('interest reference', accountData.interestAccountReference);
  
//       const monthlyInterestRate = (annualInterestRate / 12) / 100;
//       const monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow((1 + monthlyInterestRate), -loanTermMonths));
  
//       let remainingBalance = loanAmount;
//       const loanData = [];
//       const collections = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
  
//       for (const payment of payments) {
//         const interestPortion = remainingBalance * monthlyInterestRate;
//         const principalPortion = payment.amount - interestPortion;
//         const endingBalance = remainingBalance - principalPortion;
  
//         // Add document to 'Amortizations' collection
//         await addDoc(collection(db, 'Amortizations'), {
//             paymentDate:payment.date, // Check if payment.date is a valid Date object
//             beginningBalance: parseFloat(remainingBalance),
//             payment: parseFloat(payment.amount),
//             principal: parseFloat(principalPortion),
//             interest: parseFloat(interestPortion),
//             endingBalance: parseFloat(endingBalance),
//             loanAmortization:id
//         });

//         await updateDoc(doc(db, 'allTransaction2', payment.id), { armotized: 'yes' });

//         const userLoanDocRef = doc(db, 'Loans', id);
//         const userLoanDocSnapshot = await getDoc(userLoanDocRef);

//          // Update the amountRemaining field in the Loans document
//          await updateDoc(userLoanDocRef, { amountRemaining: endingBalance });
//          await updateDoc(userLoanDocRef,{ amountPaid: principalPortion });
    
//         remainingBalance = endingBalance;
  
//         // Update principal account balance
//         for (const collectionName of collections) {
//           const collectionRef = collection(db, collectionName);
//           const principalDocRef = query(collectionRef, where('account_name', '==', accountData.principleAccount));
//           const principalSnapshot = await getDocs(principalDocRef);
  
//           if (!principalSnapshot.empty) {
//             const principalDoc = principalSnapshot.docs[0];
//             const principalData = principalDoc.data();
  
//             // Find the latest balance entry by date
//             const balances = principalData.balances || [];
//             let latestBalanceEntry = null;
//             let latestBalanceIndex = -1;
  
//             balances.forEach((balanceEntry, index) => {
//               if (
//                 !latestBalanceEntry ||
//                 (balanceEntry.date && balanceEntry.date.serverTimestamp && balanceEntry.date.serverTimestamp.seconds > latestBalanceEntry.date.serverTimestamp.seconds)
//               ) {
//                 latestBalanceEntry = balanceEntry;
//                 latestBalanceIndex = index;
//               }
//             });
  
//             if (latestBalanceEntry) {
//               const currentBalance = latestBalanceEntry.balance || 0;
//               const currentCreditBalance = latestBalanceEntry.creditBalance || 0;
//               const currentDebitBalance = latestBalanceEntry.debitBalance || 0;
  
//               // Determine which balance to update based on collection type
//               let newCreditBalance = currentCreditBalance;
//               let newDebitBalance = currentDebitBalance;
//               if (collectionName === 'Assets' || collectionName === 'Expenses') {
//                 newDebitBalance += principalPortion;
//               } else {
//                 newCreditBalance += principalPortion;
//               }
  
//               const updatedPrincipalBalance = currentBalance + principalPortion;
  
//               // Update the latest balance entry in balances array
//               balances[latestBalanceIndex].balance = updatedPrincipalBalance;
//               balances[latestBalanceIndex].creditBalance = newCreditBalance;
//               balances[latestBalanceIndex].debitBalance = newDebitBalance;
  
//               await updateDoc(doc(db, collectionName, principalDoc.id), { balances });
//             } else {
//               console.error(`No balance entries found for account '${accountData.principleAccount}'.`);
//             }
  
//             break;
//           }
//         }
  
//         // Update interest account balance
//         for (const collectionName of collections) {
//           const collectionRef = collection(db, collectionName);
//           const interestDocRef = query(collectionRef, where('account_name', '==', accountData.interestAccount));
//           const interestSnapshot = await getDocs(interestDocRef);
  
//           if (!interestSnapshot.empty) {
//             const interestDoc = interestSnapshot.docs[0];
//             const interestData = interestDoc.data();
  
//             // Find the latest balance entry by date
//             const balances = interestData.balances || [];
//             let latestBalanceEntry = null;
//             let latestBalanceIndex = -1;
  
//             balances.forEach((balanceEntry, index) => {
//               if (
//                 !latestBalanceEntry ||
//                 (balanceEntry.date && balanceEntry.date.serverTimestamp && balanceEntry.date.serverTimestamp.seconds > latestBalanceEntry.date.serverTimestamp.seconds)
//               ) {
//                 latestBalanceEntry = balanceEntry;
//                 latestBalanceIndex = index;
//               }
//             });
  
//             if (latestBalanceEntry) {
//               const currentBalance = latestBalanceEntry.balance || 0;
//               const currentCreditBalance = latestBalanceEntry.creditBalance || 0;
//               const currentDebitBalance = latestBalanceEntry.debitBalance || 0;
  
//               const updatedInterestBalance = currentBalance + interestPortion;
  
//               // Determine which balance to update based on collection type
//               let newCreditBalance = currentCreditBalance;
//               let newDebitBalance = currentDebitBalance;
//               if (collectionName === 'Liabilities' || collectionName === 'Expenses' || collectionName === 'Income') {
//                 newCreditBalance += interestPortion;
//               } else {
//                 newDebitBalance += interestPortion;
//               }
  
//               // Update the latest balance entry in balances array
//               balances[latestBalanceIndex].balance = updatedInterestBalance;
//               balances[latestBalanceIndex].creditBalance = newCreditBalance;
//               balances[latestBalanceIndex].debitBalance = newDebitBalance;
  
//               await updateDoc(doc(db, collectionName, interestDoc.id), { balances });
//             } else {
//               console.error(`No balance entries found for account '${accountData.interestAccount}'.`);
//             }
  
//             break;
//           }
//         }
  
//         // Update interest account reference balance
//         for (const collectionName of collections) {
//           const collectionRef = collection(db, collectionName);
//           const interestRefDocRef = query(collectionRef, where('account_name', '==', accountData.interestAccountReference));
//           const interestRefSnapshot = await getDocs(interestRefDocRef);
  
//           if (!interestRefSnapshot.empty) {
//             const interestRefDoc = interestRefSnapshot.docs[0];
//             const interestRefData = interestRefDoc.data();
  
//             // Find the latest balance entry by date
//             const balances = interestRefData.balances || [];
//             let latestBalanceEntry = null;
//             let latestBalanceIndex = -1;
  
//             balances.forEach((balanceEntry, index) => {
//               if (
//                 !latestBalanceEntry ||
//                 (balanceEntry.date && balanceEntry.date.serverTimestamp && balanceEntry.date.serverTimestamp.seconds > latestBalanceEntry.date.serverTimestamp.seconds)
//               ) {
//                 latestBalanceEntry = balanceEntry;
//                 latestBalanceIndex = index;
//               }
//             });
  
//             if (latestBalanceEntry) {
//               const currentBalance = latestBalanceEntry.balance || 0;
//               const currentCreditBalance = latestBalanceEntry.creditBalance || 0;
//               const currentDebitBalance = latestBalanceEntry.debitBalance || 0;
  
//               const updatedInterestRefBalance = currentBalance + interestPortion;
  
//               // Determine which balance to update based on collection type
//               let newCreditBalance = currentCreditBalance;
//               let newDebitBalance = currentDebitBalance;
//               if (collectionName === 'Liabilities' || collectionName === 'Expenses' || collectionName === 'Income') {
//                 newCreditBalance += interestPortion;
//               } else {
//                 newDebitBalance += interestPortion;
//               }
  
//               // Update the latest balance entry in balances array
//               balances[latestBalanceIndex].balance = updatedInterestRefBalance;
//               balances[latestBalanceIndex].creditBalance = newCreditBalance;
//               balances[latestBalanceIndex].debitBalance = newDebitBalance;
  
//               await updateDoc(doc(db, collectionName, interestRefDoc.id), { balances });
//             } else {
//               console.error(`No balance entries found for account '${accountData.interestAccountReference}'.`);
//             }
  
//             break;
//           }
//         }
        
//         // Update the corresponding asset balance
//         const userDocRef = doc(db, 'Loans', id);
//         const userDocSnapshot = await getDoc(userDocRef);
//         const userData = userDocSnapshot.data();
//         await updateAssetsBalance(userData.user, userData.loanSchedual, principalPortion, collections);

//           const userId = userData.user.id;
//           const userDoc = await getDoc(doc(db, 'users', userId));
//           const firstName = userDoc.data().display_name;
//           const midName = userDoc.data().mid_name;
//           const sirName = userDoc.data().sir_name;
//           const userFullName = `${firstName} ${midName ? midName.charAt(0).toUpperCase() + '. ' : ''}${sirName || ''}`.trim();
//           console.log('username', userFullName);
//           setUserData({
//               firstName,
//               midName,
//               sirName,
//            });

//         const paymentAmount = parseFloat(principalPortion) + parseFloat(interestPortion)

//         // Example generateAccountCode function to generate a unique account code
//       const generateAccountCode = () => {
//         // Generate a random three-digit number and concatenate with "0"
//         const randomDigits = Math.floor(Math.random() * 900) + 100; // Generates a number between 100 and 999
//         return `${randomDigits}0`;
//         };
 
//       const transferCode = `RT${generateAccountCode()}`

//         const journalData = {
//           journalCode: transferCode, // Replace with your actual journal code
//           description: 'Reducing Payment Transfer',
//           date: new Date(), // Replace with your actual date format
//           total_credit: paymentAmount,
//           total_debit: paymentAmount,
//           transactionType: 'Transfer',
//           entries: [
//               {
//                   debit_account: accountData.principleAccount,
//                   debit_account_code:'',
//                   credit_account: '',
//                   debit_amount: principalPortion,
//                   credit_amount: 0,
//                   double_entry_description: 'Transfer to Loan Account',
//               },
//               {
//                 debit_account: accountData.interestAccountReference,
//                 debit_account_code:'',
//                 credit_account: '',
//                 debit_amount: interestPortion,
//                 credit_amount: 0,
//                 double_entry_description: 'Transfer to Bank Account',
//               },
//               {
//                   debit_account: '',
//                   credit_account:accountData.interestAccount,
//                   credit_account_code:'',
//                   debit_amount: 0,
//                   credit_amount: interestPortion,
//                   double_entry_description: 'Transfer from Account',
//               },
//               {
//                 debit_account: '',
//                 credit_account:userFullName,
//                 credit_account_code:'',
//                 debit_amount: 0,
//                 credit_amount:principalPortion,
//                 double_entry_description: 'Long Term Loan',
//             },
//           ],
//       };

//       await addDoc(collection(db, 'Journal'), journalData);
  
//         remainingBalance = endingBalance;
        
//       }
//       // setData(loanData);
//     } catch (error) {
//       console.error('Error calculating loan details:', error);
//     }
//   };


//   const formatNumber = (number) => {
//     return number?.toLocaleString();
//   };

//   const handleRefresh = () =>{
//     fetchData();
//   }

//   const renderDateRequested = (timestamp) => {
//     if (timestamp && timestamp.seconds) {
//       const date = new Date(timestamp.seconds * 1000);
//       const formattedDate = date.toLocaleString("en-US", {
//         year: "numeric",
//         month: "numeric",
//         day: "numeric",
//       });
//       return formattedDate;
//     }
//     return null;
//   };
  
//   const columns = [
//     {
//       title: 'Payment Date',
//       dataIndex: 'paymentDate',
//       key: 'paymentDate',
//       align: 'center',
//       render: renderDateRequested,
//     },
//     {
//       title: 'Beginning Balance',
//       dataIndex: 'beginningBalance',
//       key: 'beginningBalance',
//       align: 'center',
//       render: (text) => formatNumber(text),
//     },
//     {
//       title: 'Payment',
//       dataIndex: 'payment',
//       key: 'payment',
//       align: 'center',
//       render: (text) => formatNumber(text),
//     },
//     {
//       title: 'Principal',
//       dataIndex: 'principal',
//       key: 'principal',
//       align: 'center',
//       render: (text) => formatNumber(text),
//     },
//     {
//       title: 'Interest',
//       dataIndex: 'interest',
//       key: 'interest',
//       align: 'center',
//       render: (text) => formatNumber(text),
//     },
//     {
//       title: 'Ending Balance',
//       dataIndex: 'endingBalance',
//       key: 'endingBalance',
//       align: 'center',
//       render: (text) => formatNumber(text),
//     },
//   ];

//   return (
//     <div>
//       <Widget>
//       <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
//       <ButtonGroup>
//                 <Click color="primary" style={{ marginRight: '2px' }} onClick={handleRefresh} size='sm'>
//                   Update Armotization
//                 </Click>
//                 <Click color="success" style={{ marginRight: '2px' }}size='sm'>
//                   CSV
//                 </Click>
//                 <Click color="success" style={{ marginRight: '2px' }} size='sm'>
//                   PDF
//                 </Click>
//                 <ReactToPrint
//                       trigger={() => (
//                         <Click color="success" disabled={printing} size='sm'>
//                          {printing ? <Spinner size="sm" style={{ color: 'white' }}/> : <i className="fa fa-print" aria-hidden="true"></i>}
//                         </Click>
//                       )}
//                       content={() => componentRef.current} // Specify the component to be printed
//                       onBeforeGetContent={() => setPrinting(true)}
//                       onAfterPrint={() => setPrinting(false)}
//                     />
//             </ButtonGroup>
//           </div>
//         <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
//         <Table columns={columns} loading={loading} dataSource={data} size='small' />
//         </div>
//       </Widget>
//     </div>
//   );
// };

// export default UserCurrentLoanArmotization;


import React, { useRef, useState, useEffect } from 'react';
import { Table } from 'antd';
import { Spinner,Input as InputData} from 'reactstrap';
import Widget from '../../../../components/Widget/Widget';
import { fetchFirebaseConfig } from '../../../../firebase';
import { collection, getDocs, query, where, getDoc, doc, updateDoc, serverTimestamp, onSnapshot, addDoc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print'; 
import { useHistory,Link,useParams } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import { ButtonIcon, TextButton } from '../../../../components/Buttons/CustomButtons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const UserCurrentLoanArmotization = () => {
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [annualInterestRate, setAnnualInterestRate] = useState(''); // Default value
  const [loanTermMonths, setLoanTermMonths] = useState(''); // Default value
  const [loanPrinciple, setLoanPrinciple] = useState(''); // Default value
  const [userData, setUserData] = useState('');
  const [userName, setUserName] = useState('');


  const {id} = useParams();

  const componentRef = useRef(null);

  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        if (!id) {
          console.error('UID is undefined');
          return;
        }
        const userDocRef = doc(db, 'Loans', id);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          const loanPrinciple = userData.amountRemaining || userData.loanAmount1;
          const loanTermMonths = userData.repaymentMonths || 0;
          const annualInterestRate = userData.interestRate || 0;
  
          setLoanPrinciple(loanPrinciple);
          setLoanTermMonths(loanTermMonths);
          setAnnualInterestRate(annualInterestRate);
  
          if (userData.user) {
            const userId = userData.user.id;
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
              const firstName = userDoc.data().display_name;
              const midName = userDoc.data().mid_name;
              const sirName = userDoc.data().sir_name;
              const userFullName = `${firstName} ${midName ? midName.charAt(0).toUpperCase() + '. ' : ''}${sirName || ''}`.trim();
              console.log('username', userFullName);
              setUserData({
                firstName,
                midName,
                sirName,
              });
              setUserName(userFullName)
            } else {
              console.log('User document not found');
            }
          } else {
            console.log('User reference not found');
          }
  
          // Call fetchPayments with the updated values
          fetchPayments(loanPrinciple, annualInterestRate, loanTermMonths);
        } else {
          console.log('Loan document not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchLoanData();
  }, [db, id]);


  const fetchPayments = async (loanPrinciple,annualInterestRate,loanTermMonths) => {
    try {
        if (!id) {
            console.error('UID is undefined');
            return;
        }

        const paymentsQuery = query(
            collection(db, 'allTransaction2'),
            where('transactionType', '==', 'Loan Payment'),
            where('approvalStatus', '==', 'Approved'),
            where('transactionId', '==', id)
        );

        const paymentsSnapshot = await getDocs(paymentsQuery);
        const payments = [];

        console.log('before', payments)

        console.log('principle', loanPrinciple);
        console.log('interest', annualInterestRate);
        console.log('terms', loanTermMonths);

        paymentsSnapshot.forEach((doc) => {
            const paymentData = doc.data();
            if (!paymentData.hasOwnProperty('armotized') || paymentData.armotized !== 'yes') {
                payments.push({
                    id: doc.id,
                    amount: paymentData.transactionAmount,
                    date: paymentData.transactionDate,
                });
            }
        });

        console.log('After', payments)

        if (payments.length > 0 && (loanPrinciple && annualInterestRate && loanTermMonths)) {
            await calculateLoanDetails(loanPrinciple, annualInterestRate, loanTermMonths, payments);
        }else{
          return null
        }
    } catch (error) {
        console.error('Error processing payments:', error);
    }
};

const fetchData = async () => {
  setLoading(true);
    try {
        if (!id) {
            console.error('UID is undefined');
            return;
        }

       // Query payments where loanAmortization is equal to a specific ID
          const paymentsQuery = query(
            collection(db, 'Amortizations'),
            where('loanAmortization', '==', id)
          );

          // Get the snapshot of the documents returned by the query
          const paymentsSnapshot = await getDocs(paymentsQuery);

          // Use Promise.all to process each document asynchronously
          const paymentsAmortizations = await Promise.all(
          paymentsSnapshot.docs.map(async (doc) => {
            const paymentData = doc.data();
            return {
              id: doc.id,
              paymentDate: paymentData.paymentDate, // Assuming paymentDate is stored as a string
              beginningBalance: paymentData.beginningBalance,
              payment: paymentData.payment,
              principal: paymentData.principal,
              interest: paymentData.interest,
              endingBalance: paymentData.endingBalance,
            };
          })
          );

          const sortedNewLoansData = paymentsAmortizations.sort((b,a) => b.paymentDate.seconds - a.paymentDate.seconds);

        setData(sortedNewLoansData);
        setLoading(false);
    } catch (error) {
        console.error('Error processing payments amortizations:', error);
    }
};

useEffect(() => {
fetchData();
}, [db, id]);

  const updateAssetsBalance = async (user, loanSchedual, principalPortion) => {
    try {
      const assetsQuery = query(
        collection(db, 'Assets'),
        where('user', '==', user),
        where('loanType', '==', loanSchedual)
      );
      const assetsSnapshot = await getDocs(assetsQuery);
  
      if (!assetsSnapshot.empty) {
        const assetDoc = assetsSnapshot.docs[0];
        const assetData = assetDoc.data();
  
        // Find the latest balance entry by date
        const balances = assetData.balances || [];
        let latestBalanceEntry = null;
        let latestBalanceIndex = -1;
        balances.forEach((balanceEntry, index) => {
          if (
            !latestBalanceEntry ||
            (balanceEntry.date && balanceEntry.date.serverTimestamp && balanceEntry.date.serverTimestamp.seconds > latestBalanceEntry.date.serverTimestamp.seconds)
          ) {
            latestBalanceEntry = balanceEntry;
            latestBalanceIndex = index;
          }
        });
  
        if (latestBalanceEntry) {
          const currentBalance = latestBalanceEntry.balance || 0;
          const currentCreditBalance = latestBalanceEntry.creditBalance || 0;
          const currentDebitBalance = latestBalanceEntry.debitBalance || 0;

          const updatedBalance = currentBalance - principalPortion;
          const newCreditBalance = currentCreditBalance + principalPortion; // No change for debits
          const newDebitBalance = currentDebitBalance
  
          // Update the latest balance entry in balances array
          balances[latestBalanceIndex].balance = updatedBalance;
          balances[latestBalanceIndex].creditBalance = newCreditBalance;
          balances[latestBalanceIndex].debitBalance = newDebitBalance;
          // balances[latestBalanceIndex].date = new Date(); // Update date for tracking
  
          await updateDoc(doc(db, 'Assets', assetDoc.id), { balances });
        } else {
          console.error(`No balance entries found for user '${user}' and loan type '${loanSchedual}'.`);
        }
      }
    } catch (error) {
      console.error('Error updating asset balance:', error);
    }
  };
  
  
  const calculateLoanDetails = async (loanAmount, annualInterestRate, loanTermMonths, payments) => {
    try {
      const accountsSnapshot = await getDocs(query(collection(db, 'PrincipleInterestAccounts'), where('company_id', '==', 'NCAAWS')));
  
      if (accountsSnapshot.empty) {
        throw new Error('No account information found for the specified company_id');
      }
  
      const accountDoc = accountsSnapshot.docs[0];
      const accountData = accountDoc.data();
  
      console.log('principle', accountData.principleAccount);
      console.log('principleCode', accountData.principleAccountCode);
      console.log('interest', accountData.interestAccount);
      console.log('interestCode', accountData.interestAccountCode);
      console.log('interest reference', accountData.interestAccountReference);
      console.log('interest reference Code', accountData.interestAccountReferenceCode);
  
      const monthlyInterestRate = (annualInterestRate / 12) / 100;
      const monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow((1 + monthlyInterestRate), -loanTermMonths));
  
      let remainingBalance = loanAmount;
      const loanData = [];
      const collections = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
  
      for (const payment of payments) {
        const interestPortion = remainingBalance * monthlyInterestRate;
        const principalPortion = payment.amount - interestPortion;
        const endingBalance = remainingBalance - principalPortion;

         const userLoanDocRef = doc(db, 'Loans', id);
         const userLoanDocSnapshot = await getDoc(userLoanDocRef);

          const userLoanData = userLoanDocSnapshot.data();
          const userRef = userLoanData.user
  
        // Add document to 'Amortizations' collection
        await addDoc(collection(db, 'Amortizations'), {
            paymentDate:payment.date, // Check if payment.date is a valid Date object
            beginningBalance: parseFloat(remainingBalance),
            payment: parseFloat(payment.amount),
            principal: parseFloat(principalPortion),
            interest: parseFloat(interestPortion),
            endingBalance: parseFloat(endingBalance),
            loanAmortization:id,
            user:userRef
        });

        await updateDoc(doc(db, 'allTransaction2', payment.id), { armotized: 'yes' });

        // Update the amountRemaining field in the Loans document
        await updateDoc(userLoanDocRef, { amountRemaining: endingBalance });
        await updateDoc(userLoanDocRef,{ amountPaid: principalPortion });

    
        remainingBalance = endingBalance;
  
        // Update principal account balance
        for (const collectionName of collections) {
          const collectionRef = collection(db, collectionName);
          const principalDocRef = query(collectionRef, where('account_name', '==', accountData.principleAccount));
          const principalSnapshot = await getDocs(principalDocRef);
  
          if (!principalSnapshot.empty) {
            const principalDoc = principalSnapshot.docs[0];
            const principalData = principalDoc.data();
  
            // Find the latest balance entry by date
            const balances = principalData.balances || [];
            let latestBalanceEntry = null;
            let latestBalanceIndex = -1;
  
            balances.forEach((balanceEntry, index) => {
              if (
                !latestBalanceEntry ||
                (balanceEntry.date && balanceEntry.date.serverTimestamp && balanceEntry.date.serverTimestamp.seconds > latestBalanceEntry.date.serverTimestamp.seconds)
              ) {
                latestBalanceEntry = balanceEntry;
                latestBalanceIndex = index;
              }
            });
  
            if (latestBalanceEntry) {
              const currentBalance = latestBalanceEntry.balance || 0;
              const currentCreditBalance = latestBalanceEntry.creditBalance || 0;
              const currentDebitBalance = latestBalanceEntry.debitBalance || 0;
  
              // Determine which balance to update based on collection type
              let newCreditBalance = currentCreditBalance;
              let newDebitBalance = currentDebitBalance;
              if (collectionName === 'Assets' || collectionName === 'Expenses') {
                newDebitBalance += principalPortion;
              } else {
                newCreditBalance += principalPortion;
              }
  
              const updatedPrincipalBalance = currentBalance + principalPortion;
  
              // Update the latest balance entry in balances array
              balances[latestBalanceIndex].balance = updatedPrincipalBalance;
              balances[latestBalanceIndex].creditBalance = newCreditBalance;
              balances[latestBalanceIndex].debitBalance = newDebitBalance;
  
              await updateDoc(doc(db, collectionName, principalDoc.id), { balances });
            } else {
              console.error(`No balance entries found for account '${accountData.principleAccount}'.`);
            }
  
            break;
          }
        }
  
        // Update interest account balance
        for (const collectionName of collections) {
          const collectionRef = collection(db, collectionName);
          const interestDocRef = query(collectionRef, where('account_name', '==', accountData.interestAccount));
          const interestSnapshot = await getDocs(interestDocRef);
  
          if (!interestSnapshot.empty) {
            const interestDoc = interestSnapshot.docs[0];
            const interestData = interestDoc.data();
  
            // Find the latest balance entry by date
            const balances = interestData.balances || [];
            let latestBalanceEntry = null;
            let latestBalanceIndex = -1;
  
            balances.forEach((balanceEntry, index) => {
              if (
                !latestBalanceEntry ||
                (balanceEntry.date && balanceEntry.date.serverTimestamp && balanceEntry.date.serverTimestamp.seconds > latestBalanceEntry.date.serverTimestamp.seconds)
              ) {
                latestBalanceEntry = balanceEntry;
                latestBalanceIndex = index;
              }
            });
  
            if (latestBalanceEntry) {
              const currentBalance = latestBalanceEntry.balance || 0;
              const currentCreditBalance = latestBalanceEntry.creditBalance || 0;
              const currentDebitBalance = latestBalanceEntry.debitBalance || 0;
  
              const updatedInterestBalance = currentBalance + interestPortion;
  
              // Determine which balance to update based on collection type
              let newCreditBalance = currentCreditBalance;
              let newDebitBalance = currentDebitBalance;
              if (collectionName === 'Liabilities' || collectionName === 'Expenses' || collectionName === 'Income') {
                newCreditBalance += interestPortion;
              } else {
                newDebitBalance += interestPortion;
              }
  
              // Update the latest balance entry in balances array
              balances[latestBalanceIndex].balance = updatedInterestBalance;
              balances[latestBalanceIndex].creditBalance = newCreditBalance;
              balances[latestBalanceIndex].debitBalance = newDebitBalance;
  
              await updateDoc(doc(db, collectionName, interestDoc.id), { balances });
            } else {
              console.error(`No balance entries found for account '${accountData.interestAccount}'.`);
            }
  
            break;
          }
        }
  
        // Update interest account reference balance
        for (const collectionName of collections) {
          const collectionRef = collection(db, collectionName);
          const interestRefDocRef = query(collectionRef, where('account_name', '==', accountData.interestAccountReference));
          const interestRefSnapshot = await getDocs(interestRefDocRef);
  
          if (!interestRefSnapshot.empty) {
            const interestRefDoc = interestRefSnapshot.docs[0];
            const interestRefData = interestRefDoc.data();
  
            // Find the latest balance entry by date
            const balances = interestRefData.balances || [];
            let latestBalanceEntry = null;
            let latestBalanceIndex = -1;
  
            balances.forEach((balanceEntry, index) => {
              if (
                !latestBalanceEntry ||
                (balanceEntry.date && balanceEntry.date.serverTimestamp && balanceEntry.date.serverTimestamp.seconds > latestBalanceEntry.date.serverTimestamp.seconds)
              ) {
                latestBalanceEntry = balanceEntry;
                latestBalanceIndex = index;
              }
            });
  
            if (latestBalanceEntry) {
              const currentBalance = latestBalanceEntry.balance || 0;
              const currentCreditBalance = latestBalanceEntry.creditBalance || 0;
              const currentDebitBalance = latestBalanceEntry.debitBalance || 0;
  
              const updatedInterestRefBalance = currentBalance + interestPortion;
  
              // Determine which balance to update based on collection type
              let newCreditBalance = currentCreditBalance;
              let newDebitBalance = currentDebitBalance;
              if (collectionName === 'Liabilities' || collectionName === 'Expenses' || collectionName === 'Income') {
                newCreditBalance += interestPortion;
              } else {
                newDebitBalance += interestPortion;
              }
  
              // Update the latest balance entry in balances array
              balances[latestBalanceIndex].balance = updatedInterestRefBalance;
              balances[latestBalanceIndex].creditBalance = newCreditBalance;
              balances[latestBalanceIndex].debitBalance = newDebitBalance;
  
              await updateDoc(doc(db, collectionName, interestRefDoc.id), { balances });
            } else {
              console.error(`No balance entries found for account '${accountData.interestAccountReference}'.`);
            }
  
            break;
          }
        }
        
        // Update the corresponding asset balance
        const userDocRef = doc(db, 'Loans', id);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        await updateAssetsBalance(userData.user, userData.loanSchedual, principalPortion, collections);

          const userId = userData.user.id;
          const userDoc = await getDoc(doc(db, 'users', userId));
          const firstName = userDoc.data().display_name;
          const midName = userDoc.data().mid_name;
          const sirName = userDoc.data().sir_name;
          const userFullName = `${firstName} ${midName ? midName.charAt(0).toUpperCase() + '. ' : ''}${sirName || ''}`.trim();
          console.log('username', userFullName);
          setUserData({
              firstName,
              midName,
              sirName,
           });

        const paymentAmount = parseFloat(principalPortion) + parseFloat(interestPortion)

        // Example generateAccountCode function to generate a unique account code
      const generateAccountCode = () => {
        // Generate a random three-digit number and concatenate with "0"
        const randomDigits = Math.floor(Math.random() * 900) + 100; // Generates a number between 100 and 999
        return `${randomDigits}0`;
        };

        const assetCollectionRef = collection(db, 'Assets');
        const assetQuery = query(assetCollectionRef, where('account_name', '==', userFullName), where('loanType', '==', 'Long Term Loan'));
        const assetSnapshot = await getDocs(assetQuery);

        const assetDoc = assetSnapshot.docs[0];
        const assetData = assetDoc.data();
        const creditAccountCode = assetData.account_code;
 
        const transferCode = `RT${generateAccountCode()}`

        const journalData = {
          journalCode: transferCode, // Replace with your actual journal code
          description: 'Reducing Payment Transfer',
          date: new Date(), // Replace with your actual date format
          total_credit: paymentAmount,
          total_debit: paymentAmount,
          transactionType: 'Reducing Entry',
          entries: [
              // {
              //     debit_account: accountData.principleAccount,
              //     debit_account_code:accountData.principleAccountCode,
              //     credit_account: '',
              //     debit_amount: principalPortion,
              //     credit_amount: 0,
              //     double_entry_description: 'Long Term principal payment to Loan Account',
              // },
              {
                debit_account: accountData.interestAccountReference,
                debit_account_code:accountData.interestAccountReferenceCode,
                credit_account: '',
                debit_amount: payment.amount ,
                credit_amount: 0,
                double_entry_description: 'Transfer Payment Interest to Bank Account',
              },
              {
                  debit_account: '',
                  credit_account:accountData.interestAccount,
                  credit_account_code:accountData.interestAccountCode,
                  debit_amount: 0,
                  credit_amount: interestPortion,
                  double_entry_description: 'Transfer Amortization Interest from Account',
              },
              {
                debit_account: '',
                credit_account:userFullName,
                credit_account_code:creditAccountCode,
                debit_amount: 0,
                credit_amount:principalPortion,
                double_entry_description: 'Long Term Loan principal payment',
            },
          ],
      };

      await addDoc(collection(db, 'Journal'), journalData);
  
        remainingBalance = endingBalance;
        
      }
      // setData(loanData);
    } catch (error) {
      console.error('Error calculating loan details:', error);
    }
  };

  const formatNumber = (number) => {
    return number?.toLocaleString();
  };

  const handleRefresh = () =>{
    fetchData();
  }

  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };
  
  const columns = [
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      render: renderDateRequested,
    },
    {
      title: 'Beginning Balance',
      dataIndex: 'beginningBalance',
      key: 'beginningBalance',
      align: 'center',
      render: (text) => formatNumber(text),
    },
    {
      title: 'Payment',
      dataIndex: 'payment',
      key: 'payment',
      align: 'center',
      render: (text) => formatNumber(text),
    },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
      align: 'center',
      render: (text) => formatNumber(text),
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      align: 'center',
      render: (text) => formatNumber(text),
    },
    {
      title: 'Ending Balance',
      dataIndex: 'endingBalance',
      key: 'endingBalance',
      align: 'center',
      render: (text) => formatNumber(text),
    },
  ];

  return (
    <div>
      <Widget>
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ButtonGroup size='sm'>
                <TextButton label=' Update Armotization' style={{ marginRight: '2px' }} size='sm' onClick={handleRefresh}/>
                <TextButton label='CSV'  size='sm'/>
                <TextButton label='PDF'  size='sm'/>
                <ReactToPrint
                      trigger={() => (
                        <ButtonIcon icon={faPrint} size="sm" disabled={printing} borderRadius={false}/>
                      )}
                      content={() => componentRef.current} // Specify the component to be printed
                      onBeforeGetContent={() => setPrinting(true)}
                      onAfterPrint={() => setPrinting(false)}
                    />
            </ButtonGroup>
          </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
        <Table columns={columns} loading={loading} dataSource={data} size='small' />
        </div>
      </Widget>
    </div>
  );
};

export default UserCurrentLoanArmotization;






