import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { collection, query, where, getDocs, addDoc, setDoc, serverTimestamp, doc } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import { Breadcrumb, Select, Space } from 'antd';
import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';

const CreateCategoryLoan = () => {
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [loanPeriod1, setLoanPeriod1] = useState('');
  const [loanPeriod2, setLoanPeriod2] = useState('');
  const [loanLimitRate, setLoanLimitRate] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTypes, setLoanTypes] = useState([]); // State to store loan types
  const [subSectorOptions, setSubSectorOptions] = useState([]); // State to store sub-sector options
  const [selectedLoanType, setSelectedLoanType] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedInterestMethod, setSelectedInterestMethod] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedSubSectors, setSelectedSubSectors] = useState([]);

  const { db } = fetchFirebaseConfig();

  const sectorSubsectorMapping = {
    Manufacturing: [
      { label: 'Food processing', value: 'Food processing' },
      { label: 'Animal Feed processing', value: 'Animal Feed processing' },
      { label: 'Carpentry', value: 'Carpentry' },
      { label: 'Textile and Leather products', value: 'Textile and Leather products' },
      { label: 'Others', value: 'Others' },
    ],
    Services: [
      { label: 'Education & health', value: 'Education & health' },
      { label: 'Financial & Business', value: 'Financial & Business' },
      { label: 'Transport & Communication', value: 'Transport & Communication' },
      { label: 'Housing & Finance', value: 'Housing & Finance' },
      { label: 'Micro Insurance', value: 'Micro Insurance' },
      { label: 'Micro Leasing', value: 'Micro Leasing' },
      { label: 'Others', value: 'Others' },
    ],
    'Trade and Commerce': [
      { label: 'Wholesale & Retail Stores', value: 'Wholesale & Retail Stores' },
      { label: 'Medical Store', value: 'Medical Store' },
      { label: 'Hotel & Restaurants', value: 'Hotel & Restaurants' },
      { label: 'Electricity & Gas', value: 'Electricity & Gas' },
      { label: 'Others', value: 'Others' },
    ],
    Agriculture: [
      { label: 'Crop production', value: 'Crop production' },
      { label: 'Animal production', value: 'Animal production' },
      { label: 'Forestry', value: 'Forestry' },
      { label: 'Fishing', value: 'Fishing' },
      { label: 'Hunting', value: 'Hunting' },
      { label: 'Others', value: 'Others' },
    ],
    'Personal Loan': [
      { label: 'Consumer', value: 'Consumer' },
      { label: 'Others', value: 'Others' },
    ],
  };

  useEffect(() => {
    const fetchLoanTypes = async () => {
      try {
        const loansTypeSnapshot = await getDocs(
          query(collection(db, 'companyLoans'), where('companyID', '==', 'DSP'))
        );

        if (!loansTypeSnapshot.empty) {
          const loanTypesData = loansTypeSnapshot.docs.map(doc => ({
            value: doc.data().name,
            label: doc.data().name,
          }));
          setLoanTypes(loanTypesData);
        } else {
          console.error('No documents found for companyID equal to "DSP"');
        }
      } catch (error) {
        console.error('Error fetching loan types:', error);
      }
    };

    fetchLoanTypes();
  }, [db]);

  const handleSave = async () => {
    try {
      // Check if required fields are set
      if (!selectedSector || !selectedLoanType || !selectedInterestMethod || !interestRate || !loanLimitRate || !maxAmount || !minAmount || !loanPeriod1 || !loanPeriod2) {
        toast.error("Please fill in all required fields.");
        return;
      }

      setIsSubmitting(true)
  
      // Create a new document in the LoansType collection
      const loanTypeDocRef = await addDoc(collection(db, 'LoansType'), {
        companyID: 'DSP',
        name: selectedSector,
        updatedAt: serverTimestamp(),
      });
  
      // Create a new document in the LoanTypeCategory sub-collection
      const loanTypeCategoryDocRef = doc(collection(db, `LoansType/${loanTypeDocRef.id}/LoanTypeCategory`));
      await setDoc(loanTypeCategoryDocRef, {
        LoanTypeName: selectedSector,
        LoanTypeRef: loanTypeDocRef.path,
        Term: selectedLoanType,
        interestMethod: selectedInterestMethod,
        interestRate: Number(interestRate),
        loanLimitRate: Number(loanLimitRate),
        maxAmount: Number(maxAmount),
        minAmount: Number(minAmount),
        periodInMonths1: Number(loanPeriod1),
        periodInMonths2: Number(loanPeriod2),
        sectorName: {
          [selectedSector]: selectedSubSectors
        },
        updatedAt: serverTimestamp(),
      });
  
      // Create a new document in the LoanSector collection
      await addDoc(collection(db, 'LoanSector'), {
        LoanTypeName: selectedSector,
        LoanTypeRef: loanTypeDocRef.path,
        sectorName: {
          [selectedSector]: selectedSubSectors
        },
        updatedAt: serverTimestamp(),
      });

      setIsSubmitting(false)
  
      toast.success(
        <div>
          <i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
          &nbsp;&nbsp; Settings Created
        </div>
      );
    } catch (error) {
      console.error('Error creating settings:', error);
      toast.error("Error creating settings. Please try again.");
    }
  };
  
 

  const onSectorChange = (value) => {
    setSelectedSector(value);
    setSubSectorOptions(sectorSubsectorMapping[value] || []);
    console.log(`selected ${value}`);
  };

  const onSubSectorChange = (value) => {
    setSelectedSubSectors(value); // Update selected sub-sectors state
    console.log(`selected ${value}`);
  };

  const onLoanTypeChange = (value) => {
    setSelectedLoanType(value);
    console.log(`selected ${value}`);
  };


  const onInterestMethodChange = (value) => {
    setSelectedInterestMethod(value);
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
    <div>
        <Row>
          <Col xs={12}>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maxAmount">Loan Type:</Label>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select Loan Type"
                      optionFilterProp="label"
                      onChange={onLoanTypeChange}
                      onSearch={onSearch}
                      options={loanTypes} // Use state variable for options
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maxAmount">Max Loan Amount:</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="maxAmount"
                      name="maxAmount"
                      placeholder="Max Loan Amount"
                      value={maxAmount}
                      onChange={(e) => setMaxAmount(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="minAmount">Min Loan Amount:</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="minAmount"
                      name="minAmount"
                      placeholder="Min Loan Amount"
                      value={minAmount}
                      onChange={(e) => setMinAmount(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="minLoanPeriod">Max Loan Period (Months):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="loanPeriod1"
                      name="loanPeriod1"
                      placeholder="Loan Period"
                      value={loanPeriod1}
                      onChange={(e) => setLoanPeriod1(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="minLoanPeriod">Min Loan Period (Months):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="loanPeriod2"
                      name="loanPeriod2"
                      placeholder="Loan Period"
                      value={loanPeriod2}
                      onChange={(e) => setLoanPeriod2(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="interestMethod">Interest Method:</Label>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select Interest Method"
                      optionFilterProp="label"
                      onChange={onInterestMethodChange}
                      onSearch={onSearch}
                      options={[
                        {
                          value: 'reducing',
                          label: 'Reducing',
                        },
                        {
                          value: 'flat',
                          label: 'Flat',
                        },
                      ]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="interestRate">Interest Rate (%):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="interestRate"
                      name="interestRate"
                      placeholder="Interest Rate"
                      value={interestRate}
                      onChange={(e) => setInterestRate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="loanLimitRate">Loan Limit Rate (%):</Label>
                    <Input
                      type="number"
                      style={{ borderRadius: '7px' }}
                      id="loanLimitRate"
                      name="loanLimitRate"
                      placeholder="Loan Limit Rate"
                      value={loanLimitRate}
                      onChange={(e) => setLoanLimitRate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="sector">Sector</Label>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Sector"
                      optionFilterProp="label"
                      onChange={onSectorChange}
                      onSearch={onSearch}
                      options={[
                        { value: 'Manufacturing', label: 'Manufacturing' },
                        { value: 'Services', label: 'Services' },
                        { value: 'Trade and Commerce', label: 'Trade and Commerce' },
                        { value: 'Agriculture', label: 'Agriculture' },
                        { value: 'Personal Loan', label: 'Personal Loan' },
                      ]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="subSector">SubSector</Label>
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Select sub categories"
                      onChange={onSubSectorChange}
                      options={subSectorOptions} // Use state variable for options
                    />
                  </FormGroup>
                </Col>
              </Row>
              <TextButton label="Create" onClick={handleSave} isLoading={isSubmitting} loadingLabel='Creating' isBorderRadius={true}/>
            </Form>
          </Col>
        </Row>
    </div>
  );
};

export default CreateCategoryLoan;





