import React, { useState,useEffect } from 'react';
import {
  Col,
} from 'reactstrap';
import Widget from '../../../components/Widget/Widget';
import CountUp from 'react-countup';
import { getTotalSharesDisbursements,getTotalSharesPayments } from './data';
import StatsCard from '../../../components/StatsCard/statsCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { faCreditCard, faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SharesWidgets = () => {
  const [totalSharesDisbursed, setTotalSharesDisbursed] = useState(0);
  const [totalSharesPayments, setTotalSharesPayments] = useState(0);

  const history = useHistory();


  useEffect(() => {
    const fetchData = async () => { // Set loading to true when fetching data
      try {
        const sharesDisbursements = await getTotalSharesDisbursements();
        setTotalSharesDisbursed(sharesDisbursements);
        const sharesPayments = await getTotalSharesPayments();
        setTotalSharesPayments(sharesPayments);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
     <Col sm={12} md={6}>
      <StatsCard
        title="Shares Payments"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalSharesPayments} duration={2} separator="," />}</div>}
        icon={<FontAwesomeIcon icon={faCreditCard}/>} 
        color="#28d456"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
        <Col sm={12} md={6}>
       <StatsCard
        title="Shares Disbursements"
        value={<div style={{ fontWeight: 600 }}>{<CountUp end={totalSharesDisbursed} duration={2} separator="," />}</div>}
        icon={<FontAwesomeIcon icon={faMoneyCheckDollar}/>} 
        color="#4b3e75"// Example icon, replace with your desired icon
        showTSH={true}  // Show TSH label
       />
        </Col>
     </>
     )
    }
     export default SharesWidgets;