import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
// import { countUsers,getTotalApprovedLoanAmount,getTotalSavingsAmount,fetchActiveUsersCount } from '../widgets/data';
// import { fetchUserCountsByGender,fetchLoansDataAndPercentageByGender } from '../dashboard/data';

const SaccossGeoDistReports = () => {

const [userCount, setUserCount] = useState("loading...");
const [maleCount, setMaleCount] = useState("loading...");
const [femaleCount, setFemaleCount] = useState("loading...");
const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState("loading...");
const [totalSavings, setTotalSavings] = useState("loading...");
const [activeUsersCount, setActiveUsersCount] = useState("loading...");
const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState("loading...");
const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState("loading...");
const [loanData, setLoanData] = useState([]);
const [totalMaleLoanCount, setTotalMaleLoanCount] = useState("loading...");
const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState("loading...");

// useEffect(() => {
//   // Fetch the total savings amount when the component mounts
//   getTotalSavingsAmount()
//     .then((formattedTotalSavingsAmount) => {
//       setTotalSavings(formattedTotalSavingsAmount);
//     })
//     .catch((error) => {
//       console.error('Error fetching total savings:', error);
//     });
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const counts = await fetchUserCountsByGender(window.location);
//       setMaleCount(counts.maleCount);
//       setFemaleCount(counts.femaleCount);
//     } catch (error) {
//       console.error('Error fetching user counts:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const result = await fetchLoansDataAndPercentageByGender(window.location);
//       setLoanData(result.totalLoanData);
//       // setLoanPercentage(result.loanPercentage);
//       // setTotalLoanAmount(result.totalLoanAmount);
//       setTotalMaleLoanAmount(result.totalMaleLoanAmount);
//       setTotalFemaleLoanAmount(result.totalFemaleLoanAmount);
//       setTotalMaleLoanCount(result.totalMaleLoanCount);
//       setTotalFemaleLoanCount(result.totalFemaleLoanCount);
//     } catch (error) {
//       console.error('Error fetching loan data:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []); 

   const   handleSaccossGeoDistReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: 'NCAA WORKERS SAVINGS AND CREDIT COOPERATIVE SOCIETY LIMITED',
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','GEOGRAPHICAL DISTRIBUTION OF SACCOS, BRANCHES, EMPLOYEES AND LOANS BY AGE FOR THE MONTH ENDED: ']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 01: To be submitted Monthly for Category B and Quartely for Category A',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

const headerRow2e = worksheet.addRow(['Sno', 'Geographical Area','Number of Branches(Including SACCOSS HQ)','Number of Employees','','Number of Borrowers','','','','Number of Loans','','','','Outstanding Loans(TZS 0.00)','','']);
headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } }; // Light Blue background color
headerRow2e.font = { bold: true };
headerRow2e.height = 25;

// Create a new row to be inserted between headerRow2e and subLabelRow
const additionalRow = worksheet.addRow([
    ,'','','', '', 'Up to 35 Years', '', 'Above 35 Years','', 'Up to 35 Years', '', 'Above 35 Years','', 'Up to 35 Years', '', 'Above 35 Years','']);

// Style the new row if needed
additionalRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } }; // Yellow background color
additionalRow.font = { bold: true };

// Add a sub-row for the labels just above the subcolumns
const subLabelRow = worksheet.addRow(['', '','','','Female','Male','Female', 'Male','Female', 'Male','Female', 'Male','Female', 'Male','Female', 'Male',]);
subLabelRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } };
subLabelRow.font = { bold: true };


worksheet.addRow(['', 'A. Mainland Tanzania','', '', '', '', '', '', '', '', '', '', '', '', '', '']).font = { bold: true };
worksheet.addRow(['1', 'Arusha', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['2', 'Arusha CC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['3', 'Arusha DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['4', 'Karatu CC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['5', 'Longido DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['6', 'Meru','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['7', 'Monduli', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['8', 'Ngorongoro', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['9', 'Dar Es Salaam','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['10','Ilala MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['11', 'Kigamboni MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['12', 'Kinondoni MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['13', 'Ubungo MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['14', 'Temeke MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['15', 'Dodoma', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['16', 'Bahi DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['17', 'Chamwino DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['18', 'Chemba DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['19', 'Dodoma CC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['20', 'Kondoa DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['21', 'Kondoa TC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['22', 'Kongwa DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['23', 'Mpwapwa DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['24', 'Geita','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['25', 'Bukombe','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['26', 'Chato DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['27', 'Geita DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['28', 'Mbogwe DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['29', 'Iringa','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['30','Iringa DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['31', 'Iringa MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['32', 'Kilolo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['33', 'Mafinga TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['34', 'Mufindi DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['35', 'Kagera', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['36', 'Biharamulo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['37', 'Bukoba DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['38', 'Bukoba MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['40', 'Karagwe DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['41', 'Kyerwa DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['42', 'Missenyi- DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['43', 'Muleba DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['44', 'Ngara DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['45', 'Katavi','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['46', 'Mlele DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['47', 'Mpanda DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['48', 'Mpanda MC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['49', 'Mpimbwe DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['50', 'Nsimbo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['51','Kigoma','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['52', 'Buhigwe DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['53', 'Kakonko DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['54', 'Kasulu DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['55', 'Kasulu TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['56', 'Kibondo DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['57', 'Kigoma DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['58', 'Kigoma MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['59', 'Uvinza DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['60', 'Kilimanjaro','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['61', 'Hai DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['62', 'Moshi DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['63', 'Moshi MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['64', 'Mwanga DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['65', 'Rombo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['66', 'Same DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['67', 'Siha DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['68', 'Lindi', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['69', 'Kilwa DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['70','Lindi DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['71', 'Lindi MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['72', 'Liwale DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['73', 'Nachingwea DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['74', 'Ruangwa DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['75', 'Manyara', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['76', 'Babati DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['77', 'Babati TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['78', 'Hanang DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['79', 'Kiteto DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['80', 'Mbulu DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['81', 'Mbulu TC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['82', 'Mara','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['83', 'Simanjiro DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['84', 'Bunda DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['85', 'Bunda TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['86', 'Butiama DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['87', 'Musoma DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['88', 'Musoma MC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['89', 'Rorya DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['90','Serengeti DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['91', 'Tarime DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['92', 'Tarime TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['93', 'Mbeya','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['94', 'Busokelo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['95', 'Chunya DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['96', 'Kyela DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['97', 'Mbarali DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['98', 'Mbeya CC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['99', 'Mbeya DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['100','Rungwe DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['101', 'Morogoro', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['102', 'Gairo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['103', 'Ifakara TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['104', 'Kilombero DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['105', 'Kilosa DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['106', 'Morogoro DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['107', 'Morogoro MC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['108', 'Mvomero DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['109', 'Ulanga DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['110','Mtwara','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['111', 'Masasi DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['112', 'Masasi TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['113', 'Mtwara DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['114', 'Mtwara MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['115', 'Nanyamba TC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['116', 'Nanyumbu DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['117', 'Newala DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['118', 'Newala TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['119', 'Tandahimba DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['120', 'Mwanza', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['121', 'Buchosa DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['122', 'Ilemela MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['123', 'Kwimba DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['124', 'Magu DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['125', 'Misungwi DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['126', 'Sengerema DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['127', 'Ukerewe DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['128', 'Njombe', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['129', 'Ludewa DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['130','Makambako TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['131', 'Makete DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['132', 'Njombe DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['133', 'Njombe TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['134', 'Wangingombe DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['135', 'Pwani', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['136', 'Bagamoyo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['137', 'Chalinze DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['138', 'Kibaha DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['139', 'Kibaha TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['140', 'Kibiti DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['141', 'Kisarawe DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['142', 'Mafia DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['143', 'Mkuranga DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['144', 'Rufiji DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['145', 'Rukwa','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['146', 'Kalambo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['147', 'Nkasi DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['148', 'Sumbawanga DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['149', 'Sumbawanga MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['150','Ruvuma','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['151', 'Madaba DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['152', 'Mbinga DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['153', 'Mbinga TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['154', 'Namtumbo DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['155', 'Nyasa DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['156', 'Songea DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['157', 'Songea MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['158', 'Tunduru DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['159', 'Shinyanga','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['160', 'Kahama TC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['161', 'Kishapu DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['162', 'Msalala DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['163', 'Shinyanga DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['164', 'Shinyanga MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['165', 'Ushetu DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['166', 'Simiyu','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['167', 'Bariadi DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['168', 'Bariadi TC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['169', 'Busega DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['170','Itilima DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['171', 'Maswa DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['172', 'Meatu DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['173', 'Singida','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['174', 'Ikungi DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['175', 'Iramba DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['176', 'Itigi DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['177', 'Manyoni DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['178', 'Mkalama DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['179', 'Singida DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['180', 'Singida MC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['181', 'Songwe', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['182', 'Ileje DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['183', 'Mbozi DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['184', 'Momba DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['185', 'Songwe DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['186', 'Tunduma TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['187', 'Tabora', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['188', 'Igunga DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['189', 'Kaliua DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['190','Nzega DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['191', 'Nzega TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['192', 'Sikonge DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['193', 'Tabora DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['194', 'Tabora MC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['195', 'Urambo DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['196', 'Tanga','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
worksheet.addRow(['197', 'Bumbuli DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['198', 'Handeni DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['199', 'Handeni TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['200', 'Kilindi DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['201', 'Korogwe DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['202', 'Korogwe TC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['203', 'Lushoto DC', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['204', 'Mkinga Dc','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['205', 'Muheza DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['206', 'Pangani DC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
worksheet.addRow(['207', 'Tanga CC','0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'])
worksheet.addRow(['208', 'Grand Total', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']).font = { bold: true };
    
   
     // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

     // Add additional content
     worksheet.addRow([]); // Empty row to create space            
     worksheet.addRow(['','* Arrange regions in alphabetical order']).font = { bold: true };
     worksheet.addRow([]); // Empty row to create space  
     worksheet.addRow(['','* CC : City Council'])
     worksheet.addRow(['','* MC : Municipal'])
     worksheet.addRow(['','* DC: District Council'])
     worksheet.addRow(['','* TC: Town Council'])



    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'saccos_geographical_distribution.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleSaccossGeoDistReport,
    // ... (other data or functions you want to export)
  };
};

export default SaccossGeoDistReports;