import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback, // Import FormFeedback component
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { doc, getDoc } from 'firebase/firestore';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom'; 
import { getThemeColor } from '../../components/ThemeSetUp';

const ResetPassword = () => {
  const { uid } = useParams();
  const { db, auth } = fetchFirebaseConfig();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newEmail, setNewEmail] = useState(''); // New state for the new email input
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [error, setError] = useState(null);
  const [passwordMismatch, setPasswordMismatch] = useState(false); // State to track password mismatch
  const [showNewPassword, setShowNewPassword] = useState(false); // State to track new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to track confirm password visibility
  const [isSubmittingReset, setIsSubmittingReset] = useState(false);
  const [isSubmittingResetLink, setIsSubmittingResetLink] = useState(false);
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!uid) {
          console.error('UID is undefined');
          return;
        }

        const userDocRef = doc(db, 'users', uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setName(userData.display_name || '');
          setPosition(userData.position || 'User');
          setEmail(userData.email || '');
          setPassword(userData.password || '');

          // Autofill new email input with the initial email value
          setNewEmail(userData.email || '');
          // Autofill new password and confirm password inputs with the initial password value
          setNewPassword(userData.password || '');
          setConfirmPassword(userData.password || '');
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data');
      }
    };

    fetchUserData();

    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, [db, uid]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordMismatch(true); // Set password mismatch state to true
      return;
    } else {
      setPasswordMismatch(false); // Reset password mismatch state if passwords match
    }

    setIsSubmittingReset(true)

    // Log the JSON data before sending the request
    // console.log('JSON data:', { userId: uid, newPassword, newEmail });

    try {
      const functionUrl = 'https://us-central1-ncaasaccos-da7d4.cloudfunctions.net/resetPassword/resetPassword'; // Replace this with your Cloud Function endpoint
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: uid, newPassword, newEmail }), // Include newEmail in the JSON payload
      });

      if (response.ok) {
        toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Credentials updated successfully</div>);
      } else {
        const responseData = await response.json();
        setError(responseData.error || 'Error updating password. Please try again later.');
        toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Credentials updated successfully</div>);
      }
      setIsSubmittingReset(false)
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error updating credentials</div>);
    }
  };

  const handleResetLinkClick = async () => {
    setIsSubmittingResetLink(true)
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Email link sent successfully</div>);
      setIsSubmittingResetLink(false)
    } catch (error) {
      console.error('Error sending password reset email:', error);
      toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error sending password link</div>);
    }
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Members</Breadcrumb.Item>
        <Breadcrumb.Item>View Members</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Paasword & Email Settings</span></Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">Update {position}'s Password & Email</h4>
      <Row>
        <Col xs={8}>
          <WidgetShadow>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" id="email" name="email" value={email} placeholder="Email" disabled style={{ borderRadius: '7px' }} />
              </FormGroup>
              <FormGroup>
                <Label for="prevpassword">Old password</Label>
                <Input type="password" id="prevpassword" name="prevpassword" value={password} placeholder="Old password" disabled style={{ borderRadius: '7px' }} />
              </FormGroup>
              <FormGroup>
                <Label for="newemail">New email</Label>
                <Input type="email" id="newemail" name="newemail" value={newEmail} placeholder="New email" onChange={(e) => setNewEmail(e.target.value)} style={{ borderRadius: '7px' }}/>
              </FormGroup>
              <FormGroup>
                <Label for="newpassword">New password</Label>
                <div style={{ position: 'relative' }}>
                  <Input type={showNewPassword ? 'text' : 'password'} id="newpassword" name="newpassword" value={newPassword} placeholder="New password" onChange={(e) => setNewPassword(e.target.value)} style={{ borderRadius: '7px' }}/>
                  <i className={`fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '12px', cursor: 'pointer', color: '#c0c0c0' }} onClick={() => setShowNewPassword(!showNewPassword)}></i>
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="confirmpassword">Confirm password</Label>
                <div style={{ position: 'relative' }}>
                  <Input type={showConfirmPassword ? 'text' : 'password'} id="confirmpassword" name="confirmpassword" value={confirmPassword} placeholder="Confirm password" onChange={(e) => setConfirmPassword(e.target.value)} invalid={passwordMismatch} style={{ borderRadius: '7px' }}/> {/* Add invalid prop based on passwordMismatch state */}
                  {passwordMismatch && <FormFeedback>Passwords do not match</FormFeedback>} {/* Show feedback if passwordMismatch is true */}
                  <i className={`fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '12px', cursor: 'pointer', color: '#c0c0c0'}} onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
                </div>
              </FormGroup>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                 <TextButton label='Reset' loadingLabel='Resetting' isLoading={isSubmittingReset} isBorderRadius={true} onClick={handleSubmit}/>
                <TextButton label='Send Reset Link' onClick={handleResetLinkClick} loadingLabel='Sending' isLoading={isSubmittingResetLink} isBorderRadius={true}/>
              </div>
            </Form>
          </WidgetShadow>
        </Col>
      </Row>
      {/* <ToastContainer position="top-right" autoClose={4000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{ fontSize:'13.5px'}}/> */}
    </div>
  );
};

export default ResetPassword;
