import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData,Modal, ModalHeader, ModalBody,Form,ModalFooter} from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc, doc, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { getThemeColor } from '../../components/ThemeSetUp'; 

const Posts = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modal, setModal] = useState(false);
  const [isVisible14a, setIsVisible14a] = useState(false);
  const [title, setTitle] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [themeColor, setThemeColor] = useState('');
  const [decription, setDescription] = useState('');

  const toggle = () => setModal(!modal);

  const componentRef = useRef(null);

  const fetchPostsAccounts = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      const querySnapshot = await getDocs(collection(db, 'posts'));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.user);
        const userData = userDoc.exists() ? userDoc.data() : null;
        return {
          ...item,
          id: item.id,
          member: userData ? userData.display_name : '',
        };
      }));

      // Sort the data based on the PayoutDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.date.seconds - a.date.seconds);

      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

    useEffect(() => {
    fetchPostsAccounts();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  const handlePost = async () => {
    setIsSubmitting(true)
    const currentAuth = firebase.auth().currentUser;

    if (!currentAuth) {
      console.error('No authenticated user found');
      return;
    }

    try {
      const { db } = fetchFirebaseConfig();

      // Reference to 'posts' collection
      const postsCollection = collection(db, 'posts');

      // Create new post document
      await addDoc(postsCollection, {
        title: decription, // Assuming titleInputRef is a ref to the title input element
        content: title, // Assuming contentInputRef is a ref to the content input element
        date: serverTimestamp(),
        user: doc(db, 'users', currentAuth.uid) // Reference to current authenticated user's document
      });

      toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Post created successfully</div>);
      fetchPostsAccounts();
      setIsSubmitting(false)
      toggle();
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error('Failed to create post.');
      toast.error(<div><i className="fa fa-exclamation" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; Error while creating post</div>);
    }
  };

  const fetchVisibility = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users');
      const userDocRef = doc(usersCollection, user.uid);
      
      // Use onSnapshot to listen for changes to user permissions
      const unsubscribe = onSnapshot(userDocRef, async (userDocSnap) => {
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPosition = userData.position;
          const permissionsCollection = collection(db, 'permissions');
          const q = query(permissionsCollection, where('Role', '==', userPosition));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const permissionDocSnap = querySnapshot.docs[0];
            const permissionData = permissionDocSnap.data();
            const accessArray = permissionData.acess || [];
            setIsVisible14a(accessArray.includes(14.1));
          } else {
            setIsVisible14a(false);
          }
        }
      });
      
      return () => {
        // Unsubscribe from onSnapshot when component unmounts
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  fetchVisibility();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleFilter = async () => {
    setLoadingData(true);
    try {
      const { db } = fetchFirebaseConfig();
      const querySnapshot = await getDocs(query(collection(db, 'posts'), 
        where('date', '>=', new Date(startDate)),
        where('date', '<=', new Date(endDate))
      ));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.user);
        const userData = userDoc.exists() ? userDoc.data() : null;
        return {
          ...item,
          id: item.id,
          member: userData ? userData.display_name : '',
        };
      }));

      // Sort the data based on the PayoutDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.date.seconds - a.date.seconds);

      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  const handleRedo = () => {
    fetchPostsAccounts();
    setEndDate('');
    setStartDate('');
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'From',
      dataIndex: 'member',
      key: 'member',
      align: 'center',
      ...getColumnSearchProps('member'),
      sorter: (a, b) => a.member - b.member,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      ...getColumnSearchProps('title'),
      sorter: (a, b) => a.title - b.title,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description',
      dataIndex: 'content',
      key: 'content',
      align: 'center',
      ...getColumnSearchProps('content'),
      sorter: (a, b) => a.content - b.content,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Posted On',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      sorter: (a, b) => a.date - b.date,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Posts</span></Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">View Posts</h5>
      <WidgetShadow
       title={<h7><span className="fw-semi-bold">Data Range</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
           <Col md={6}>
                    <FormGroup>
                      <Label for="exampleFirstName">Start Date</Label>
                      <InputData
                        id="exampleFirstName"
                        style={{ borderRadius: '7px' }}
                        name="startDate"
                        placeholder="Start Date"
                        type="date"
                        bsSize="sm"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleMiddleName">End Date</Label>
                      <InputData
                        id="endDate"
                        style={{ borderRadius: '7px' }}
                        name="middlename"
                        placeholder="End date"
                        type="date"
                        bsSize="sm"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <TextButton label='Search'  onClick={handleFilter} size='sm' isBorderRadius={true}/>
                  <div style={{ marginLeft: '10px' }}></div>
                  <TextButton label='Reset'  onClick={handleRedo} size='sm' isBorderRadius={true}/>
                </div>
            </WidgetShadow>
            <WidgetShadow>
            {isVisible14a && (
              <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>       
                <TextButton label='new post'  onClick={toggle} size='sm' isBorderRadius={true}/>
              </div>
              )}
              <div style={{ overflowX: 'auto', overflowY: 'auto' }} ref={componentRef}>
                <Table columns={columns} dataSource={data} loading={loadingData} size="small" />
              </div>
              <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader style={{border:'none'}} toggle={toggle}>New Post</ModalHeader>
                <ModalBody>
                <Widget>
                <Form>
                  <FormGroup>
                  <Label for="title" sm={2} > Title </Label>
                    <InputData
                      id="exampleEmail"
                      style={{ borderRadius: '7px' }}
                      name="title"
                      placeholder="post title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                  <Label for="title" sm={2}>Description</Label>
                    <InputData
                      id="title"
                      style={{ borderRadius: '7px' }}
                      name="title"
                      placeholder="post description"
                      type="textarea"
                      value={decription}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                </FormGroup>
              <div style={{ display: 'flex', justifyContent: 'flex-start',marginTop:'12px'}}>
               <TextButton label='Post'  onClick={handlePost}  disabled={ !decription || title} isLoading={isSubmitting} loadingLabel='Posting'/>{' '}
              <Click color="danger" onClick={toggle} style={{ marginLeft: '10px' }}>
                Cancel
              </Click>
              </div>
            </Form>
            </Widget>
          </ModalBody>
      </Modal>
      </WidgetShadow>
      <ToastContainer position="top-right" autoClose={4000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{ fontSize:'13.5px'}}/>
    </div>
  );
};

export default Posts;
