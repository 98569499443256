import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, serverTimestamp, addDoc } from 'firebase/firestore';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-toastify/dist/ReactToastify.css';
import { Select } from 'antd';
import { fetchFirebaseConfig } from '../../../firebase';
import { TextButton } from '../../../components/Buttons/CustomButtons';
import 'firebase/compat/auth'
import { showToast } from '../../../components/Toast/toast';
import { getThemeColor } from '../../../components/ThemeSetUp';
import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';

const AccountSettings = () => {
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedSavingsAccount, setSelectedSavingsAccount] = useState('');
  const [selectedSharesAccount, setSelectedSharesAccount] = useState('');
  const [selectedDepositsAccount, setSelectedDepositsAccount] = useState('');
  const [themeColor, setThemeColor] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loanAccounts, setLoanAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState({});

  const { db } = fetchFirebaseConfig();

  const history = useHistory();

  useEffect(() => {
    const fetchAccountNames = async () => {
      try {
        const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
        const data = {};

        const promises = tables.map(async (table) => {
          const collectionSnapshot = await getDocs(query(collection(db, table)));
          const accounts = [];
          const childAccountsBuffer = [];
          const grandchildBuffer = [];

          collectionSnapshot.docs.forEach((doc) => {
            const { account_name, account_level, parentAccount, account_code, IsMainAccount } = doc.data();

            if (IsMainAccount === 'No') {
              return;
            }

            if (account_level === 'parent') {
              accounts.push({ name: account_name, code: account_code, level: 1, children: [] });
            } else if (account_level === 'child') {
              childAccountsBuffer.push({ name: account_name, code: account_code, parentAccount });
            } else if (account_level === 'grandchild') {
              grandchildBuffer.push({ name: account_name, code: account_code, parentAccount });
            }
          });

          childAccountsBuffer.forEach((child) => {
            const parentIndex = accounts.findIndex(account => account.name === child.parentAccount);
            if (parentIndex !== -1) {
              accounts[parentIndex].children.push({ name: child.name, code: child.code, level: 2, children: [] });
            } else {
              accounts.push({ name: child.name, code: child.code, level: 1, children: [] });
            }
          });

          grandchildBuffer.forEach((grandchild) => {
            let grandchildAdded = false;
            accounts.forEach(parentAccount => {
              parentAccount.children.forEach(childAccount => {
                if (childAccount.name === grandchild.parentAccount) {
                  childAccount.children.push({ name: grandchild.name, code: grandchild.code, level: 3 });
                  grandchildAdded = true;
                }
              });
            });
          });

          const lowestLevelAccounts = [];
          accounts.forEach((account) => {
            if (account.children.length === 0) {
              lowestLevelAccounts.push(account);
            } else {
              account.children.forEach((child) => {
                if (child.children.length === 0) {
                  lowestLevelAccounts.push(child);
                } else {
                  child.children.forEach((grandchild) => {
                    lowestLevelAccounts.push(grandchild);
                  });
                }
              });
            }
          });

          return { table, accounts: lowestLevelAccounts };
        });

        const resolvedPromises = await Promise.all(promises);

        resolvedPromises.forEach(({ table, accounts }) => {
          data[table] = accounts;
        });
        console.log('Fetched account names:', data);
        setAccountOptions(data);
      } catch (error) {
        console.error('Error fetching account names:', error);
      }
    };

    const fetchData = async () => {
      try {
        await fetchAccountNames();

        // Fetch loan accounts
        const loanSnapshot = await getDocs(collection(db, 'companyLoans'));
        const loans = loanSnapshot.docs.map((doc) => doc.data());
        setLoanAccounts(loans);

        // Fetch account settings
        const querySnapshot = await getDocs(
          query(collection(db, 'AccountingSettings'), where('companyID', '==', 'DSP'))
        );

        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data();
          setSelectedSavingsAccount(docData.savingsAccount || '');
          setSelectedSharesAccount(docData.sharesAccount || '');
          setSelectedDepositsAccount(docData.depositsAccount || '');

          // Dynamically set loan account states
          const newSelectedAccounts = {};
          loans.forEach(loan => {
            const fieldName = `${loan.name.replace(/\s+/g, '')}Account`;
            newSelectedAccounts[fieldName] = docData[fieldName] || '';
          });
          setSelectedAccounts(newSelectedAccounts);
        } else {
          console.error('No document found for company_id equal to "DSP"');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, [db]);

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const querySnapshot = await getDocs(
        query(collection(db, 'AccountingSettings'), where('companyID', '==', 'DSP'))
      );

      const updateData = {
        savingsAccount: selectedSavingsAccount,
        sharesAccount: selectedSharesAccount,
        depositsAccount: selectedDepositsAccount,
        updated_At: serverTimestamp(),
        ...selectedAccounts  // Include all selected loan accounts
      };

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, updateData);
      } else {
        // Create a new document if none exists
        await addDoc(collection(db, 'AccountingSettings'), {
          companyID: 'DSP',
          ...updateData,
          created_At: serverTimestamp()
        });
      }
      showToast('fa fa-check', 'Accounts updated successfully!');
      console.log('Accounts updated successfully!');
    } catch (error) {
      console.error('Error updating accounts:', error);
      showToast('fa fa-times', 'Error updating accounts');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAccountChange = (value, fieldName) => {
    setSelectedAccounts((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const renderLoanInputs = () => {
    return loanAccounts.map((loan) => {
      const fieldName = `${loan.name.replace(/\s+/g, '')}Account`;
      return (
        <Col md={6} key={loan.name}>
          <FormGroup>
            <Label>{`${loan.name} Account:`}</Label>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select an account"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => handleAccountChange(value, fieldName)}
              value={selectedAccounts[fieldName]}
            >
              {optionsMemo}
            </Select>
          </FormGroup>
        </Col>
      );
    });
  };

  const renderAccountOptions = (accounts) => {
    const uniqueAccounts = new Map();
    accounts.forEach((account) => {
      if (!uniqueAccounts.has(account.name)) {
        uniqueAccounts.set(account.name, account);
      }
    });

    return Array.from(uniqueAccounts.values()).map((account) => (
      <Select.Option key={account.name} value={account.name}>
        {account.code}&nbsp;{account.name}
      </Select.Option>
    ));
  };

  const optionsMemo = useMemo(() => 
    Object.keys(accountOptions).map((table) => (
      <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
        {renderAccountOptions(accountOptions[table])}
      </Select.OptGroup>
    )), [accountOptions]
  );

  return (
    <div>
      <Row>
        <Col xs={12}>
          <WidgetShadow
            style={{ height: '300px'}} 
            title={<h5><span className="fw-normal">Set Up Default Account Settings</span></h5>}
          >
            <div style={{ color: 'gray'}}>
              <span>Go to <span style={{ color: themeColor, cursor: 'pointer', fontWeight: 620 }} onClick={() => { history.push('/app/accounting/account-charts');}}>Account Charts</span> to create new Accounts</span>
            </div>
            <Form className='mt-2'>
              <WidgetShadow>
              <span style={{fontSize:'14.5px', marginBottom:'10px',color: themeColor, fontWeight: 600}}>Loan Accounts</span>
                <Row className='mt-3'>
                {renderLoanInputs()}
                </Row>
                </WidgetShadow>
                <WidgetShadow>
                <span style={{fontSize:'14.5px', marginBottom:'10px',color: themeColor, fontWeight: 600}}>Other Accounts</span>
                <Row className='mt-3'>
                <Col md={6}>
                  <FormGroup>
                    <Label for="destinationAccount">Savings Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setSelectedSavingsAccount(value)}
                      value={selectedSavingsAccount}
                    >
                      {optionsMemo}
                    </Select>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="sourceAccount">Shares Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setSelectedSharesAccount(value)}
                      value={selectedSharesAccount}
                    >
                      {optionsMemo}
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="destinationAccount">Deposit Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setSelectedDepositsAccount(value)}
                      value={selectedDepositsAccount}
                    >
                      {optionsMemo}
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              </WidgetShadow>
              <TextButton label="Save" onClick={handleSave} loadingLabel='Saving' isLoading={isSubmitting} isBorderRadius={true}/>
            </Form>
          </WidgetShadow>
        </Col>
      </Row>
    </div>
  );
};

export default AccountSettings;





// import React, { useState, useEffect, useMemo } from 'react';
// import { Row, Col, Form, FormGroup, Label } from 'reactstrap';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { Select } from 'antd';
// import { fetchFirebaseConfig } from '../../../firebase';
// import { TextButton } from '../../../components/Buttons/CustomButtons';
// import WidgetShadow from '../../../components/WidgetShadow/WidgetShadow';

// const AccountSettings = () => {
//   const [accountOptions, setAccountOptions] = useState([]);
//   const [loanAccounts, setLoanAccounts] = useState([]);
//   const [selectedAccounts, setSelectedAccounts] = useState({});
//   const [themeColor, setThemeColor] = useState('');
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const { db } = fetchFirebaseConfig();

//   useEffect(() => {
//     const fetchAccountNames = async () => {
//       try {
//         const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
//         const data = {};

//         const promises = tables.map(async (table) => {
//           const collectionSnapshot = await getDocs(collection(db, table));
//           const accounts = collectionSnapshot.docs.map((doc) => doc.data());
//           return { table, accounts };
//         });

//         const resolvedPromises = await Promise.all(promises);

//         resolvedPromises.forEach(({ table, accounts }) => {
//           data[table] = accounts;
//         });
//         setAccountOptions(data);
//       } catch (error) {
//         console.error('Error fetching account names:', error);
//       }
//     };

//     const fetchLoanAccounts = async () => {
//       try {
//         const loanSnapshot = await getDocs(collection(db, 'companyLoans'));
//         const loans = loanSnapshot.docs.map((doc) => doc.data());
//         setLoanAccounts(loans);
//       } catch (error) {
//         console.error('Error fetching loan accounts:', error);
//       }
//     };

//     fetchAccountNames();
//     fetchLoanAccounts();
//   }, [db]);

//   const handleAccountChange = (value, name) => {
//     setSelectedAccounts((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//     const renderAccountOptions = (accounts) => {
//     const uniqueAccounts = new Map();
//     accounts.forEach((account) => {
//       if (!uniqueAccounts.has(account.name)) {
//         uniqueAccounts.set(account.name, account);
//       }
//     });

//     return Array.from(uniqueAccounts.values()).map((account) => (
//       <Select.Option key={account.name} value={account.name}>
//         {account.code}&nbsp;{account.name}
//       </Select.Option>
//     ));
//   };

  
//   const optionsMemo = useMemo(() => 
//     Object.keys(accountOptions).map((table) => (
//       <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
//         {accountOptions[table].map((account) => (
//           <Select.Option key={account.name} value={account.name}>
//             {account.name}
//           </Select.Option>
//         ))}
//       </Select.OptGroup>
//     )), [accountOptions]
//   );


//   const renderLoanInputs = () => {
//     return loanAccounts.map((loan) => (
//       <Col md={4} key={loan.name}>
//         <FormGroup>
//           <Label>{`${loan.name} Account:`}</Label>
//           <Select
//             showSearch
//             style={{ width: '100%' }}
//             placeholder="Select an account"
//             optionFilterProp="children"
//             filterOption={(input, option) =>
//               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//             }
//             onChange={(value) => handleAccountChange(value, loan.name)}
//             value={selectedAccounts[loan.name]}
//           >
//             {optionsMemo}
//           </Select>
//         </FormGroup>
//       </Col>
//     ));
//   };

//   const handleSave = async () => {
//     setIsSubmitting(true);
//     // Save the selected account settings to Firestore...
//     setIsSubmitting(false);
//   };

//   return (
//     <div>
//       <Row>
//         <Col xs={12}>
//           <WidgetShadow
//             style={{ height: 'auto' }} 
//             title={<h5><span className="fw-normal">Set Up Default Account Settings</span></h5>}
//           >
//             <Form className='mt-3'>
//               <Row>
//                 {renderLoanInputs()}
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label>Savings Account:</Label>
//                     <Select
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       onChange={(value) => handleAccountChange(value, 'Savings')}
//                       value={selectedAccounts['Savings']}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label>Shares Account:</Label>
//                     <Select
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       onChange={(value) => handleAccountChange(value, 'Shares')}
//                       value={selectedAccounts['Shares']}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//                 <Col md={6}>
//                   <FormGroup>
//                     <Label>Deposits Account:</Label>
//                     <Select
//                       showSearch
//                       style={{ width: '100%' }}
//                       placeholder="Select an account"
//                       onChange={(value) => handleAccountChange(value, 'Deposits')}
//                       value={selectedAccounts['Deposits']}
//                     >
//                       {optionsMemo}
//                     </Select>
//                   </FormGroup>
//                 </Col>
//               </Row>
//               <TextButton label="Save" onClick={handleSave} isLoading={isSubmitting} />
//             </Form>
//           </WidgetShadow>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default AccountSettings;


