import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { collection, getDocs, query } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { getThemeColor } from '../../components/ThemeSetUp'; 

const ViewBudget = () => {
  const { db } = fetchFirebaseConfig();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const budgetSnapshot = await getDocs(collection(db, 'Budgets'));
        const budgets = [];

        budgetSnapshot.forEach((doc) => {
          const data = doc.data();
          budgets.push({
            ...data,
            id: doc.id,
          });
        });

        const incomeSnapshot = await getDocs(collection(db, 'TestIncome'));
        const incomeMap = new Map();
        incomeSnapshot.forEach((doc) => {
          incomeMap.set(doc.id, doc.data());
        });

        const expensesSnapshot = await getDocs(collection(db, 'TestExpenses'));
        const expensesMap = new Map();
        expensesSnapshot.forEach((doc) => {
          expensesMap.set(doc.id, doc.data());
        });

        const processedData = budgets.map((budget) => {
          const records = budget.records.map((record) => {
            const accountData =
              incomeMap.get(record.account_id) ||
              expensesMap.get(record.account_id) ||
              {};
            return {
              ...record,
              account_code: accountData.account_code || record.account_code,
              account_level: accountData.account_level || record.account_level,
              account_name: accountData.account_name || record.account_name,
            };
          });
          return {
            ...budget,
            records,
          };
        });

        setData(processedData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();

    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, [db]);

  const columns = [
    {
      title: 'Account Code',
      dataIndex: 'account_code',
      key: 'account_code',
      width: 150,
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
      width: 200,
    },
    // {
    //   title: 'Account Level',
    //   dataIndex: 'account_level',
    //   key: 'account_level',
    //   width: 150,
    // },
    ...[
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ].map((month) => ({
      title: month.charAt(0).toUpperCase() + month.slice(1),
      dataIndex: month,
      key: month,
      width: 100,
    })),
    // {
    //   title: 'Annual Total',
    //   dataIndex: 'total',
    //   key: 'total',
    //   width: 150,
    // },
  ];

  const expandedRowRender = (record) => {
    return (
      <Table
        columns={columns}
        dataSource={record.records}
        pagination={false}
        rowKey='account_id'
      />
    );
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Budget</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>View Budget</span></Breadcrumb.Item>
      </Breadcrumb>
      <h5 className='mb-lg'>View Budget</h5>
      <WidgetShadow>
      <Table
        loading={loading}
        columns={[
          {
            title: 'Budget Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
          },
          {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            width: 100,
          },
        ]}
        dataSource={data}
        expandedRowRender={expandedRowRender}
        rowKey='id'
      />
      </WidgetShadow>
    </div>   
  );
};

export default ViewBudget;
