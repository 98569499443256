import React, { useRef, useState, useEffect } from 'react';
import { Button, Input, Space, Table,Tooltip } from 'antd';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'firebase/compat/auth';
import { faCreditCard, faFileExport, faMoneyCheckDollar, faPrint, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import { getThemeColor } from '../../components/ThemeSetUp';
import s from './Styles.module.scss';
import ReusableTable from '../../components/CustomTable/table';

const SharesAccounts = () => {
  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [themeColor, setThemeColor] = useState('');

  const history = useHistory();

  const { db } = fetchFirebaseConfig();

  useEffect(() => {
    fetchLoansAccounts();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  const fetchLoansAccounts = async () => {
    try {
      setLoadingData(true);
      const querySnapshot = await getDocs(query(collection(db, 'Liabilities'), where('AccountType', '==', 'Shares Account')));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setData(fetchedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLoansAccounts();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);


  const renderDateRequested = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };


  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'account_name', width: 30 },
      { header: 'Balance', key: 'balances', width: 20 },
      { header: 'Date Created', key: 'createDate', width: 15 },
    ];
  
   const formatters = {
    id: (_, __, index) => index + 1,
    createDate: renderDateRequested,
  };

 await generateExcelFile({
    sheetName: 'Shares Accounts',
    title: 'Shares Accounts',
    columns: columns,
    data: data,
    formatters: formatters,
    fileName: 'SharesAccounts.xlsx',
    numericalColumns: ['balances'],
    useFullName: true, // This will use the full name formatter
    useLatestBalance: true, // This will use the latest balance formatter
    customStyles: {
      headerFill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F7FA' }
      }
    }
  });
};


const generatePDF = async () => {
  const columns = [
    { header: '#', key: 'id', width: 5 },
    { header: 'Name', key: 'account_name', width: 30 },
    { header: 'Balance', key: 'balances', width: 20 },
    { header: 'Date Created', key: 'createDate', width: 15 },
  ];
    
  const formatters = {
    id: (_, __, index) => index + 1,
    createDate: renderDateRequested,
  };

  await generatePDFFile({
    title: 'Shares Accounts',
    columns: columns,
    data: data,
    formatters: formatters,
    fileName: 'SharesAccounts.pdf',
    numericalColumns: ['balances'],
    useFullName: true, // This will use the full name formatter
    useLatestBalance: true, // This will use the latest balance formatter
  });
};


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center',
      render: (text, record) => (
        <Link to={`/app/accounting/parent-account-ledger/${record.id}`} className={s.accountlink} style={{ '--theme-color': themeColor }}>
        {text}
      </Link>
      ),
    },
    {
      title: 'Shares Balance',
      dataIndex: 'balances',
      key: 'balances',
      align: 'center',
      render: (balances) => {
        if (Array.isArray(balances)) {
          // Find the balance with the latest timestamp
          const latestBalance = balances.reduce((prev, current) => {
            return (new Date(prev.date) > new Date(current.date)) ? prev : current;
          });
          return formatNumber(latestBalance.balance);
        }
        return 'N/A'; // Or any default value or message
     }
    },
    {
      title: 'Date Created',
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'center',
      render: renderDateRequested,
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <Space>
            <ButtonIcon icon={faUserShield} tooltipTitle="Panel" onClick={() => history.push(`/app/shares/shares-accounts/shares-panel/${record.id}`)}/>
            <ButtonIcon icon={faCreditCard} tooltipTitle="Deposits" onClick={() => history.push(`/app/shares/shares-accounts/shares-payment/${record.id}`)}/>
            <ButtonIcon icon={faMoneyCheckDollar} tooltipTitle='Payouts' onClick={() => history.push(`/app/shares/shares-accounts/shares-payout/${record.id}`)}/>
          </Space>
        ),
      },
      
  ];

  return (
    <div>
       <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Shares</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Accounts</span></Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-3">Shares Accounts</h5>
      <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="createDate"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
    </div>
  );
};

export default SharesAccounts;
