// data.js
import { collection, getDocs,query,where,onSnapshot } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../../firebase';


  export const getTotalSavingsDisbursements = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'PaymentData'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanState is 'Approved'
      const q = query(savingsCollection, where('transaction', '==', 'Savings Payout'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalSavingsDisbursements = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount field from each document and add it to the total
        totalSavingsDisbursements += doc.data().Amount;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalSavingsDisbursements = totalSavingsDisbursements
  
      return formattedTotalSavingsDisbursements; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching approved loans:', error);
      throw error;
    }
  };



  export const getTotalSavingsPayments = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'allTransaction2'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanState is 'Approved'
      const q = query(savingsCollection, where('transactionType', '==', 'Saving Deposit'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalSavingsPayments = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount field from each document and add it to the total
        totalSavingsPayments += doc.data().transactionAmount;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalSavingsPayments = totalSavingsPayments
  
      return formattedTotalSavingsPayments; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching approved loans:', error);
      throw error;
    }
  };

  