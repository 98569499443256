import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { useState,useEffect } from 'react';
import { collection,doc,addDoc,updateDoc,serverTimestamp,getDoc,onSnapshot } from 'firebase/firestore';
import Widget from '../../components/Widget/Widget';
import {fetchFirebaseConfig} from '../../firebase';
import Swal from 'sweetalert2';
import { useRef } from "react";
import html2canvas from 'html2canvas';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactToPrint from 'react-to-print';
import { useHistory,useParams } from 'react-router-dom/cjs/react-router-dom.min'; 
import Receipt from '../../components/recepitSample/receipt';
import jsPDF from 'jspdf';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { TextButton } from '../../components/Buttons/CustomButtons';


const LoansFormPayment = ( ) => {

  const [editedAmountPaid, setEditedAmountPaid] = useState(" ");
  const [loanDetails, setLoanDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(" ");

  const toggle = (e) => setModal(!modal);

  const {id} = useParams();

  const history = useHistory();

  const componentRef = useRef(null);

  const receipt = (e) =>{
    e.preventDefault();
    setModal(!modal);
  }

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const { db } = fetchFirebaseConfig();
        const loanRef = doc(db, 'Loans',id);
        const loanSnapshot = await getDoc(loanRef);

        if (loanSnapshot.exists()) {
          const loanData = loanSnapshot.data();
          setLoanDetails(loanData);

          const userRef = loanData.user;
          const userSnapshot = await getDoc(userRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setUserDetails(userData);

            setLoanDetails((prevLoanDetails) => ({
              ...prevLoanDetails,
              user: userData.display_name,
              userPosition: userData.position,
              userPhotoUrl: userData.photo_url,
              userInterest: userData.interestRate,
              userid: userData.uid,
            }));

            onSnapshot(loanRef, (updatedLoanSnapshot) => {
              if (updatedLoanSnapshot.exists()) {
                const updatedLoanData = updatedLoanSnapshot.data();
                setLoanDetails(updatedLoanData);
              }
            });
          } else {
            setUserDetails(null);
          }

          onSnapshot(loanRef, (updatedLoanSnapshot) => {
            if (updatedLoanSnapshot.exists()) {
              const updatedLoanData = updatedLoanSnapshot.data();
              setLoanDetails(updatedLoanData);
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchLoanDetails();
  }, [id]); 


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { db,storage } = fetchFirebaseConfig();
      const loanRef = doc(db, 'Loans',id);
  
      // Fetch the loan document to get the loanId field
      const loanSnapshot = await getDoc(loanRef);
      const loanData = loanSnapshot.data();
  
      const updatedAmountPaid = parseInt(editedAmountPaid);
  
      // Use SweetAlert to confirm the action
      const result = await Swal.fire({
        title: 'Confirm',
        text: `Make a payment of TSH ${updatedAmountPaid?.toLocaleString()} to ${loanDetails?.userName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });
  
      if (result.isConfirmed) {

        setLoading(true);
  
        const { db,storage } = fetchFirebaseConfig();
        const loanRef = doc(db, 'Loans',id);

       // Fetch the updated loan details after the updateDoc is complete
        const updatedLoanSnapshot = await getDoc(loanRef);
        const updatedLoanData = updatedLoanSnapshot.data();

        const element = document.getElementById('receipt');

        // Generate PNG image from the receipt element
        const canvas = await html2canvas(element, { scale: 2, useCORS: true });
        const imgData = canvas.toDataURL('image/png');
        const blob = await fetch(imgData).then((res) => res.blob());

        const storageRef = ref(storage, `Payslip/${id}/receipt.png`);
        await uploadBytes(storageRef, blob);
        const downloadURL = await getDownloadURL(storageRef);

        // Generate PDF from the receipt element
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        const pdfBlob = pdf.output('blob');

        const pdfStorageRef = ref(storage, `PayslipPdf/${id}/receipt.pdf`);
        await uploadBytes(pdfStorageRef, pdfBlob);
        const pdfDownloadURL = await getDownloadURL(pdfStorageRef);

        toggle();

                // Convert the provided date to a server timestamp
                const dateParts = date.split('-');
                const year = parseInt(dateParts[0]);
                const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
                const day = parseInt(dateParts[2]);
        
                // Extract the time from the current date object
                const currentDate = new Date();
                const hours = currentDate.getHours();
                const minutes = currentDate.getMinutes();
                const seconds = currentDate.getSeconds();
        
                const serverTimestampmap = new Date(
                  year,
                  month,
                  day,
                  hours,
                  minutes,
                  seconds
                );

          const transactionData = {
            approvalStatus: 'Approved',
            transactionAmount: updatedAmountPaid,
            transactionDate:  serverTimestampmap,
            transactionType: 'Loan Payment',
            transactionUser: loanDetails.user,
            voucher: downloadURL,
            transactionId:id,
            voucherPdf: pdfDownloadURL, // Add the loanId field to the transaction data
          };
  
        // Add the transaction data to the collection
        await addDoc(collection(db, 'allTransaction2'), transactionData);

        setEditedAmountPaid(" ")
        // Show success message
        setLoading(false);

        Swal.fire({
          icon: 'success',
          title: 'Payment Successful',
          text: 'The transaction has been successfully completed.',
          confirmButtonColor: '#28a745',
        });

        history.push(`/app/loans/loans-accounts/loan-armotization/${id}`)
      }
    } catch (error) {
      console.error(error);
    } 
  };


  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      align: 'center',
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: 'center',
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: 'center',
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      align: 'center',
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: 'center',
    },
  ];
  
  const dataSource = [
    {
      key: "1",
      index: 1,
      description: 'Loan payment',
      quantity: 1,
      unitPrice: `${numberWithCommas(editedAmountPaid)}`,
      amount: `${numberWithCommas(editedAmountPaid)}`,
      align: 'center',
    },
  ];
  
  const totalRow = {
  
    key: 'total',
    description: '',
    quantity: '',
    unitPrice: <b>Total</b>,
    amount:`${numberWithCommas(editedAmountPaid)}`,
    isTotalRow: true, // Add a flag to identify the total row
  };
  
  const tableData = [...dataSource, totalRow];

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  return (
    <div>
      <h5 className="mb-lg">Submit Loan payments</h5>
      <Row>
       <Col xs={6}>
          <WidgetShadow>
            <Form onSubmit={receipt}>
              <FormGroup>
                  <Label for="PaidAmount">Amount</Label>
                  <Input
                  id="PaidAmount"
                  style={{ borderRadius: '7px' }}
                  name="loanLimit"
                  placeholder="Payment Amount"
                  type='number'
                  required
                  value={editedAmountPaid}
                  onChange={(e) => setEditedAmountPaid(e.target.value)}
                  />
              </FormGroup>
              <FormGroup>
                  <Label for="selectedDate">Date</Label>
                  <Input
                  id="selectedDate"
                  style={{ borderRadius: '7px' }}
                  name="selectDate"
                  required
                  placeholder="Select Date"
                  type='date'
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  />
              </FormGroup>
              <TextButton label='Submit' isSubmit={true} isBorderRadius={true}/>
              </Form>
                <Receipt
                fetchId='receipt'
                ref={componentRef}
                isOpen={modal}
                toggle={toggle}
                dataSource={tableData}
                columns={columns}
                user={userDetails?.display_name}
                userLocation={userDetails?.location}
                userContacts={userDetails?.phone_number}
                referenceNumber={userDetails?.refNo}
                userDate={date}
                amountRequested={editedAmountPaid}
                submit={handleSubmit}
                receiptTitle="Payment Voucher"
                destination='To'
             />
          </WidgetShadow>
        </Col>
      </Row>
    </div>
  );
};

export default LoansFormPayment;

