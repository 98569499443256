// data.js
import { collection, getDocs,query,where,onSnapshot } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../../firebase';

export const countUsers = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const usersCollection = collection(db, 'users'); // Use collection function
  
      const snapshot = await getDocs(usersCollection); // Use getDocs to get a snapshot of the collection
      const userCount = snapshot.size;
  
      return userCount;
    } catch (error) {
      console.error('Error counting users:', error);
      return 0;
    }
  };

  export const getTotalApprovedLoanAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const assetsCollection = collection(db, 'Assets'); // Access the Assets collection
  
      // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
      const q = query(
        assetsCollection,
        where('loanType', 'in', ['Development Fund', 'Chap Chap', 'Long Term Loan'])
      );
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalLoanAmount = 0;
  
      querySnapshot.forEach((doc) => {
        const balances = doc.data().balances;
        if (Array.isArray(balances)) {
          // Find the balance with the latest timestamp
          const latestBalance = balances.reduce((prev, current) => {
            return (new Date(prev.date) > new Date(current.date)) ? prev : current;
          });
          totalLoanAmount += latestBalance.balance;
        }
      });
  
      return totalLoanAmount; // Return the total amount
    } catch (error) {
      console.error('Error fetching loans:', error);
      throw error;
    }
  };



  export const getTotalSavingsAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const assetsCollection = collection(db, 'Liabilities'); // Access the Assets collection
  
      // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
      const q = query(
        assetsCollection,
        where('AccountType', '==', 'Savings Account')
      );
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let totalSavingsAmount = 0;
  
      querySnapshot.forEach((doc) => {
        const balances = doc.data().balances;
        if (Array.isArray(balances)) {
          // Find the balance with the latest timestamp
          const latestBalance = balances.reduce((prev, current) => {
            return (new Date(prev.date) > new Date(current.date)) ? prev : current;
          });
          totalSavingsAmount += latestBalance.balance;
        }
      });
  
      return totalSavingsAmount; // Return the total amount
    } catch (error) {
      console.error('Error fetching Savings:', error);
      throw error;
    }
  };



  export const getSharesDifferenceAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const assetsCollection = collection(db, 'Liabilities'); // Access the Assets collection
  
      // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
      const q = query(
        assetsCollection,
        where('AccountType', '==', 'Shares Account')
      );
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total shares amount
      let totalSharesAmount = 0;
  
      querySnapshot.forEach((doc) => {
        const balances = doc.data().balances;
        if (Array.isArray(balances)) {
          // Find the balance with the latest timestamp
          const latestBalance = balances.reduce((prev, current) => {
            return (new Date(prev.date) > new Date(current.date)) ? prev : current;
          });
           totalSharesAmount += latestBalance.balance;
        }
      });

      const formattedSharesAmount = totalSharesAmount
  
      return formattedSharesAmount; // Return the total amount
    } catch (error) {
      console.error('Error fetching Shares:', error);
      throw error;
    }
  };





const fetchTotalUsersCount = async () => {
    const { db } = fetchFirebaseConfig();
    const usersCollection = collection(db, 'users');
    const usersSnapshot = await getDocs(usersCollection);
  
    return usersSnapshot.size; // Get the total count of users
  };
  
  export { fetchTotalUsersCount };

  const fetchActiveSavingsCount = async () => {
    const { db } = fetchFirebaseConfig();
    const savingsCollection = collection(db, 'Liabilities');
    const activeSavingsQuery = query(savingsCollection, where('AccountType', '==', 'Savings Account'));
    const activeSavingsSnapshot = await getDocs(activeSavingsQuery);
  
    return activeSavingsSnapshot.size; // Get the count of active savings
  };
  
  export { fetchActiveSavingsCount };


  export const getTotalSharesAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'Savings'); // Replace 'Savings' with your collection name
  
      // Create a query to filter documents where status is 'active'
      const q = query(savingsCollection, where('status', '==', 'active'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let Amount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the totalSavings field from each document and add it to the total
        Amount += doc.data().Amount;
      });
  
      // Format the total savings amount as TZS using numeral.js
      const formattedTotalSharesAmount = Amount
  
      return formattedTotalSharesAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching total savings:', error);
      throw error;
    }
  };



  export const getSharesAmount = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const savingsCollection = collection(db, 'shares'); // Replace 'Savings' with your collection name
  
      // Create a query to filter documents where status is 'active'
      const q = query(savingsCollection);
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total savings amount
      let totalSharesAmount = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the totalSavings field from each document and add it to the total
        totalSharesAmount += doc.data().totalSharesAmount;
      });
  
      // Format the total savings amount as TZS using numeral.js
      const formattedSharesAmount = totalSharesAmount
  
      return formattedSharesAmount; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching total savings:', error);
      throw error;
    }
  };

  // In data.js

// export const getSharesDifferenceAmount = async () => {
//   try {
//     const { db } = fetchFirebaseConfig();
//     const savingsCollection = collection(db, 'Savings');
//     const sharesCollection = collection(db, 'shares');

//     // Create a query to filter documents where status is 'active' in Savings
//     const savingsQuery = query(savingsCollection, where('status', '==', 'active'));
//     const savingsSnapshot = await getDocs(savingsQuery);

//     // Initialize variables to store the total savings amount and shares amount
//     let totalSavingsAmount = 0;
//     savingsSnapshot.forEach((doc) => {
//       totalSavingsAmount += doc.data().Amount;
//     });

//     // Create a query to get totalSharesAmount from the 'shares' collection
//     const sharesQuery = query(sharesCollection);
//     const sharesSnapshot = await getDocs(sharesQuery);

//     // Initialize a variable to store the total shares amount
//     let totalSharesAmount = 0;
//     sharesSnapshot.forEach((doc) => {
//       totalSharesAmount += doc.data().totalSharesAmount;
//     });

//     // Calculate the difference between totalSavingsAmount and totalSharesAmount
//     const differenceAmount = totalSharesAmount - totalSavingsAmount;

//     // Format the difference amount as TZS using numeral.js
//     const formattedDifferenceAmount = differenceAmount

//     return formattedDifferenceAmount;
//   } catch (error) {
//     console.error('Error fetching shares difference amount:', error);
//     throw error;
//   }
// };


export const getTotalDeposits = async () => {
  try {
    const { db } = fetchFirebaseConfig();
    const assetsCollection = collection(db, 'Liabilities'); // Access the Assets collection

    // Create a query to filter documents where loanType is 'Development Fund', 'Chap Chap', or 'Long Term Loan'
    const q = query(
      assetsCollection,
      where('AccountType', '==', 'Deposit Account')
    );

    const querySnapshot = await getDocs(q);

    // Initialize a variable to store the total deposit amount
    let totalDepositsAmount = 0

    querySnapshot.forEach((doc) => {
      const balances = doc.data().balances;
      if (Array.isArray(balances)) {
        // Find the balance with the latest timestamp
        const latestBalance = balances.reduce((prev, current) => {
          return (new Date(prev.date) > new Date(current.date)) ? prev : current;
        });
        totalDepositsAmount += latestBalance.balance;
      }
    });

    const formattedTotalDepositsAmount = totalDepositsAmount

    return formattedTotalDepositsAmount; // Return the total amount
  } catch (error) {
    console.error('Error fetching Deposits:', error);
    throw error;
  }
};




  
