import React, { useState, useEffect, useMemo } from 'react';
import Widget from '../../../../components/Widget/Widget';
import { useParams, Link } from "react-router-dom";
import { Spinner,FormGroup,Label,Row,Col,Input as InputData,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {fetchFirebaseConfig} from '../../../../firebase';
import {
    doc,
    getDoc,
    query,
    where,
    collection,
    getDocs,
    updateDoc
  } from 'firebase/firestore';
import UserCurrentLoanArmotization from './CurrentloanArmotization';
import UserActualLoanArmotization from './ActualLoanArmotization';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Select } from 'antd';
import WidgetShadow from '../../../../components/WidgetShadow/WidgetShadow';
import { TextButton } from '../../../../components/Buttons/CustomButtons';
import TabNavigation from '../../../../components/TabNavigation/tabnavigation';

const  ArmotizationPanel = () => {

  const { id } = useParams(); // Get the UID from the URL

  const [activeTab, setActiveTab] = useState('ActualArmotization');
  const [refreshDepositsTable, setRefreshDepositsTable] = useState(false); 
  const tabContent = {
    ActualArmotization: { label: 'Actual Amortization', content: <UserActualLoanArmotization loanId={id}/> },
    CurrentArmotization: { label: 'Current Amortization', content: <UserCurrentLoanArmotization loanId={id}/> },
  };
  const [loanPrinciple, setLoanPrinciple] = useState(''); // Default value
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedAccount1, setSelectedAccount1] = useState('');
  const [accountOptions1, setAccountOptions1] = useState([]);
  const [selectedAccount2, setSelectedAccount2] = useState('');
  const [accountOptions2, setAccountOptions2] = useState([]);


  const {db}=fetchFirebaseConfig();

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'CurrentArmotization' || key === 'ActualArmotization') {
      // Set refresh state to true when the deposits tab is clicked
      setRefreshDepositsTable(prevState => !prevState);
    }
  };

    // Function to handle saving selected accounts
const handleSave = async () => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'PrincipleInterestAccounts'), where('company_id', '==', 'NCAAWS')));
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      await updateDoc(docRef, {
        principleAccount: selectedAccount,
        interestAccount: selectedAccount1,
        interestAccountReference: selectedAccount2,
        interestAccountCode: getAccountCode(selectedAccount1),
        interestAccountReferenceCode: getAccountCode(selectedAccount2),
        principleAccountCode: getAccountCode(selectedAccount)
      });      
      toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{fontSize: '1.5rem'}}></i>&nbsp;&nbsp; Accounts updated</div>);
      console.log('Accounts updated successfully!');
    } else {
      console.error('No document found for company_id equal to "NCAAWS"');
    }
  } catch (error) {
    console.error('Error updating accounts:', error);
  }
};


  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        if (!id) {
          console.error('UID is undefined');
          return;
        }
        const userDocRef = doc(db, 'Loans', id);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          console.log('Fetched user data:', userData);
          setLoanPrinciple(userData.amountRemaining.toLocaleString() || 0); // Set profile picture URL
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchLoanData();
  }, [db, id]);

    // Fetch and populate data from Firestore on component mount
    useEffect(() => {
      const fetchSettings = async () => {
        try {
          const querySnapshot = await getDocs(query(collection(db, 'PrincipleInterestAccounts'), where('company_id', '==', 'NCAAWS')));
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();
            setSelectedAccount(docData.principleAccount);
            setSelectedAccount1(docData.interestAccount);
            setSelectedAccount2(docData.interestAccountReference);
          }
        } catch (error) {
          console.error('Error fetching settings:', error);
        }
      };
  
      fetchSettings();
    }, [db]);


  useEffect(() => {
    const fetchAccountNames = async () => {
      try {
        const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
        const data = {};
  
        const promises = tables.map(async (table) => {
          const collectionSnapshot = await getDocs(collection(db, table));
          const accounts = [];
          const childAccountsBuffer = [];
          const grandchildBuffer = [];
  
          collectionSnapshot.docs.forEach((doc) => {
            const { account_name, account_level, parentAccount, account_code, IsMainAccount } = doc.data();
  
            // Skip accounts with IsMainAccount: 'No'
            if (IsMainAccount === 'No') {
              return;
            }
  
            if (account_level === 'parent') {
              accounts.push({ name: account_name, code: account_code, level: 1, children: [] });
            } else if (account_level === 'child') {
              childAccountsBuffer.push({ name: account_name, code: account_code, parentAccount });
            } else if (account_level === 'grandchild') {
              grandchildBuffer.push({ name: account_name, code: account_code, parentAccount });
            }
          });
  
          // Process childAccountsBuffer to add children to parents
          childAccountsBuffer.forEach((child) => {
            const parentIndex = accounts.findIndex(account => account.name === child.parentAccount);
            if (parentIndex !== -1) {
              accounts[parentIndex].children.push({ name: child.name, code: child.code, level: 2, children: [] });
            } else {
              accounts.push({ name: child.name, code: child.code, level: 1, children: [] });
            }
          });
  
          // Process grandchildBuffer to add grandchildren to children
          grandchildBuffer.forEach((grandchild) => {
            let grandchildAdded = false;
            accounts.forEach(parentAccount => {
              parentAccount.children.forEach(childAccount => {
                if (childAccount.name === grandchild.parentAccount) {
                  childAccount.children.push({ name: grandchild.name, code: grandchild.code, level: 3 });
                  grandchildAdded = true;
                }
              });
            });
            if (!grandchildAdded) {
              // console.warn(`Grandchild account ${grandchild.name} not added: Parent account ${grandchild.parentAccount} not found.`);
            }
          });
  
          // Filter out non-lowest-level accounts
          const lowestLevelAccounts = [];
          accounts.forEach((account) => {
            if (account.children.length === 0) {
              lowestLevelAccounts.push(account);
            } else {
              account.children.forEach((child) => {
                if (child.children.length === 0) {
                  lowestLevelAccounts.push(child);
                } else {
                  child.children.forEach((grandchild) => {
                    lowestLevelAccounts.push(grandchild);
                  });
                }
              });
            }
          });
  
          return { table, accounts: lowestLevelAccounts };
        });
  
        const resolvedPromises = await Promise.all(promises);
  
        resolvedPromises.forEach(({ table, accounts }) => {
          data[table] = accounts;
        });
        console.log('Fetched account names:', data);
        setAccountOptions(data);
        setAccountOptions1(data);
        setAccountOptions2(data);
      } catch (error) {
        console.error('Error fetching account names:', error);
      }
    };
  
    fetchAccountNames();
  }, [db]);
  

     // Example generateAccountCode function to generate a unique account code
     const generateAccountCode = () => {
     // Generate a random three-digit number and concatenate with "0"
      const randomDigits = Math.floor(Math.random() * 900) + 100; // Generates a number between 100 and 999
      return `${randomDigits}0`;
     };

     const transferCode = `TE${generateAccountCode()}`
  
  const renderAccountOptions = (accounts) => {
    const uniqueAccounts = new Map();
    accounts.forEach((account) => {
      if (!uniqueAccounts.has(account.name)) {
        uniqueAccounts.set(account.name, account);
      }
    });
  
    return Array.from(uniqueAccounts.values()).map((account) => (
      <Select.Option key={account.name} value={account.name}>
        {account.code}&nbsp;{account.name}
      </Select.Option>
    ));
  };

  const renderAccountOptions1 = (accounts) => {
    const uniqueAccounts = new Map();
    accounts.forEach((account) => {
      if (!uniqueAccounts.has(account.name)) {
        uniqueAccounts.set(account.name, account);
      }
    });
  
    return Array.from(uniqueAccounts.values()).map((account) => (
      <Select.Option key={account.name} value={account.name}>
        {account.code}&nbsp;{account.name}
      </Select.Option>
    ));
  };

  const renderAccountOptions2 = (accounts) => {
    const uniqueAccounts = new Map();
    accounts.forEach((account) => {
      if (!uniqueAccounts.has(account.name)) {
        uniqueAccounts.set(account.name, account);
      }
    });

    return Array.from(uniqueAccounts.values()).map((account) => (
      <Select.Option key={account.name} value={account.name}>
        {account.code}&nbsp;{account.name}
      </Select.Option>
    ));
  };
  
  const accountOptionsMemo = useMemo(() => (
    Object.keys(accountOptions).map((table) => (
      <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
        {renderAccountOptions(accountOptions[table])}
      </Select.OptGroup>
    ))
  ), [accountOptions]);

  const accountOptionsMemo1 = useMemo(() => (
    Object.keys(accountOptions1).map((table) => (
      <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
        {renderAccountOptions1(accountOptions1[table])}
      </Select.OptGroup>
    ))
  ), [accountOptions1]);

  const accountOptionsMemo2 = useMemo(() => (
    Object.keys(accountOptions2).map((table) => (
      <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
        {renderAccountOptions2(accountOptions2[table])}
      </Select.OptGroup>
    ))
  ), [accountOptions2]);

  const getAccountCode = (accountName) => {
    let code = '';
    Object.keys(accountOptions).forEach(table => {
        const account = accountOptions[table].find(acc => acc.name === accountName);
        if (account) {
            code = account.code;
        }
    });
    return code;
}; 

  return (
    <div>
        <WidgetShadow
       title={<h7><span className="fw-semi-bold">Armotization</span></h7>}
      >
         <Row  style={{marginTop:'15px'}}>
                 {/* <Col md={3}>
                  <FormGroup>
                    <Label for="sourceAccount">Principal Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedAccount}
                      onChange={(value) => setSelectedAccount(value)}
                    >
                      {accountOptionsMemo}
                    </Select>
                  </FormGroup>
                </Col> */}
                <Col md={6}>
                  <FormGroup>
                    <Label for="destinationAccount">Interest Account:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedAccount1}
                      onChange={(value) => setSelectedAccount1(value)}
                    >
                      {accountOptionsMemo1}
                    </Select>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="destinationAccount">Deduction Deposited To:</Label>
                    <Select
                      id="sourceAccount"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select an account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedAccount2}
                      onChange={(value) => setSelectedAccount2(value)}
                    >
                      {accountOptionsMemo2}
                    </Select>
                  </FormGroup>
                </Col>
             </Row>
             <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <TextButton label='Save' onClick={handleSave} size='sm'/>
                </div>
           </WidgetShadow>
            {/* <WidgetShadow>
              <Nav tabs style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <NavItem>
                <NavLink
                    className={activeTab === 'ActualArmotization' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('ActualArmotization')}
                    style={activeTab === 'ActualArmotization' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                    <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Actual Armotization</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'CurrentArmotization' ? 'active green-tab' : ''}
                    onClick={() => handleTabChange('CurrentArmotization')}
                    style={activeTab === 'CurrentArmotization' ? { backgroundColor: '#3754a5', color: 'white', borderColor:'#3754a5' } : {}}
                  >
                  <span  style={{fontSize:'12.5px',fontWeight:'bold'}}>&nbsp;&nbsp;Current Armotization</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="ActualArmotization">
                  <Widget>
                    <div className="align-items-center">{tabContent.ActualArmotization}</div>
                  </Widget>
                </TabPane>
                <TabPane tabId="CurrentArmotization">
                  <Widget>
                    <div className="align-items-center" >{tabContent.CurrentArmotization}</div>
                  </Widget>
                </TabPane>
              </TabContent>
              <ToastContainer position="top-right" autoClose={4000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{ fontSize: '13.5px'}}/>
            </WidgetShadow> */}
           <WidgetShadow>
            <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} tabContent={tabContent} />
          </WidgetShadow>
          </div>
      );
    };

export default ArmotizationPanel;
