import React,{useState,useEffect} from 'react';
import ExcelJS from 'exceljs';
// import { countUsers,getTotalApprovedLoanAmount,getTotalSavingsAmount,fetchActiveUsersCount } from '../widgets/data';
// import { fetchUserCountsByGender,fetchLoansDataAndPercentageByGender } from '../dashboard/data';

const LoansToInsiders = () => {

const [userCount, setUserCount] = useState("loading...");
const [maleCount, setMaleCount] = useState("loading...");
const [femaleCount, setFemaleCount] = useState("loading...");
const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState("loading...");
const [totalSavings, setTotalSavings] = useState("loading...");
const [activeUsersCount, setActiveUsersCount] = useState("loading...");
const [totalMaleLoanAmount, setTotalMaleLoanAmount] = useState("loading...");
const [totalFemaleLoanAmount, setTotalFemaleLoanAmount] = useState("loading...");
const [loanData, setLoanData] = useState([]);
const [totalMaleLoanCount, setTotalMaleLoanCount] = useState("loading...");
const [totalFemaleLoanCount, setTotalFemaleLoanCount] = useState("loading...");

// useEffect(() => {
//   // Fetch the total savings amount when the component mounts
//   getTotalSavingsAmount()
//     .then((formattedTotalSavingsAmount) => {
//       setTotalSavings(formattedTotalSavingsAmount);
//     })
//     .catch((error) => {
//       console.error('Error fetching total savings:', error);
//     });
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const counts = await fetchUserCountsByGender(window.location);
//       setMaleCount(counts.maleCount);
//       setFemaleCount(counts.femaleCount);
//     } catch (error) {
//       console.error('Error fetching user counts:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const result = await fetchLoansDataAndPercentageByGender(window.location);
//       setLoanData(result.totalLoanData);
//       setTotalMaleLoanAmount(result.totalMaleLoanAmount);
//       setTotalFemaleLoanAmount(result.totalFemaleLoanAmount);
//       setTotalMaleLoanCount(result.totalMaleLoanCount);
//       setTotalFemaleLoanCount(result.totalFemaleLoanCount);
//     } catch (error) {
//       console.error('Error fetching loan data:', error);
//       // Handle error as needed
//     }
//   };

//   fetchData();
// }, []); 

   const handleLoansToInsidersReport = async () => {
    // Hard-coded values for demonstration
    const reportData = {
      nameOfSaccos: 'NCAA WORKERS SAVINGS AND CREDIT COOPERATIVE SOCIETY LIMITED',
      mspcode: 'xxx',
    };

    // Create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saccos Report');

 // Increase the height of the row
    const headerRow1a = worksheet.addRow(['',`NAME OF SACCOS: ${reportData.nameOfSaccos}`])
    headerRow1a.font = { bold: true,}; 
    headerRow1a.height = 25;
    const headerRow1b =worksheet.addRow(['',`MSP CODE :  ${reportData.mspcode}`])
    headerRow1b.font = { bold: true,}; 
    headerRow1b.height = 25;
    const headerRow1 = worksheet.addRow(['','LOANS TO INSIDERS AND RELATED PARTIES AS AT:']);
    headerRow1.font = { bold: true,}; // Make the font bold and set color to dark blue
    headerRow1.height = 25;
    const headerRow1c = worksheet.addRow([ '','MSP3 FORM 06: To be submitted Monthly for Category B and Quartely for Category A',])
    headerRow1c.font = { bold: true,}; 
    headerRow1c.height = 25;
    const headerRow1d = worksheet.addRow(['','Amount reported as TZS 0.00'])
    headerRow1d.font = { bold: true,}; 
    headerRow1d.height = 25;
    
    // Add empty rows for spacing
    worksheet.addRow([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    // Add additional details
    const headerRow2e = worksheet.addRow(['Sno','Related Party','Number of loans','Loan Amount','Loan type','Interest Rate','Amount of Savings, Deposits and Voluntary Shares Guaranteeing Loan']);
    headerRow2e.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6'} }; // Light Blue background color
    headerRow2e.font = { bold: true,};
    worksheet.addRow(['1','Board and Committee Members','1','80,182','Agriculture loan','1,185','']);
    worksheet.addRow(['2','Managers','0','','','0.0','']);
    worksheet.addRow(['3','Employees','0','','','0.0',''])
    worksheet.addRow(['4','External auditors and Registrar Inspectors','0','','','0.0',''])
    worksheet.addRow(['5','Immediate family members of any of the above','0','','','0.0','']);
    worksheet.addRow(['6','Total','1','','','1,185','']).font = { bold: true};
   

  
       // Auto-adjust column widths
        worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? String(cell.value).length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

            // Add additional content
            worksheet.addRow([]); // Empty row to create space
            worksheet.addRow(['','(*SACCOS management should provide the member name, the number of loans for each member the total outstanding balance for all loans to the member that in TOTAL exceed 5% of core capital and the total amount of savings, deposits, or voluntary Shares used to guarantee the loan as of the reporting date.)']);
            worksheet.addRow([]); // Empty row to create space
            worksheet.addRow(['','Miscellaneous Information']);
            worksheet.addRow(['','4.9 Longest Loan Maturity (in months)','0']);


    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and click it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'liquid_to_insiders.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    handleLoansToInsidersReport,
    // ... (other data or functions you want to export)
  };
};

export default LoansToInsiders;