import React, { useRef, useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, Spinner, Badge, FormGroup, Label, Row, Col, Input as InputData, Form } from 'reactstrap';
import { Button, Input, Space, Table } from 'antd';
import { Button as Click, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import { collection, setDoc, addDoc, doc, query, getDocs, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { fetchFirebaseConfig } from '../../firebase';
import Widget from '../../components/Widget/Widget';
import { nanoid } from 'nanoid';
import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon } from '../../components/Buttons/CustomButtons';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import CreateCategoryLoan from '../settings/loan/createCategoryLoans';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import LoanSubCategories from './loanSubCategory';

const LoanCategories = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalCategory, setModalCategory] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const searchInput = useRef(null);
  const [modal, setModal] = useState(false);
  const [viewModal, setviewModal] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState(null);

  const history = useHistory();

  const toggle = () => setModal(!modal);

  const viewtoggle = () => setviewModal(!viewModal);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const fetchLoanCategories = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loansTypeCollectionRef = collection(db, 'LoansType');
      const snapshot = await getDocs(loansTypeCollectionRef);

      const categoriesMap = new Map();
      let count = 1;

      for (const docRef of snapshot.docs) {
        const loanTypeId = docRef.id;
        const loanTypeCategoryRef = collection(db, `LoansType/${loanTypeId}/LoanTypeCategory`);
        const loanTypeCategorySnapshot = await getDocs(loanTypeCategoryRef);

        for (const categoryDoc of loanTypeCategorySnapshot.docs) {
          const loanTypeCategoryData = categoryDoc.data();
          const loanTypeName = loanTypeCategoryData.LoanTypeName || "";

          if (!categoriesMap.has(loanTypeName)) {
            categoriesMap.set(loanTypeName, {
              key: count,
              id: count,
              name: loanTypeName,
              sector: loanTypeCategoryData.sectorName ? Object.keys(loanTypeCategoryData.sectorName).join(', ') : "",
              loanTypeId: loanTypeId,
            });
            count++;
          }
        }
      }

      setData(Array.from(categoriesMap.values()));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching loan categories:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoanCategories();
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space>
          <ButtonIcon icon={faEye} onClick={() => viewSubCategories(record)} />
          <ButtonIcon icon={faAdd} />
          <Click color='danger' outline style={{ borderRadius: '10px' }}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Click>
        </Space>
      ),
    },
  ];

  const viewSubCategories = (record) => {
    setSelectedLoanType(record);
    viewtoggle();
  };

  return (
    <div>
          <WidgetShadow
           style={{ height: '300px'}} 
            title={<h5><span className="fw-normal">Loan Categories</span></h5>}
           >
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonIcon icon={faAdd} tooltipTitle='Add Category' onClick={toggle}/>
        </div>
        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Table columns={columns} dataSource={data} bordered loading={loading} size="small" />
        </div>
        <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader style={{border:'none'}} toggle={toggle}>Add new Category</ModalHeader>
                    <ModalBody>
                    <Widget>
                    <CreateCategoryLoan/>
                   </Widget>
            </ModalBody>
        </Modal>
        <Modal isOpen={viewModal} toggle={viewtoggle}>
        <ModalHeader style={{border:'none'}} toggle={viewtoggle}>Sub-categories for {selectedLoanType?.name}</ModalHeader>
        <ModalBody>
          <Widget>
            <LoanSubCategories loanTypeId={selectedLoanType?.loanTypeId} loanTypeName={selectedLoanType?.name} />
          </Widget>
        </ModalBody>
      </Modal>
      </WidgetShadow>
    </div>
  );
};

export default LoanCategories;
