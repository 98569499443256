import React from 'react';
import { Button } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { TextButton } from '../../components/Buttons/CustomButtons';

const ExportPDF = ({ summaryData, data }) => {

  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  // Calculate Approval Status counts
  const approvalStatusCounts = {
    'Approved': data.filter(item => item.loanState === 'Approved').length,
    'Denied': data.filter(item => item.loanState === 'Denied').length,
    'Pending': data.filter(item => item.loanState === 'Pending').length,
  };

  const handleExportPDF = () => {
    const input = document.getElementById('pdf-content');
    const dataInput = document.getElementById('data-content');

    html2canvas(input).then((summaryCanvas) => {
      html2canvas(dataInput).then((dataCanvas) => {
        const pdf = new jsPDF();

        const summaryRows = summaryData
          .filter(item => 
            item.title !== '#' && 
            item.title !== 'Name' && 
            item.title !== "Requested On" && 
            item.title !== "Payment Status" && 
            item.title !== "Payment Due On"
          )
          .map((item, index) => {
            if (item.title === 'Loan Term') {
              return [
                index + 1,
                'Loan Term',
                `Long Term Loan: ${item.count['Long Term Loan'] || 0} (${formatNumber(item.loanAmount1['Long Term Loan'] || 0)}), Mid Term Loan: ${item.count['Mid Term Loan'] || 0} (${formatNumber(item.loanAmount1['Mid Term Loan'] || 0)}), Short Term Loan: ${item.count['Short Term Loan'] || 0} (${formatNumber(item.loanAmount1['Short Term Loan'] || 0)})`,
                formatNumber(item.loanAmount1 ? (item.loanAmount1['Long Term Loan'] + item.loanAmount1['Mid Term Loan'] + item.loanAmount1['Short Term Loan']) : 0),
              ];
            } else if (item.title === 'Approval Status') {
              return [
                index + 1,
                'Approval Status',
                `Approved: ${approvalStatusCounts['Approved'] || 0}, Denied: ${approvalStatusCounts['Denied'] || 0}, Pending: ${approvalStatusCounts['Pending'] || 0}`,
                formatNumber(item.loanAmount1 ? (item.loanAmount1['Approved'] + item.loanAmount1['Denied'] + item.loanAmount1['Pending']) : 0),
              ];
            } else {
              return [
                index + 1,
                item.title,
                item.count,
                formatNumber(item.loanAmount1 || 0),
              ];
            }
          });

        const dataRows = data.map((item, index) => [
          index + 1,
          item.member,
          formatNumber(item.loanAmount1),
          item.loanSchedual,
          item.amountPaid,
          item.amountRemaining,
          item.loanStatus,
          renderDateRequested(item.dateTaken),
          item.loanState
        ]);

        const title = 'Loans Records Summary';

        pdf.text(title, 10, 10);

        pdf.autoTable({
          startY: 20,
          head: [['#', 'Data Instances', 'Count Of Instances', 'Amount']],
          body: summaryRows,
          theme: 'grid',
          styles: {
            cellPadding: 1,
            fontSize: 10,
            valign: 'middle',
            halign: 'center',
          },
        });

        pdf.autoTable({
          startY: pdf.autoTable.previous.finalY + 10,
          head: [['#', 'Name', 'Loan Request', 'Loan Term', 'Amount Paid', 'Remaining Balance', 'Payment Status', 'Requested On', 'Approval Status']],
          body: dataRows,
          theme: 'grid',
          styles: {
            cellPadding: 1,
            fontSize: 10,
            valign: 'middle',
            halign: 'center',
          },
        });

        pdf.save("download.pdf");
      });
    });
  };

  return (
    <TextButton label='Export'  onClick={handleExportPDF}/>
  );
};

export default ExportPDF;
