// data.js
import { collection, getDocs,query,where,onSnapshot } from 'firebase/firestore';
import {fetchFirebaseConfig} from '../../../firebase';


  export const getTotalLoanDisbursements = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loansCollection = collection(db, 'PaymentData'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanState is 'Approved'
      const q = query(loansCollection, where('transaction', '==', 'Loan Payout'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalLoanDisbursements = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount field from each document and add it to the total
        totalLoanDisbursements += doc.data().Amount;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalLoanDisbursements = totalLoanDisbursements
  
      return formattedTotalLoanDisbursements; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching approved loans:', error);
      throw error;
    }
  };



  export const getTotalLoanPayments = async () => {
    try {
      const { db } = fetchFirebaseConfig();
      const loansCollection = collection(db, 'allTransaction2'); // Replace 'Loans' with your collection name
  
      // Create a query to filter documents where loanState is 'Approved'
      const q = query(loansCollection, where('transactionType', '==', 'Loan Payment'));
  
      const querySnapshot = await getDocs(q);
  
      // Initialize a variable to store the total loan amount
      let totalLoanPayments = 0;
  
      querySnapshot.forEach((doc) => {
        // Access the loanAmount field from each document and add it to the total
        totalLoanPayments += doc.data().transactionAmount;
      });
  
      // Format the total loan amount as TZS using numeral.js
      const formattedTotalLoanPayments = totalLoanPayments
  
      return formattedTotalLoanPayments; // Return the formatted amount
    } catch (error) {
      console.error('Error fetching approved loans:', error);
      throw error;
    }
  };

  