import React from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button as Click,
  ButtonGroup,
} from 'reactstrap';
import { Breadcrumb } from 'antd';
import { Button, Space, Table,Spin,Select,Collapse } from 'antd';
import { useState,useEffect,useMemo } from 'react';
import { collection,doc,addDoc,updateDoc,serverTimestamp,getDocs,query,getDoc,where } from 'firebase/firestore';
import { useHistory,Link } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import {fetchFirebaseConfig} from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {TextButton} from '../../components/Buttons/CustomButtons'
import { getThemeColor } from '../../components/ThemeSetUp';
import Toast, { showToast } from '../../components/Toast/toast'; 

const AddEntry = () => {
    const { db } = fetchFirebaseConfig();
    const [newEntry, setNewEntry] = useState({
      Amount: '',
      Description: '',
      Date: '',
    });
    const [userOptions, setUserOptions] = useState([]);
    const [accountRows, setAccountRows] = useState([
      {
        Account: '',
        Debit: '',
        Credit: '',
        Description: '',
        Amount: '',
      },
      {
        Account: '',
        Debit: '',
        Credit: '',
        Description: '',
        Amount: '',
      },
    ]);
  
    const [accountOptions, setAccountOptions] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [subAccountVisible, setSubAccountVisible] = useState(false);
    const [childAccountVisible, setChildAccountVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [themeColor, setThemeColor] = useState('');
  
    const history = useHistory();

    useEffect(() => {
      // Recalculate totals whenever accountRows changes
      getThemeColor((color) => {
        setThemeColor(color || '');
      });
      calculateTotals();
    }, [accountRows]);


        // Example generateAccountCode function to generate a unique account code
     const generateAccountCode = () => {
          // Generate a random three-digit number and concatenate with "0"
          const randomDigits = Math.floor(Math.random() * 900) + 100; // Generates a number between 100 and 999
          return `${randomDigits}0`;
     };

     const journalCode = `JE${generateAccountCode()}`
  
      const calculateTotals = () => {
        let debitTotal = 0;
        let creditTotal = 0;
    
        accountRows.forEach((row) => {
          if (row.Debit) {
            debitTotal += parseFloat(row.Debit);
          }
    
          if (row.Credit) {
            creditTotal += parseFloat(row.Credit);
          }
        });
    
        setTotalDebit(debitTotal);
        setTotalCredit(creditTotal);
      };
  
        // Reusable helper function to check if an account belongs to a specified collection
        const checkIfAccountBelongsToCollection = async (accountName, collectionName) => {
          const snapshot = await getDocs(collection(db, collectionName));
          return snapshot.docs.some((doc) => doc.data().account_name === accountName);
        };

        // Using the reusable function for specific collections
        const checkIfAssetAccount = (accountName) => checkIfAccountBelongsToCollection(accountName, 'Assets');
        const checkIfLiabilityAccount = (accountName) => checkIfAccountBelongsToCollection(accountName, 'Liabilities');
        const checkIfIncomeAccount = (accountName) => checkIfAccountBelongsToCollection(accountName, 'Income');
        const checkIfEquityAccount = (accountName) => checkIfAccountBelongsToCollection(accountName, 'Equity');
        const checkIfExpensesAccount = (accountName) => checkIfAccountBelongsToCollection(accountName, 'Expenses');
          
        const updateBalance = async (accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount, collectionName) => {
          try {
            const collectionRef = collection(db, collectionName);
            let querySnapshot;
        
            if (childAccountName && childAccountCode) {
              querySnapshot = await getDocs(query(collectionRef, 
                where('account_name', '==', childAccountName),where('account_code', '==', childAccountCode)));
            } else if (subAccountName && subAccountCode) {
              querySnapshot = await getDocs(query(collectionRef, 
              where('account_name', '==', subAccountName),where('account_code', '==', subAccountCode)));
            } else {
              querySnapshot = await getDocs(query(collectionRef, 
                where('account_name', '==', accountName),where('account_code', '==', accountCode)
              ));
            }

          if (querySnapshot.size === 0) {
            console.error(`Document with account_name '${childAccountName || subAccountName || accountName}' does not exist in the ${collectionName} collection.`);
            return;
          }

          const docSnapshot = querySnapshot.docs[0];
          const balances = docSnapshot.data().balances || [];

          // Get the current date without time
          const currentDate = new Date();
          const currentDateString = currentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD

          // Find the balance entry with the same date as the current date
          let balanceEntryToUpdate = null;
          balances.forEach((balanceEntry, index) => {
            if (balanceEntry.date && balanceEntry.date.toDate().toISOString().split('T')[0] === currentDateString) {
              balanceEntryToUpdate = { entry: balanceEntry, index: index };
            }
          });

          if (balanceEntryToUpdate) {
            const currentBalance = balanceEntryToUpdate.entry.balance || 0;
            const currentCreditBalance = balanceEntryToUpdate.entry.creditBalance || 0;
            const currentDebitBalance = balanceEntryToUpdate.entry.debitBalance || 0;

            const newBalance = currentBalance + (collectionName === 'Liabilities' || collectionName === 'Income' || collectionName === 'Equity' ? creditAmount - debitAmount : debitAmount - creditAmount);
            const newCreditBalance = currentCreditBalance + creditAmount;
            const newDebitBalance = currentDebitBalance + debitAmount;

            // Update the latest balance entry
            balances[balanceEntryToUpdate.index].balance = newBalance;
            balances[balanceEntryToUpdate.index].creditBalance = newCreditBalance;
            balances[balanceEntryToUpdate.index].debitBalance = newDebitBalance;

            const docRef = doc(db, collectionName, docSnapshot.id);
            await updateDoc(docRef, { balances });
          } else {
            console.error(`No balance entries found for account_name '${accountName}'.`);
          }
        } catch (error) {
          console.error(`Error updating ${collectionName.toLowerCase()} balance: `, error);
        }
      };

      // Using the reusable function for specific collections
      const updateAssetBalance = (accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount) =>updateBalance(accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount, 'Assets');   
      const updateLiabilityBalance = (accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount) =>updateBalance(accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount, 'Liabilities');      
      const updateIncomeBalance = (accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount) =>updateBalance(accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount, 'Income');  
      const updateEquityBalance = (accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount) =>updateBalance(accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount, 'Equity');  
      const updateExpenseBalance = (accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount) =>updateBalance(accountName, accountCode, subAccountName, subAccountCode, childAccountName, childAccountCode, debitAmount, creditAmount, 'Expenses');      
                  

        const handleAddEntry = async () => {
          //Form contollers
          if (!newEntry.Description || !newEntry.Date) {
            toast.error('Please fill in all required fields.');
            return;
          }
        
          if (totalCredit !== totalDebit) {
            toast.error('Total Credit must equal Total Debit.');
            return;
          }

           // Check if any description inputs within the table are empty
          for (const row of accountRows) {
            if (!row.Description) {
              toast.error('Please fill in all description fields within the entries.');
              return;
            }
          }

          setIsSubmitting(true)

       // Convert the provided date to a server timestamp
         const dateParts = newEntry.Date.split("-");
         const year = parseInt(dateParts[0]);
         const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
         const day = parseInt(dateParts[2]);
                    
        // Extract the time from the current date object
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        
        const serverTimestampmap = new Date(year, month, day, hours, minutes, seconds);
        
          try {
            const journalData = {
              journalCode: journalCode,
              description: newEntry.Description,
              date: serverTimestampmap,
              total_credit: totalCredit,
              total_debit: totalDebit,
              transactionType: 'journal',
              entries: [],
            };
        
              for (const row of accountRows) {
            const debitAmount = parseFloat(row.Debit) || 0;
            const creditAmount = parseFloat(row.Credit) || 0;

            const entry = {
              debit_account: debitAmount > 0 ? (row.childAccount || row.subAccount || row.Account) : '',
              credit_account: creditAmount > 0 ? (row.childAccount || row.subAccount || row.Account) : '',
              debit_amount: debitAmount,
              credit_amount: creditAmount,
              double_entry_description: row.Description,
              debit_account_code: debitAmount > 0 ? getAccountCode(row.childAccount || row.subAccount || row.Account) : '',
              credit_account_code: creditAmount > 0 ? getAccountCode(row.childAccount || row.subAccount || row.Account) : '',
            };

            const accountToUpdate = row.childAccount || row.subAccount || row.Account;
            const isAssetAccount = await checkIfAssetAccount(accountToUpdate);
            const isLiabilityAccount = await checkIfLiabilityAccount(accountToUpdate);
            const isIncomeAccount = await checkIfIncomeAccount(accountToUpdate);
            const isEquityAccount = await checkIfEquityAccount(accountToUpdate);
            const isExpenseAccount = await checkIfExpensesAccount(accountToUpdate);

            if (isAssetAccount) {
              await updateAssetBalance(row.Account, row.AccountCode, row.subAccount, row.subAccountCode, row.childAccount, row.childAccountCode, debitAmount, creditAmount);
            } else if (isLiabilityAccount) {
              await updateLiabilityBalance(row.Account, row.AccountCode, row.subAccount, row.subAccountCode, row.childAccount, row.childAccountCode, debitAmount, creditAmount);
            } else if (isIncomeAccount) {
              await updateIncomeBalance(row.Account, row.AccountCode, row.subAccount, row.subAccountCode, row.childAccount, row.childAccountCode, debitAmount, creditAmount);
            } else if (isEquityAccount) {
              await updateEquityBalance(row.Account, row.AccountCode, row.subAccount, row.subAccountCode, row.childAccount, row.childAccountCode, debitAmount, creditAmount);
            } else if (isExpenseAccount) {
              await updateExpenseBalance(row.Account, row.AccountCode, row.subAccount, row.subAccountCode, row.childAccount, row.childAccountCode, debitAmount, creditAmount);
            }

            journalData.entries.push(entry);
          }
        
            await addDoc(collection(db, 'Journal'), journalData);
            setIsSubmitting(false)
            // toast.success(<div><i className="fa fa-check" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>&nbsp;&nbsp; Journal created successfully</div>);
            showToast('fa fa-check', 'Journal Created Successfully');
            setTimeout(() => {
              history.push("/app/accounting/account-journals");
            }, 4000);
          } catch (error) {
            console.error('Error adding entry: ', error);
          }
        };


      const getAccountCode = (accountName) => {
        let code = '';
        Object.keys(accountOptions).forEach(table => {
            const account = accountOptions[table].find(acc => acc.name === accountName);
            if (account) {
                code = account.code;
            }
        });
        return code;
    };  
  

    const handleAddAccount = () => {
      setAccountRows((prevRows) => {
        const updatedRows = [...prevRows];
        const lastRow = updatedRows[updatedRows.length - 1];
  
        updatedRows.push({
          Account: '',
          Debit: lastRow.Credit,
          Credit: lastRow.Debit,
          Description: '',
          Amount: '',
        });
  
        return updatedRows;
      });
    };
      
    
    const handleRemoveRow = (index) => {
      if (accountRows.length > 2) {
        const updatedRows = [...accountRows];
        updatedRows.splice(index, 1);
        setAccountRows(updatedRows);
      } else {
        toast.warning(<div><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{fontSize:'1.5rem'}}></i>&nbsp;&nbsp; At least two rows must be present.</div>)
      }
    };


    useEffect(() => {
      const fetchAccountNames = async () => {
        try {
          const tables = ['Assets', 'Liabilities', 'Expenses', 'Income', 'Equity'];
          const data = {};
      
          const promises = tables.map(async (table) => {
            const collectionSnapshot = await getDocs(collection(db, table));
            const accounts = [];
      
            collectionSnapshot.docs.forEach((doc) => {
              const { account_name, account_level, account_code, IsMainAccount } = doc.data();
      
              // Only include accounts with account_level === 'child' and IsMainAccount !== 'No'
              if (account_level === 'parent' && IsMainAccount !== 'No') {
                accounts.push({ name: account_name, code: account_code });
              }
            });
      
            return { table, accounts };
          });
      
          const resolvedPromises = await Promise.all(promises);
      
          resolvedPromises.forEach(({ table, accounts }) => {
            data[table] = accounts;
          });
          console.log('Fetched account names:', data);
          setAccountOptions(data);
        } catch (error) {
          console.error('Error fetching account names:', error);
        }
      };
      fetchAccountNames();
    }, [db]);


    const renderAccountOptions = (accounts) => {
      const uniqueAccounts = new Map();
      accounts.forEach((account) => {
        if (!uniqueAccounts.has(account.name)) {
          uniqueAccounts.set(account.name, account);
        }
      });
    
      return Array.from(uniqueAccounts.values()).map((account) => (
        <Select.Option key={account.name} value={account.name}>
          {account.code}&nbsp;{account.name}
        </Select.Option>
      ));
    };

    const accountOptionsMemo = useMemo(() => (
      Object.keys(accountOptions).map((table) => (
        <Select.OptGroup key={table} label={<span style={{ fontWeight: 'bold', fontSize: '15.5px' }}>{table}</span>}>
          {renderAccountOptions(accountOptions[table])}
        </Select.OptGroup>
      ))
    ), [accountOptions]);


    const fetchChildAccounts = async (parentAccountName, collectionName) => {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where('parentAccount', '==', parentAccountName));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        name: doc.data().account_name,
        code: doc.data().account_code
      }));
    };


    const handleInputChange = async (index, field, value, code) => {
      const updatedRows = [...accountRows];
      updatedRows[index][field] = value;
      updatedRows[index][`${field}Code`] = code;
    
      if (field === 'Account') {
        // Determine which collection the account belongs to
        let collection;
        for (const [key, accounts] of Object.entries(accountOptions)) {
          if (accounts.some(account => account.name === value)) {
            collection = key;
            break;
          }
        }
    
        if (collection) {
          const childAccounts = await fetchChildAccounts(value, collection);
          updatedRows[index].childAccounts = childAccounts;
          updatedRows[index].subAccount = ''; // Reset sub-account when main account changes
          updatedRows[index].childAccount = ''; // Reset child-account when main account changes
          
          // Update subAccountVisible based on whether childAccounts exist
          setSubAccountVisible(childAccounts.length > 0);
          setChildAccountVisible(false);
        }
      } else if (field === 'subAccount') {
        const mainAccount = updatedRows[index].Account;
        let collection;
        for (const [key, accounts] of Object.entries(accountOptions)) {
          if (accounts.some(account => account.name === mainAccount)) {
            collection = key;
            break;
          }
        }
    
        if (collection) {
          const grandChildAccounts = await fetchChildAccounts(value, collection);
          updatedRows[index].grandChildAccounts = grandChildAccounts;
          updatedRows[index].childAccount = ''; // Reset child-account when sub-account changes
          
          // Update childAccountVisible based on whether grandChildAccounts exist
          setChildAccountVisible(grandChildAccounts.length > 0);
        }
      } else if (field === 'Debit' && value) {
        updatedRows[index].Credit = '';
        if (index < updatedRows.length - 1) {
          updatedRows[index + 1].Credit = value;
        }
      } else if (field === 'Credit' && value) {
        updatedRows[index].Debit = '';
        if (index < updatedRows.length - 1) {
          updatedRows[index + 1].Debit = value;
        }
      }
    
      setAccountRows(updatedRows);
    
      // Recalculate totals
      calculateTotals();
    };

    const columns = [
      {
        title: 'Account',
        dataIndex: 'Account',
        key: 'Account',
        align: 'center',
        width: "32%",
        render: (text, record, index) => (
          <Select
            style={{ width: "100%" }}
            value={text}
            onChange={(value, option) => handleInputChange(index, 'Account', value, option.key)}
          >
            {accountOptionsMemo}
          </Select>
        ),
      },
      {
        title: 'Sub-Account',
        dataIndex: 'subAccount',
        key: 'subAccount',
        align: 'center',
        width: "32%",
        render: (text, record, index) => (
          <Select
            style={{ width: '100%' }}
            value={text}
            onChange={(value, option) => handleInputChange(index, 'subAccount', value, option.key)}
          >
            {record.childAccounts && record.childAccounts.map(account => (
              <Select.Option key={account.code} value={account.name}>
                {account.code}&nbsp;{account.name}
              </Select.Option>
            ))}
          </Select>
        ),
        hidden: !subAccountVisible,
      },
      {
        title: 'Child-Account',
        dataIndex: 'childAccount',
        key: 'childAccount',
        align: 'center',
        width: "20%",
        render: (text, record, index) => (
          <Select
            style={{ width: '100%' }}
            value={text}
            onChange={(value, option) => handleInputChange(index, 'childAccount', value, option.key)}
          >
            {record.grandChildAccounts && record.grandChildAccounts.map(account => (
              <Select.Option key={account.code} value={account.name}>
                {account.code}&nbsp;{account.name}
              </Select.Option>
            ))}
          </Select>
        ),
        hidden: !childAccountVisible,
      },
      {
        title: 'Debit',
        dataIndex: 'Debit',
        key: 'Debit',
        align: 'center',
        render: (text, record, index) => (
          <Input
            style={{ borderRadius: '7px' }}
            value={text}
            onChange={(e) => handleInputChange(index, 'Debit', e.target.value)}
          />
        ),
      },
      {
        title: 'Credit',
        dataIndex: 'Credit',
        key: 'Credit',
        align: 'center',
        render: (text, record, index) => (
          <Input
            style={{ borderRadius: '7px' }}
            value={text}
            onChange={(e) => handleInputChange(index, 'Credit', e.target.value)}
          />
        ),
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
        align: 'center',
        render: (text, record, index) => (
          <Input
           style={{ borderRadius: '7px' }}
            value={text}
            onChange={(e) => {
              const updatedRows = [...accountRows];
              updatedRows[index].Description = e.target.value;
              setAccountRows(updatedRows);
            }}
          />
        ),
      },
    ];
  

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Accounting</Breadcrumb.Item>
        <Breadcrumb.Item>Journals</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Add Journal</span></Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-lg">Add Journal Entry</h4>
        <WidgetShadow>
          <Form>
            <FormGroup>
                <Label for="loanGuarantors">Date</Label>
                <Input
                style={{ borderRadius: '7px' }}
                value={newEntry.Date}
                onChange={(e) => setNewEntry({ ...newEntry, Date: e.target.value })}
                type="date"
                size='sm'
                />
            </FormGroup>
            <FormGroup>
                <Label for="loanCommitee"> Description </Label>
                <Input
                 style={{ borderRadius: '7px' }}
                value={newEntry.Description}
                onChange={(e) => setNewEntry({ ...newEntry, Description: e.target.value })}
                placeholder='Description'
                type="textarea"
                size='sm'
                />
            </FormGroup>
            </Form>
          </WidgetShadow>

        <WidgetShadow>
        <ButtonGroup className='mb-3'>
          <TextButton label='Add Row' onClick={handleAddAccount} />
          <Click color="danger" size="sm" onClick={handleRemoveRow}style={{marginLeft:'1px'}}>
            Delete Row
          </Click>
        </ButtonGroup>
        <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <Table
          columns={columns.filter(col => !col.hidden)}
          dataSource={accountRows}
          pagination={false}
          rowKey={(record, index) => index}
          size='small'
        />
      </div>
        <div style={{ justifyContent: 'flex-start' }} className='mt-3'>
          <p style={{ fontSize: '14px', margin: '5px 0' }}>
            <span style={{ fontWeight: 'bold' }}>Total Credit: </span>
            {totalCredit.toFixed(2)}
          </p>
          <p style={{ fontSize: '14px', margin: '5px 0' }}>
            <span style={{ fontWeight: 'bold' }}>Total Debit: </span>
            {totalDebit.toFixed(2)}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextButton label='Create' onClick={handleAddEntry} isSubmit={false} loadingLabel='Creating' isLoading={isSubmitting} isBorderRadius={true}/>
        </div>
      </WidgetShadow>
    </div>
  );
};

export default AddEntry;



