import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc } from 'firebase/firestore';
import { Button as Click, ButtonGroup } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import WidgetShadow from '../../components/WidgetShadow/WidgetShadow';
import { faFileExport, faFileInvoiceDollar, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom'; 
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import ReusableTable from '../../components/CustomTable/table';
import { getThemeColor } from '../../components/ThemeSetUp';

const LoanTransactions = () => {

  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [themeColor, setThemeColor] = useState('');

  useEffect(() => {
    fetchLoanTransactions();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);

  const fetchLoanTransactions = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
      const querySnapshot = await getDocs(query(collection(db, 'allTransaction2'), where('transactionType', '==', 'Loan Payment')));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.transactionUser);
        const userData = userDoc.exists() ? userDoc.data() : null;
        if (userData && userData.display_name) {
          return {
            ...item,
            id: item.id,
            firstName: userData.display_name,
            middleName: userData.mid_name,
            sirName: userData.sir_name,
            voucher: item.voucher,
          };
        }
        return null; // Return null for data with empty display_name
      }));

      // Sort the data based on the transactionDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.transactionDate.seconds - a.transactionDate.seconds);

      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };


  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleVoucherClick = (voucher) => {
    window.open(voucher, '_blank'); // Open the voucher URL in a new tab
  };

  const renderLoanType = (transactionType) => {
    return transactionType === "Loan Payment" ? (
      <Badge color="success" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Loan Payment</span>
      </Badge>
    ) : null;
  };


  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Amount', key: 'transactionAmount', width: 20 },
      { header: 'Deposit Type', key: 'transactionType', width: 30 },
      { header: 'Approval Status', key: 'approvalStatus', width: 20 },
      { header: 'Date Created', key: 'transactionDate', width: 15 },
    ];
  
   const formatters = {
    id: (_, __, index) => index + 1,
    transactionDate: renderDateRequested,
  };

 await generateExcelFile({
    sheetName: 'Loan Deposits',
    title: 'Loan Deposits',
    columns: columns,
    data: data,
    formatters: formatters,
    fileName: 'LoanDeposits.xlsx',
    numericalColumns: ['transactionAmount'],
    useFullName2: true, // This will use the full name formatter
    useLatestBalance: true, // This will use the latest balance formatter
    customStyles: {
      headerFill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F7FA' }
      }
    }
  });
};

const generatePDF = async () => {
  const columns = [
    { header: '#', key: 'id', width: 5 },
    { header: 'Name', key: 'name', width: 30 },
    { header: 'Amount', key: 'transactionAmount', width: 20 },
    { header: 'Deposit Type', key: 'transactionType', width: 30 },
    { header: 'Approval Status', key: 'approvalStatus', width: 20 },
    { header: 'Date Created', key: 'transactionDate', width: 15 },
  ];
    
  const formatters = {
    id: (_, __, index) => index + 1,
    createDate: renderDateRequested,
  };

  await generatePDFFile({
    title: 'Loan Deposits',
    columns: columns,
    data: data,
    formatters: formatters,
    fileName: 'LoanDeposits.pdf',
    numericalColumns: ['transactionAmount'],
    useFullName2: true, // This will use the full name formatter
    useLatestBalance: true, // This will use the latest balance formatter
  });
};


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const firstName = record.firstName ? capitalizeFirstLetter(record.firstName) : '';
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sirName ? capitalizeFirstLetter(record.sirName) : '';
        const fullName = [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');  
        return (
          <span>{fullName}</span>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      align: 'center',
      sorter: (a, b) => a.transactionAmount - b.transactionAmount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      sorter: (a, b) => a.transactionType - b.transactionType,
      sortDirections: ['descend', 'ascend'],
      render: renderLoanType,
    },
    {
      title: 'Receipt',
      dataIndex: 'voucher',
      key: 'voucher',
      align: 'center',
      render: (text) => (
        <Space>
        <ButtonIcon icon={faFileInvoiceDollar} onClick={() => handleVoucherClick(text)}/>
        </Space>
      ),
    },
    {
      title: 'Approval Status',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      align: 'center',
      sorter: (a, b) => a.approvalStatus - b.approvalStatus,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      align: 'center',
      sorter: (a, b) => a.transactionDate - b.transactionDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Loans</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Deposits</span></Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Loan Deposits</h5>
      <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="transactionDate"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
    </div>
  );
};

export default LoanTransactions;
