import { getDocs, collection, query, where } from "firebase/firestore";
import {fetchFirebaseConfig} from "../../firebase";

// Function to fetch users from Firestore excluding Employees
export const fetchUsers = async () => {
  const { db } = fetchFirebaseConfig();
  const usersCollection = collection(db, "users");
  
  // Create a query to exclude users with position "Employee"
  const q = query(usersCollection, where("position", "!=", "Employee"));
  const usersSnapshot = await getDocs(q);

  const users = [];
  usersSnapshot.forEach((doc) => {
    const userData = doc.data();
    users.push(userData);
  });

  return users;
};