import React, { useRef, useState, useEffect } from 'react';
import { Button, Input, Space, Table } from 'antd';
import {Spinner,Badge,FormGroup,Label,Row,Col,Input as InputData} from 'reactstrap';
import { fetchFirebaseConfig } from '../../firebase';
import { collection, getDocs, query, where, getDoc } from 'firebase/firestore';
import { faFileExport, faFileInvoiceDollar, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, TextButton } from '../../components/Buttons/CustomButtons';
import { Breadcrumb } from 'antd';  
import { Link } from 'react-router-dom';
import { generateExcelFile } from '../../components/ExcelDownload/excelSheet';
import { generatePDFFile } from '../../components/PdfDownload/pdfDownload';
import { getThemeColor } from '../../components/ThemeSetUp';
import ReusableTable from '../../components/CustomTable/table';

const SharesPayouts = () => {

  const [isLoading, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [themeColor, setThemeColor] = useState('');


  const fetchSharesPayouts = async () => {
    try {
      setLoadingData(true);
      const { db } = fetchFirebaseConfig();
  
      // Fetch payouts with transaction type 'Shares Payout'
      const querySnapshot = await getDocs(query(collection(db, 'PaymentData'), where('transaction', '==', 'Shares Payout')));
      const fetchedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Fetch user data for each document
      const updatedData = await Promise.all(fetchedData.map(async (item) => {
        const userDoc = await getDoc(item.userReference);
        const userData = userDoc.exists() ? userDoc.data() : null;
        if (userData && userData.display_name) {
          return {
            ...item,
            id: item.id,
            firstName: userData.display_name,
            middleName: userData.mid_name,
            sirName: userData.sir_name,
            voucher: item.voucher,
          };
        }
        return null; // Return null for data with empty display_name
      }));
  
      // Sort the data based on the PayoutDate field in descending order
      const sortedData = updatedData.sort((a, b) => b.PayoutDate.seconds - a.PayoutDate.seconds);
  
      setData(sortedData);
      setLoadingData(false);
    } catch (error) {
      setError(error);
      setLoadingData(false);
    }
  };
  
    useEffect(() => {
    fetchSharesPayouts();
    getThemeColor((color) => {
      setThemeColor(color || '');
    });
  }, []);


  const renderDateRequested = timestamp => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return formattedDate;
    }
    return null;
  };

  const formatNumber = number => {
    return number?.toLocaleString();
  };

  const handleVoucherClick = (voucherUrl) => {
    window.open(voucherUrl, '_blank'); // Open the voucher URL in a new tab
  };

  const renderLoanType = (transaction) => {
    return transaction === "Shares Payout" ? (
      <Badge color="info" style={{borderRadius:'5px'}}>
        <span style={{ color: 'white' }}>Shares Payout</span>
      </Badge>
    ) : null;
  };


  const generateExcel = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Amount Transfered', key: 'Amount', width: 20 },
      { header: 'Payout Type', key: 'transaction', width: 15 },
      { header: 'Payout Date', key: 'PayoutDate', width: 30 },
    ];
  
    const formatters = {
      id: (_, __, index) => index + 1,
      PayoutDate: renderDateRequested,
    };
  
    await generateExcelFile({
      sheetName: 'Shares Disbursement',
      title: 'Shares Disbursement',
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: 'SharesDisbursement.xlsx',
      numericalColumns: ['Amount'],
      useFullName2: true, // This will use the full name formatter
      useLatestBalance: true, // This will use the latest balance formatter
      customStyles: {
        headerFill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF5F7FA' }
        }
      }
    });
  };


  const generatePDF = async () => {
    const columns = [
      { header: '#', key: 'id', width: 5 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Amount Transfered', key: 'Amount', width: 20 },
      { header: 'Payout Type', key: 'transaction', width: 15 },
      { header: 'Payout Date', key: 'PayoutDate', width: 30 },
    ];
      
    const formatters = {
      id: (_, __, index) => index + 1,
      createDate: renderDateRequested,
    };
  
    await generatePDFFile({
      title: 'Shares Disbursement',
      columns: columns,
      data: data,
      formatters: formatters,
      fileName: 'SharesDisbursement.pdf',
      numericalColumns: ['Amount'],
      useFullName2: true, // This will use the full name formatter
      useLatestBalance: true, // This will use the latest balance formatter
    });
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (_, record) => {
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const firstName = record.firstName ? capitalizeFirstLetter(record.firstName) : '';
        const middleNameInitial = record.middleName ? record.middleName.charAt(0).toUpperCase() + '.' : '';
        const sirName = record.sirName ? capitalizeFirstLetter(record.sirName) : '';
        const fullName = [firstName, middleNameInitial, sirName].filter(Boolean).join(' ');  
        return (
          <span>{fullName}</span>
        );
      },
    },
    {
      title: 'Amount Transferred',
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'center',
      sorter: (a, b) => a.Amount - b.Amount,
      sortDirections: ['descend', 'ascend'],
      render: (text) => formatNumber(text),
    },
    {
      title: 'Payout Type',
      dataIndex: 'transaction',
      key: 'transaction',
      align: 'center',
      sorter: (a, b) => a.transaction - b.transaction,
      sortDirections: ['descend', 'ascend'],
      render: renderLoanType,
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
      align: 'center',
      render: (text) => (
        <Space>
        <ButtonIcon icon={faFileInvoiceDollar} onClick={() => handleVoucherClick(text)}/>
        </Space>
      ),
    },
    {
      title: 'Payout Date',
      dataIndex: 'PayoutDate',
      key: 'PayoutDate',
      align: 'center',
      sorter: (a, b) => a.PayoutDate - b.PayoutDate,
      sortDirections: ['descend', 'ascend'],
      render: renderDateRequested,
    },
  ];

  return (
    <div>
     <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/app/main">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Shares</Breadcrumb.Item>
        <Breadcrumb.Item><span style={{color: themeColor}}>Payouts</span></Breadcrumb.Item>
      </Breadcrumb>
      <h5 className="mb-lg">Shares Disbursements</h5>
      <ReusableTable
          columns={columns}
          dataSource={data}
          isLoading={isLoading}
          dateField="PayoutDate"  // or whatever field name you're using for dates
          dateFormat="seconds"  // or 'milliseconds', 'iso', etc.
          generateExcel={generateExcel}
          generatePDF={generatePDF}
        />
    </div>
  );
};

export default SharesPayouts;
